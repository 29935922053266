import AbstractModel from '../../../base/AbstractModel';

export default class FoodComponent extends AbstractModel {
  name: string | null = null;
  fullName: string | null = null;
  price: number | null = null;
  weight: number | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
