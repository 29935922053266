export const appConfig = {
  apiUrl: {
    dev: 'https://obrkartadev.netimob.com/api/',
    prod: process.env.REACT_APP_API_URL, // this var require on server
  },
  authApiUrl: {
    dev: 'https://apluslogin.ru/api/',
    prod: process.env.REACT_APP_AUTH_API_URL,
  },
  storesLinks: {
    playStore: 'https://play.google.com/store/apps/details?id=ru.obrkarta.apps.android.obrkarta',
    appStore: 'https://apps.apple.com/ru/app/%D0%BE%D0%B1%D1%80%D0%BA%D0%B0%D1%80%D1%82%D0%B0-2-0/id1527869810',
    huaweiStore: 'https://appgallery.huawei.com/#/app/C104090547',
  },
  formLink: 'https://docs.google.com/forms/d/e/1FAIpQLSdczmMKrbzM_7HDCqOC27z38y91PlaOggmwDcroNFGIjfGMSQ/viewform',
  obrCarta: 'https://obrkarta.ru/',
  newsUrl: 'https://obrkarta.ru/news/',
  faqUrl: 'https://obrkarta.ru/faq/',
  insuranceUrl: 'https://obrkarta.ru/about/insurance/',
  tokenStorageKey: '_obrAccessToken', // _[projectName]AccessToken
  siteName: 'Образовательная карта',
  recaptchPublicKey: '6Lc_eS8kAAAAALKSnjnTyOf8xG-iJ5EyBZ48omzF',
};
