import AbstractModel from '../../../base/AbstractModel';

export enum Gender {
  male = 'M',
  female = 'F',
}

export class Auth extends AbstractModel {
  token: string | null = null;
  deviceId: number | null = null;
  email: string | null = null;
  gender: Gender | null = null;
  lastname: string | null = null;
  middlename: string | null = null;
  name: string | null = null;
  birthday: Date | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
