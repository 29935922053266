import { AuthPhone } from '../models/AuthPhone';
import { AuthFactory } from '../factories/AuthFactory';
import { IAuthCode } from '../types/AuthTypes';
import { CodeAuth } from '../models/CodeAuth';
import AuthApiRepository from '../repositories/AuthApiRepository';

export default class AuthService {
  authApi: AuthApiRepository;
  factory: AuthFactory;

  constructor() {
    this.authApi = new AuthApiRepository();
    this.factory = new AuthFactory();
  }

  registerPhone = async (phone: string, gRecaptchaResponse: string) => {
    const { data } = await this.authApi.register(phone, gRecaptchaResponse);
    return this.factory.create<AuthPhone>(AuthPhone, data);
  };

  sendCode = async (codeData: IAuthCode) => {
    const { data } = await this.authApi.sendCode(codeData);
    return this.factory.create<CodeAuth>(CodeAuth, data);
  };

  authorize = async (accessToken: string) => {
    const { data } = await this.authApi.getToken(accessToken);
    return this.factory.createAuth(data.data);
  };

  passThrough = async (accessToken: string) => {
    const { data } = await this.authApi.passThrough(accessToken);
    return this.factory.createAuth(data.data);
  };
}
