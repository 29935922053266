import React, { useMemo } from 'react';
import { Box, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import QDialog from './UI/QDialog';
import { useIsTablet } from '../hooks/useIsTablet';
import LoaderButton from './UI/LoaderButton';
import { useRootStore } from '../base/hooks/useRootStore';

interface IUnbindChildModalProps {
  open: boolean;
  contractId: number | null;
  onClose: () => void;
}

const UnbindChildModal: React.FC<IUnbindChildModalProps> = observer(props => {
  const { open, onClose, contractId } = props;
  const classes = useStyles();
  const isTablet = useIsTablet();
  const { childrenStore } = useRootStore();
  const { children, loading } = childrenStore;

  const child = useMemo(() => children.find(child => child.contractId === contractId), [children, contractId]);

  const unbindChild = () => {
    if (contractId) {
      childrenStore.unbindChild(contractId).then(onClose);
    }
  };

  return (
    <QDialog open={open} hideCloseBtn={true}>
      <Box mb={{ md: 2, xl: 4 }}>
        <Typography variant={isTablet ? 'h4' : 'h3'}>Отвязать от аккаунта</Typography>
      </Box>

      <Box mb={{ md: 2, xl: 4 }}>
        <Typography variant={isTablet ? 'body2' : 'body1'} className={classes.grayText}>
          {`Вы уверены, что хотите отвязать аккаунт ребенка: ${child?.firstLastName}?`}
        </Typography>
      </Box>

      <Grid container spacing={2} direction={isTablet ? 'column-reverse' : 'row'}>
        <Grid item md={12} xl={6}>
          <LoaderButton
            variant="outlined"
            fullWidth
            size={isTablet ? 'small' : 'medium'}
            loading={loading}
            onClick={unbindChild}
          >
            Отвязать
          </LoaderButton>
        </Grid>
        <Grid item md={12} xl={6}>
          <Button variant="contained" color="primary" fullWidth size={isTablet ? 'small' : 'medium'} onClick={onClose}>
            Не отвязывать
          </Button>
        </Grid>
      </Grid>
    </QDialog>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  grayText: {
    color: theme.palette.neonGray['500'],
  },
}));

export default UnbindChildModal;
