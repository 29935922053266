import AbstractFactory from '../../base/AbstractFactory';
import Attendance from './models/Attendance';
import AttendanceDay from './models/AttendanceDay';
import { parseApiDate } from '../../helpers/dateHelpers';

export default class AttendanceFactory extends AbstractFactory {
  createAttendanceList = (data: any[]) =>
    data
      .map(json => {
        const model = new AttendanceDay(json);

        model.date = parseApiDate(json.date);
        model.attendance = this.createList(Attendance, json.attendance);

        return model;
      })
      .reverse();
}
