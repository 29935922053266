import AbstractModel from '../../../base/AbstractModel';

export default class PurchaseItem extends AbstractModel {
  currency: string | null = null;
  id: number | null = null;
  name: string | null = null;
  price: number | null = null;
  quantity: number | null = null;
  totalPrice: number | null = null;

  constructor(data: any) {
    super();
    this.load(data);
  }
}
