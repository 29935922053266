import React, { ChangeEvent, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core';
import { format, isAfter, isBefore } from 'date-fns';
import { useFormik } from 'formik';

import QDialog from '../../../components/UI/QDialog';
import QRadio from '../../../components/UI/QRadio';
import { useIsTablet } from '../../../hooks/useIsTablet';
import Food from '../../../modules/preorder/models/Food';
import QDatePicker from '../../../components/UI/QDatePicker';
import { ExclamationIcon } from '../../../components/UI/Icons/SvgIcons';
import QIcon from '../../../components/UI/Icons/QIcon';
import { useRootStore } from '../../../base/hooks/useRootStore';

const days = [
  { label: 'Пн', value: 'monday' },
  { label: 'Вт', value: 'tuesday' },
  { label: 'Ср', value: 'wednesday' },
  { label: 'Чт', value: 'thursday' },
  { label: 'Пт', value: 'friday' },
  { label: 'Сб', value: 'saturday' },
  { label: 'Вс', value: 'sunday', disabled: true },
];

const DATE_FORMAT = 'dd.MM.yyyy';

interface DayValues {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
}

const dayValuesInit: DayValues = {
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
};

interface FormikValues extends DayValues {
  startDate?: Date;
  endDate?: Date;
  hasCheckedDay: boolean;
}

interface IRepeatOrderModalProps {
  food: Food;
  open: boolean;
  onClose: () => void;
}

const RepeatOrderModal: React.FC<IRepeatOrderModalProps> = props => {
  const { open, onClose, food } = props;
  const classes = useStyles();
  const isTablet = useIsTablet();
  const { preorderStore } = useRootStore();
  const { preorderAndWorkingDays } = preorderStore;

  const workingDay = useMemo(() => {
    const foundWorkingDay = Object.values(preorderAndWorkingDays?.calendar || {}).find(day => !day.isHoliday);

    if (foundWorkingDay) {
      return foundWorkingDay.date;
    }

    return undefined;
  }, [preorderAndWorkingDays?.calendar]);

  const [repeat, setRepeat] = useState<string>('off');
  const isRepeat = repeat === 'on';

  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      startDate: undefined,
      endDate: undefined,
      hasCheckedDay: false,
      ...dayValuesInit,
    },
    validate(values: FormikValues) {
      const { startDate, endDate, monday, tuesday, wednesday, thursday, friday, saturday } = values;
      const errors: any = {};

      if (!isRepeat) {
        return errors;
      }

      if (!values.startDate) {
        errors.startDate = 'Обязательное поле';
      }

      if (!values.endDate) {
        errors.endDate = 'Обязательное поле';
      }

      if (![monday, tuesday, wednesday, thursday, friday, saturday].some(item => item)) {
        errors.hasCheckedDay = 'Должен быть выбран хотя бы один день';
      }

      if (startDate && workingDay && isBefore(startDate, workingDay)) {
        errors.startDate = `Предзаказ можно сделать с ${format(workingDay, DATE_FORMAT)}`;
      }

      if (startDate && endDate && isAfter(startDate, endDate)) {
        errors.startDate = 'Дата начала не может быть после даты окончания';
      }

      return errors;
    },
    validateOnChange: false,
    onSubmit(values: FormikValues) {
      if (isRepeat) {
        preorderStore.setRepeat(food.id || 0, {
          ...values,
          id: 1, // TODO fill correct id
          enabled: true,
          startDate: values.startDate || new Date(),
          endDate: values.endDate || new Date(),
        });
      } else {
        preorderStore.removeRepeat(food.id || 0);
      }
      onClose();
    },
  });

  const handleRadioChange = (e: any, value: string) => {
    setRepeat(value);
  };

  const handleCheckboxChange = (e: ChangeEvent<any>, checked: boolean) => {
    const dayName = e.target.value;
    const { monday, tuesday, wednesday, thursday, friday, saturday } = values;

    if (dayName !== 'sunday') {
      if (checked || [monday, tuesday, wednesday, thursday, friday, saturday].filter(i => i).length > 1) {
        setFieldValue(dayName, checked);
      }
    }
  };

  const handleDateChange = (field: string) => (date: Date | Date[] | null) => {
    if (!Array.isArray(date)) {
      setFieldValue(field, date || undefined);
    }
  };

  return (
    <QDialog onClose={onClose} open={open} onBackdropClick={onClose}>
      <Box mt="13px" mb={1}>
        <Typography variant={isTablet ? 'h4' : 'h3'}>Повтор питания</Typography>
      </Box>
      <Box mb={4}>
        <Typography variant="subtitle1" className={classes.boldGrey}>
          {food.name}
        </Typography>
      </Box>
      <Box mb={4}>
        <Divider />
        <Box my={1} display="flex">
          <Box mx={2}>
            <QIcon component={ExclamationIcon} size={{ width: 16, height: 64 }} viewBox="0 0 18 72" />
          </Box>
          <Typography className={classes.warnText}>
            На каждый день будет предоставляться разный состав блюд в виде комплекса.
            <br />
            Ваше изменение вступит в силу через 3 дня.
          </Typography>
        </Box>
        <Divider />
      </Box>
      <Typography variant={isTablet ? 'subtitle2' : 'subtitle1'} className={classes.boldGrey}>
        Повтор
      </Typography>
      <Box mb="10px">
        <RadioGroup value={repeat} onChange={handleRadioChange}>
          <Box my="9px">
            <FormControlLabel
              value="off"
              control={<QRadio />}
              label="Не повторять"
              classes={{ label: classes.radioLabel }}
            />
          </Box>
          <Box my="9px">
            <FormControlLabel
              value="on"
              control={<QRadio />}
              label="По дням недели"
              classes={{ label: classes.radioLabel }}
            />
          </Box>
        </RadioGroup>
      </Box>
      {repeat === 'on' && (
        <Box ml="27px" mb={{ md: 2, xl: '34px' }}>
          <FormGroup row className={classes.checkboxes}>
            {days.map(day => (
              <FormControlLabel
                classes={{
                  root: classes.checkboxRoot,
                  label: classes.checkboxLabelContainer,
                  labelPlacementTop: classes.checkboxLabelPlacementTop,
                }}
                value={day.value}
                checked={values[day.value as keyof DayValues]}
                control={<Checkbox color="primary" />}
                disabled={day.disabled}
                label={
                  <Typography variant="subtitle1" className={classes.checkboxLabel}>
                    {day.label}
                  </Typography>
                }
                labelPlacement="top"
                onChange={handleCheckboxChange}
              />
            ))}
          </FormGroup>
        </Box>
      )}
      <Box mb={{ md: 2, xl: 4 }}>
        <Divider />
      </Box>
      <Box mb="12px">
        <Typography variant={isTablet ? 'subtitle2' : 'subtitle1'} className={classes.boldGrey}>
          Период повтора
        </Typography>
      </Box>
      <Box mb={3}>
        <Grid container>
          <Grid item md={6}>
            <Box mr={1}>
              <QDatePicker
                placeholderText="Начало"
                selected={values.startDate}
                onChange={handleDateChange('startDate')}
                error={!!errors.startDate}
                helperText={errors.startDate}
                disabled={!isRepeat}
                minDate={workingDay}
              />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box ml={1}>
              <QDatePicker
                placeholderText="Конец"
                selected={values.endDate}
                onChange={handleDateChange('endDate')}
                popperPlacement="top-end"
                error={!!errors.endDate}
                helperText={errors.endDate}
                disabled={!isRepeat}
                selectsEnd
                minDate={values.startDate}
                startDate={values.startDate}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => handleSubmit()}
        disabled={!values.startDate || !values.endDate}
      >
        Сохранить
      </Button>
    </QDialog>
  );
};

// TODO суббота неактивна для школ-пятидневок (т.е. у школы нет меню на этот день)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  boldGrey: {
    fontWeight: 500,
    color: theme.palette.neonGray['700'],
  },
  warnText: {
    fontWeight: 400,
    color: theme.palette.neonGray['700'],
  },
  radioLabel: {
    fontSize: 18,
    lineHeight: '28px',
    fontWeight: 400,
  },
  checkboxes: {
    [theme.breakpoints.down('lg')]: {
      '& .MuiFormControlLabel-root': {
        margin: '0 5px',
        '&:first-child': {
          marginLeft: 0,
        },
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
  },
  checkboxLabelPlacementTop: {
    marginLeft: 12,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 2,
    },
  },
  checkboxRoot: {
    marginRight: 12,
    [theme.breakpoints.down('lg')]: {
      marginRight: 2,
    },
    '& .MuiButtonBase-root': {
      padding: '0 5.5px 9px',
    },
  },
  checkboxLabelContainer: {
    marginLeft: 0,
  },
  checkboxLabel: {
    fontWeight: 400,
    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
}));

export default RepeatOrderModal;
