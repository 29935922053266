import React from 'react';
import { Box, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';

import currencyFormat from '../../../helpers/currencyFormat';
import { postfixHelper } from '../../../helpers/stringHelper';
import { IPreorderItem, IRegularPreorder } from '../../../modules/preorder/types/Preorder';
import { useRootStore } from '../../../base/hooks/useRootStore';
import AmountBtn from './AmountBtn';
import RepeatIcon from '../../../assets/icons/repeat-icon.svg';

const daysOfWeek = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
const DATE_FORMAT = 'dd.MM.yyyy';

interface IRepeatTooltipProps {
  regular: IRegularPreorder;
}

const RepeatTooltip: React.FC<IRepeatTooltipProps> = observer(props => {
  const classes = useStylesTooltip();
  const { regular } = props;
  const { monday, tuesday, wednesday, thursday, friday, saturday, startDate, endDate } = regular;
  const week = [monday, tuesday, wednesday, thursday, friday, saturday];

  return (
    <div>
      <Typography variant="caption" component="p" className={clsx(classes.paddingBottom, classes.bold)}>
        Дни повтора:
      </Typography>
      <ul className={clsx(classes.ul, classes.paddingBottom)}>
        {week.map(
          (day, index) =>
            day && (
              <li key={index} className={classes.paddingBottom}>
                <Typography variant="caption">{daysOfWeek[index]}</Typography>
              </li>
            ),
        )}
      </ul>
      <Typography variant="caption" className={clsx(classes.paddingBottom, classes.bold)} component="p">
        Период повтора:
      </Typography>
      <Typography variant="caption" component="p">{`${format(startDate, DATE_FORMAT)} –`}</Typography>
      <Typography variant="caption" component="p">
        {format(endDate, DATE_FORMAT)}
      </Typography>
    </div>
  );
});

const useStylesTooltip = makeStyles((theme: Theme) => ({
  ul: {
    paddingLeft: 18,
    margin: 0,
  },
  bold: {
    fontWeight: 500,
  },
  paddingBottom: {
    paddingBottom: 8,
  },
}));

interface ICartItemProps {
  cartItem: IPreorderItem;
}

const CartItem: React.FC<ICartItemProps> = observer(props => {
  const classes = useStyles();
  const { preorderStore } = useRootStore();
  const { cartItem } = props;
  const { food, amount, regular } = cartItem;

  const changeAmount = (addAmount: number) => () => {
    preorderStore.setAmount(addAmount, food.id || 0);
  };

  return (
    <Box className={classes.root} mx={{ md: 1, xl: 3 }} my={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="4px">
        <Box mr="16px">
          <Typography className={classes.bold}>{food.name}</Typography>
        </Box>
        {regular?.enabled && (
          <Tooltip
            placement="right-start"
            classes={{ tooltip: classes.tooltip, tooltipPlacementRight: classes.tooltipPlacement }}
            title={<RepeatTooltip regular={regular} />}
          >
            <Box display="flex" alignItems="center" paddingX="4px">
              <img src={RepeatIcon} alt="повтор" />
            </Box>
          </Tooltip>
        )}
        <AmountBtn amount={amount} changeAmount={changeAmount} />
        <Typography className={classes.bold}>{currencyFormat(food.price)}</Typography>
      </Box>
      <Box mb={1}>
        <Typography className={classes.grey}>{postfixHelper(450, 'гр.')}</Typography>
      </Box>
      <Typography className={classes.bold}>Комплекс блюд меняется в зависмости от меню дня</Typography>
      {food.items?.map((item, index) => (
        <Box key={index} display="flex" justifyContent="space-between">
          <Typography className={classes.grey}>{item.name}</Typography>
          <Typography className={clsx(classes.grey, classes.weight)} align="right">
            {postfixHelper(item.weight, 'гр.')}
          </Typography>
        </Box>
      ))}
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  bold: {
    fontWeight: 500,
  },
  grey: {
    color: theme.palette.neonGray['500'],
  },
  weight: {
    minWidth: 60,
  },
  tooltip: {
    color: 'black',
    backgroundColor: 'white',
    padding: 16,
    borderRadius: 4,
    boxShadow: theme.shadows[10],
  },
  tooltipPlacement: {
    position: 'relative',
    '&:before': {
      position: 'absolute',
      width: 8,
      height: 8,
      backgroundColor: '#ffffff',
      content: `''`,
      left: -4,
      transform: 'rotate(45deg)',
      top: 8,
    },
  },
}));

export default CartItem;
