import AbstractModel from '../../../base/AbstractModel';
import { ICard } from '../types/ChildrenTypes';
import AttendanceDay from '../../attendance/models/AttendanceDay';
import Purchase from '../../purchases/models/Purchase';

export class Child extends AbstractModel {
  firstName: string | null = null;
  lastName: string | null = null;
  middleName: string | null = null;
  balance: number | null = null;
  phone: string | null = null;
  email: string | null = null;
  orgId: number | null = null;
  autoBalance: number | null = null;
  cards: ICard[] | [] = [];
  contractId: number | null = null;
  avatarUrl: string | null = null;
  inSchool: boolean | null = null;
  simplified: boolean | null = null;
  attendance: {
    status: number;
    items: AttendanceDay[];
  } | null = null;
  purchases: {
    items: Purchase[];
  } | null = null;
  expenditureLimit: number | null = null;
  dateOfContract: Date | null = null;
  stateOfContract: string | null = null;
  freePayMaxCount: number | null = null;
  freePayCount: number | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }

  get firstLastName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get fullName() {
    return `${this.firstName} ${this.lastName} ${this.middleName}`;
  }
}
