import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

import QIcon from './QIcon';
import { LogoIcon } from './SvgIcons';
import { useIsTablet } from '../../../hooks/useIsTablet';

interface ILogoProps {}

const Logo: React.FC<ILogoProps> = () => {
  const isTablet = useIsTablet();
  const classes = useStyles();

  return (
    <QIcon
      component={LogoIcon}
      size={isTablet ? { height: 58, width: 97 } : { height: 90, width: 151 }}
      viewBox="0 0 151 90"
      className={classes.logo}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    marginTop: 16,
    [theme.breakpoints.up('xl')]: {
      marginRight: 24,
    },
    [theme.breakpoints.down('lg')]: {
      marginRight: 20,
    },
  },
}));

export default Logo;
