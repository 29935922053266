import React, { useEffect } from 'react';
import { Box, Grid, IconButton, Input, makeStyles, Theme, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import SquareIcon from '../../../components/UI/Icons/SquareIcon';
import { OpenInNewTabIcon } from '../../../components/UI/Icons/SvgIcons';
import { useRootStore } from '../../../base/hooks/useRootStore';
import { useIsTablet } from '../../../hooks/useIsTablet';
import NewsPlaceholder from '../../../assets/images/news-placeholder.svg';
import { appConfig } from '../../../appConfig';

const News: React.FC = observer(() => {
  const classes = useStyles();
  const isTablet = useIsTablet();
  const { newsStore } = useRootStore();
  const { news } = newsStore;
  const newsSlice = news.slice(0, isTablet ? 3 : 4);

  useEffect(() => {
    newsStore.getNews();
  }, [newsStore]);

  return (
    <Box mt="40px">
      <Box display="flex" alignItems="center" mb="18px">
        <Box mr="4px">
          <Typography variant={isTablet ? 'h3' : 'h1'}>Новости</Typography>
        </Box>
        <a href={appConfig.newsUrl} target="_blank" rel="nofollow noopener noreferrer">
          <IconButton>
            <SquareIcon component={OpenInNewTabIcon} size={24} />
          </IconButton>
        </a>
      </Box>
      <Grid container spacing={4}>
        {newsSlice.map(newsItem => (
          <Grid key={newsItem.id} item md={isTablet ? 4 : 3}>
            <Box mb={1}>
              {newsItem.image ? (
                <img src={newsItem.image} alt="Иллюстрация к новости" className={classes.image} />
              ) : (
                <div className={classes.placeholderContainer}>
                  <img src={NewsPlaceholder} alt="Иллюстрация к новости" className={classes.placeholderImg} />
                </div>
              )}
            </Box>
            <Box mb={1}>
              {newsItem.url ? (
                <a href={newsItem.url} className={classes.subtitle} target="_blank" rel="nofollow noopener noreferrer">
                  <Typography>{newsItem.text}</Typography>
                </a>
              ) : (
                <Typography className={classes.subtitle}>{newsItem.text}</Typography>
              )}
            </Box>
            <Box mb={1}>
              <Typography
                variant="body2"
                className={classes.text}
                dangerouslySetInnerHTML={{ __html: newsItem.content || '' }}
              />
            </Box>
            <Typography variant="body2" className={classes.text}>
              {newsItem.date}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    width: '100%',
    borderRadius: 4,
  },
  placeholderContainer: {
    backgroundColor: theme.palette.neonGray['50'],
    padding: '12px 22px',
    borderRadius: 4,
  },
  placeholderImg: {
    height: '100%',
    width: '100%',
  },
  subtitle: {
    color: 'black',
    textDecoration: 'none',
    '&:visited': {
      textDecoration: 'none',
    },
  },
  text: {
    color: theme.palette.neonGray['500'],
  },
  inputRoot: {
    background: '#ffffff',
  },
  input: {
    ...theme.typography.body2,
    overflow: 'hidden',
  },
}));

export default News;
