import React from 'react';
import { Box, Container, makeStyles, Typography, Theme, Grid, Link } from '@material-ui/core';

import { LogoAkbarsSvg, LogoFooterSvg, LogoInstagramIcon, LogoVkIcon } from './UI/Icons/SvgIcons';
import QIcon from './UI/Icons/QIcon';
import { useIsTablet } from '../hooks/useIsTablet';
import SquareIcon from './UI/Icons/SquareIcon';
import { appConfig } from '../appConfig';

const Footer: React.FC = () => {
  const classes = useStyles();
  const isTablet = useIsTablet();

  const year = new Date().getFullYear();

  const renderPhone = () => (
    <Typography variant="subtitle1">
      <a href="tel:+8(843)561–05–05" className={classes.phone}>
        8 (843) 561–05–05
      </a>
    </Typography>
  );

  const renderContacts = () => (
    <Box width="100%">
      <Box mb="10px">{renderPhone()}</Box>
      <Box display="flex">
        <Box mr={3}>
          <a href="https://vk.com/obrkarta" target="_blank" rel="nofollow noopener noreferrer">
            <SquareIcon component={LogoVkIcon} size={32} />
          </a>
        </Box>
        {/* <a href="https://instagram.com/obrkarta?igshid=8bz6wi84g86e" target="_blank" rel="nofollow noopener noreferrer">
          <SquareIcon component={LogoInstagramIcon} size={32} />
        </a> */}
      </Box>
    </Box>
  );

  const renderContactsForTablet = () => (
    <Box display="flex" alignItems="center">
      <Box mr={1} height={32}>
        <a href="https://vk.com/obrkarta" target="_blank" rel="nofollow noopener noreferrer">
          <SquareIcon component={LogoVkIcon} size={32} />
        </a>
      </Box>
      <Box mr={3} height={32}>
        {/* <a href="https://instagram.com/obrkarta?igshid=8bz6wi84g86e" target="_blank" rel="nofollow noopener noreferrer">
          <SquareIcon component={LogoInstagramIcon} size={32} />
        </a> */}
      </Box>
      {renderPhone()}
    </Box>
  );

  return (
    <Box className={classes.root}>
      <Container maxWidth="xl">
        <Box py={3}>
          <Box display="flex">
            <Box mr={{ md: '13px', xl: '78px' }}>
              <Box display="flex" mb={isTablet ? '20px' : '40px'}>
                <Box mr={isTablet ? 2 : 4}>
                  <a href={appConfig.obrCarta} target="_blank" rel="nofollow noopener noreferrer">
                    <QIcon
                      component={LogoFooterSvg}
                      size={isTablet ? { width: 96, height: 18 } : { width: 181, height: 34 }}
                      viewBox="0 0 181 34"
                    />
                  </a>
                </Box>
                <QIcon
                  component={LogoAkbarsSvg}
                  size={isTablet ? { width: 93, height: 17 } : { width: 175, height: 33 }}
                  viewBox="0 0 175 33"
                />
              </Box>
              <Typography
                variant={isTablet ? 'body2' : 'body1'}
                noWrap
              >{`© 2014 – ${year} «Образовательная карта»`}</Typography>
            </Box>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Link href="https://obrkarta.ru/support/" className={classes.linkColor}>
                  Помощь
                </Link>
              </Grid>
              <Grid item>
                <Link href="https://obrkarta.ru/documents/" className={classes.linkColor}>
                  Документы
                </Link>
              </Grid>
              <Grid item>
                <Link href="https://obrkarta.ru/contacts/" className={classes.linkColor}>
                  Контакты
                </Link>
              </Grid>
            </Grid>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Link href="https://obrkarta.ru/about/" className={classes.linkColor}>
                  О проекте
                </Link>
              </Grid>
              <Grid item>
                <Link href="https://obrkarta.ru/how-recharge/" className={classes.linkColor}>
                  Способы пополнения
                </Link>
              </Grid>
              <Grid item>
                <Link href="https://obrkarta.ru/partnership/" className={classes.linkColor}>
                  Сотрудничество
                </Link>
              </Grid>
              <Grid item>
                <Link href="https://obrkarta.ru/news/" className={classes.linkColor}>
                  Новости
                </Link>
              </Grid>
            </Grid>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Link href="https://obrkarta.ru/support/" className={classes.linkColor}>
                  Сообщить об ошибке на сайте
                </Link>
              </Grid>
              <Grid item>
                <Link href="https://obrkarta.ru/reklama/" className={classes.linkColor}>
                  Реклама на сайте
                </Link>
              </Grid>
              <Grid item>
                <Link href="https://obrkarta.ru/privacy-policy/" className={classes.linkColor}>
                  Согласие на обработку персональных данных
                </Link>
              </Grid>
            </Grid>
            {!isTablet && renderContacts()}
          </Box>
          {isTablet && renderContactsForTablet()}
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.divider,
  },
  linkColor: {
    color: theme.palette.neonGray['700'],
  },
  phone: {
    color: 'black',
    textDecoration: 'none',
  },
}));
