import React, { useEffect, useState } from 'react';
import { Box, Container, FormControlLabel, RadioGroup, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useIsTablet } from '../../hooks/useIsTablet';
import QRadio from '../../components/UI/QRadio';
import { useRootStore } from '../../base/hooks/useRootStore';
import MenuItem from './components/MenuItem';
import useContractId from '../../hooks/useContractId';
import Loader from '../../components/UI/Loader';
import MenuFilter from '../../modules/menu-setup/types/MenuFilter';

const MenuSetupScreen: React.FC = observer(() => {
  const isTablet = useIsTablet();
  const { menuSetupStore } = useRootStore();
  const contractId = useContractId();
  const { menu, loading } = menuSetupStore;

  const [filter, setFilter] = useState<MenuFilter>(MenuFilter.all);
  const [expandedItems, setExpandedItems] = useState<boolean[]>([]);

  useEffect(() => {
    if (menu.length !== expandedItems.length) {
      setExpandedItems(new Array(menu.length).fill(false));
    }
  }, [menu]);

  useEffect(() => {
    menuSetupStore.getMenu(contractId);
  }, [contractId]);

  const handleToggleExpand = (index: number) => (e: any, expanded: boolean) => {
    const newExpandedItems = expandedItems.slice();

    newExpandedItems[index] = expanded;
    setExpandedItems(newExpandedItems);
  };

  const handleRadioChange = (e: any, value: string) => {
    setFilter(value as MenuFilter);

    if (value !== MenuFilter.all) {
      setExpandedItems(new Array(menu.length).fill(true));
    }
  };

  return (
    <Container maxWidth="xl">
      <Box mb={3}>
        <Typography variant={isTablet ? 'h3' : 'h1'}>Настройка меню</Typography>
      </Box>

      {loading ? (
        <Loader minHeight="50vh" />
      ) : (
        <>
          <Box mb={{ md: '10px', xl: '18px' }}>
            <RadioGroup row name="card-filter" value={filter} onChange={handleRadioChange}>
              <Box mr={5}>
                <FormControlLabel value={MenuFilter.all} control={<QRadio />} label="Все продукты" />
              </Box>
              <Box mr={5}>
                <FormControlLabel value={MenuFilter.allowed} control={<QRadio />} label="Разрешенные продукты" />
              </Box>
              <Box mr={5}>
                <FormControlLabel value={MenuFilter.forbidden} control={<QRadio />} label="Запрещенные продукты" />
              </Box>
            </RadioGroup>
          </Box>

          {menu.map((menuItem, index) => (
            <MenuItem
              key={menuItem.title}
              menuCategory={menuItem}
              expanded={expandedItems[index] || false}
              onChange={handleToggleExpand(index)}
              filter={filter}
            />
          ))}
        </>
      )}
    </Container>
  );
});

export default MenuSetupScreen;
