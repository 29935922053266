import { Box, TextField, Typography, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { useFormik } from 'formik';
import { useIsTablet } from 'hooks/useIsTablet';
import { useTimer } from 'hooks/useTimer';
import { useRootStore } from 'base/hooks/useRootStore';
import { StepSettingOptions } from 'hooks/useStepHandler';

import { REQUIRED_FIELD_TEXT } from './BaseAddChild';
import PasswordResetModal from './PasswordResetModal';
import LoaderButton from '../UI/LoaderButton';
import TextButton from '../UI/TextButton';

interface IPasswordResetSecondStepProps {
  contractId: number | null;
  handleSetStep: (options: StepSettingOptions) => void;
  isChildAddingCase?: boolean;
  title?: string;
  restoreId?: string;
  message?: string;
  loading?: boolean;
}

const PasswordResetSecondStep: FC<IPasswordResetSecondStepProps> = ({
  handleSetStep,
  isChildAddingCase,
  title,
  contractId,
  loading,
  restoreId,
  message,
}) => {
  const { childrenStore, authStore } = useRootStore();
  const isTablet = useIsTablet();

  const { seconds, resetTimer } = useTimer(60);

  const classes = useStyles();
  const { values, errors, handleSubmit, handleChange } = useFormik({
    initialValues: {
      id: restoreId || '',
      code: '',
    },
    validateOnChange: false,
    validate(values) {
      const errors: any = {};

      if (!values.code) {
        errors.code = REQUIRED_FIELD_TEXT;
      }

      return errors;
    },
    onSubmit(values) {
      authStore.sendCodeToRestorePassword(values, (token: string | null) => {
        childrenStore.setTokenToRestoreChildPassword(token);
        handleSetStep({ action: 'next' });
      });
    },
  });

  // Handlers
  const handleBackButton = () => {
    handleSetStep({ step: 2 });
  };

  const handleResendCode = (): void => {
    if (contractId) {
      childrenStore.restorePassword(+contractId, () => {
        resetTimer();
      });
    }
  };

  // Renders
  return (
    <PasswordResetModal
      title={title || 'Восстановить пароль'}
      handleBackButton={isChildAddingCase ? handleBackButton : undefined}
    >
      <Box mb={4}>
        <Typography variant="body1" className={classes.text}>
          {message}
        </Typography>
      </Box>

      <Box mb={{ md: '28px', xl: 3 }}>
        <TextField
          classes={{ root: classes.input }}
          variant="outlined"
          type="text"
          placeholder="Код из SMS"
          name="code"
          size={isTablet ? 'small' : 'medium'}
          onChange={handleChange}
          value={values.code}
          error={!!errors.code}
          helperText={errors.code}
          fullWidth
        />
      </Box>

      <Box mb={2}>
        <Box mb={1} textAlign="center">
          {seconds > 0 && (
            <Typography variant={isTablet ? 'body2' : 'body1'} component="span" color="textSecondary">
              Получить код еще раз можно будет через {seconds}с
            </Typography>
          )}
        </Box>
        <Box textAlign="center">
          <TextButton disabled={!!seconds} className={classes.link} onClick={handleResendCode}>
            Получить код
          </TextButton>
        </Box>
      </Box>

      <LoaderButton
        variant="contained"
        color="primary"
        fullWidth
        size={isTablet ? 'small' : 'medium'}
        loading={loading || false}
        onClick={() => handleSubmit()}
      >
        Сменить пароль
      </LoaderButton>
    </PasswordResetModal>
  );
};

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.secondary,
  },
  input: {
    '& > div > input': {
      textAlign: 'center',
    },
  },
  link: {
    fontSize: 16,
    color: theme.palette.link[800],
    cursor: 'pointer',
    padding: 0,
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
}));

export default PasswordResetSecondStep;
