import React, { useEffect, useMemo } from 'react';
import { Box, Button, Container, Divider, Grid, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import Sticky from 'react-stickynode';
import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';

import { useIsTablet } from '../../hooks/useIsTablet';
import currencyFormat from '../../helpers/currencyFormat';
import CartItem from './components/CartItem';
import DishItem from './components/DishItem';
import { useRootStore } from '../../base/hooks/useRootStore';
import useContractId from '../../hooks/useContractId';
import Loader from '../../components/UI/Loader';
import { formatRu, isValidDate, parseApiDate } from '../../helpers/dateHelpers';
import { firstLetterUpperCase } from '../../helpers/stringHelper';
import { useLocationQuery } from '../../hooks/useLocationQuery';

const DAY_OF_WEEK_AND_DAY = 'EEEE dd.MM';
const formatDate = (date: Date) => firstLetterUpperCase(formatRu(date, DAY_OF_WEEK_AND_DAY));

interface ICartScreenProps {}

const CartScreen: React.FC<ICartScreenProps> = observer(() => {
  const classes = useStyles();
  const isTablet = useIsTablet();
  const theme = useTheme();
  const contractId = useContractId();

  const params = useLocationQuery();
  const date = useMemo(() => parseApiDate(params.get('date') || ''), [params]);
  const dateStr = isValidDate(date) && formatDate(date);

  const { preorderStore, childrenStore } = useRootStore();
  const { availableFood, availableFoodLoading, cart, preorderAndWorkingDaysLoading, totalCartPrice } = preorderStore;

  useEffect(() => {
    const child = childrenStore.children.find(child => child.contractId === contractId);

    if (child) {
      preorderStore.getPreorderAndWorkingDays(contractId, child.phone || '');
    }
  }, [childrenStore.children, contractId, preorderStore]);

  useEffect(() => {
    if (isValidDate(date)) {
      preorderStore.getAvailableFood(contractId, date);
    }
  }, [preorderStore, contractId, date]);

  if (!date || !isValidDate(date)) {
    return <Redirect to="/not-found" />;
  }

  if (availableFoodLoading || preorderAndWorkingDaysLoading) {
    return <Loader minHeight="50vh" />;
  }

  return (
    <Container maxWidth="xl">
      <Box display="flex" justifyContent="stretch">
        <Box mt="48px" className={classes.leftSide}>
          <Box mb={1}>
            <Typography variant={isTablet ? 'h3' : 'h1'}>Предзаказ питания</Typography>
          </Box>
          <Box mr={{ md: '14px', xl: 3 }}>
            <Grid container className={classes.header}>
              <Grid item md={11} xl={7}>
                <Typography>Наименование блюда</Typography>
              </Grid>
              <Grid item md={1} xl={5}>
                <Typography>Цена</Typography>
              </Grid>
            </Grid>
            {availableFood.map((food, index) => (
              <>
                <DishItem key={food.id} food={food} />
                {index !== availableFood.length - 1 && <Divider />}
              </>
            ))}
          </Box>
        </Box>
        <div className={classes.rightSideContainer}>
          <Sticky
            innerClass={classes.rightSide}
            className={classes.rightContainer}
            bottomBoundary={`.${classes.rightSideContainer}`}
          >
            <Box
              py={{ md: 1, xl: 2 }}
              px={{ md: 1, xl: 3 }}
              borderBottom={`2px solid ${theme.palette.neonGray['150']}`}
            >
              <Typography variant={isTablet ? 'h4' : 'h3'}>{dateStr}</Typography>
            </Box>
            <div className={classes.orders}>
              {cart.map((item, index) => (
                <>
                  <CartItem key={item.id} cartItem={item} />
                  {index !== cart.length - 1 && (
                    <Box mx={{ md: 1, xl: 3 }}>
                      <Divider />
                    </Box>
                  )}
                </>
              ))}
            </div>
            <Box py={{ md: 1, xl: 2 }} px={{ md: 1, xl: 3 }} borderTop={`2px solid ${theme.palette.neonGray['150']}`}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography className={classes.summaryText}>Общая стоимость</Typography>
                <Typography variant="h3">{currencyFormat(totalCartPrice)}</Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                size={isTablet ? 'medium' : 'large'}
                fullWidth
                disabled={cart.length === 0}
              >
                Отправить предзаказ
              </Button>
            </Box>
          </Sticky>
        </div>
      </Box>
    </Container>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  header: {
    padding: '12px 15px',
    backgroundColor: theme.palette.neonGray['50'],
  },
  leftSide: {
    flex: 1,
  },
  orders: {
    flex: 1,
    height: 1500,
    overflowY: 'auto',
  },
  rightSideContainer: {
    borderLeft: `2px solid ${theme.palette.neonGray['150']}`,
    borderRight: `2px solid ${theme.palette.neonGray['150']}`,
  },
  rightContainer: {
    width: 380,
    [theme.breakpoints.down('lg')]: {
      width: 249,
    },
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: 380,
    [theme.breakpoints.down('lg')]: {
      width: 249,
    },
  },
  summaryText: {
    fontWeight: 500,
  },
}));

export default CartScreen;
