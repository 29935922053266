import React from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

import QAvatar from './QAvatar';

interface IQSelectMenuItemProps {
  avatarUrl: string | null;
  primary: string;
  secondary: string;
}

const SelectMenuItem: React.FC<IQSelectMenuItemProps> = props => {
  const classes = useStyles();
  const { avatarUrl, primary, secondary } = props;

  return (
    <div className={classes.root}>
      <Box mr={2}>
        <QAvatar avatarUrl={avatarUrl} size={40} />
      </Box>
      <div className={classes.textContainer}>
        <Typography>{primary}</Typography>
        <Typography variant="caption" className={classes.secondary}>
          {secondary}
        </Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  secondary: {
    color: theme.palette.neonGray['500'],
  },
}));

export default SelectMenuItem;
