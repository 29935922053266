import React from 'react';
import { Divider, DividerProps, makeStyles } from '@material-ui/core';

const QMenuDivider: React.FC<DividerProps> = () => {
  const classes = useStyles();

  return <Divider className={classes.root} />;
};

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: 48,
    backgroundColor: 'rgba(0, 10, 38, 0.1)',
  },
}));

export default QMenuDivider;
