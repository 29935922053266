import React from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import HeaderAvatar from './HeaderAvatar';
import { InfoIcon } from '../Icons/SvgIcons';
import SquareIcon from '../Icons/SquareIcon';
import { createViewBoxHelper } from '../Icons/createViewBoxHelper';
import { useIsTablet } from '../../../hooks/useIsTablet';

interface IMyChildrenProps {
  to: string;
}

const VIEWBOX = createViewBoxHelper(24);

const MyChildren: React.FC<IMyChildrenProps> = ({ to }) => {
  const classes = useStyles();
  const location = useLocation();
  const isTablet = useIsTablet();
  const active = location.pathname === to;

  return (
    <NavLink to={to}>
      <Box display="flex" alignItems="center" ml={{ md: '1px' }}>
        <HeaderAvatar active={active}>
          <SquareIcon component={InfoIcon} size={isTablet ? 16 : 24} viewBox={VIEWBOX} />
        </HeaderAvatar>
        <Box ml={{ md: '11px', xl: '20px' }}>
          <div className={clsx(classes.label, active && classes.activeLabel)}>Мои дети</div>
        </Box>
      </Box>
    </NavLink>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: 14,
    lineHeight: '20px',
    color: theme.palette.neonGray['500'],
    [theme.breakpoints.down('lg')]: {
      fontSize: 13,
      lineHeight: '16px',
    },
  },
  activeLabel: {
    color: 'black',
  },
}));

export default MyChildren;
