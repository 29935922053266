import React from 'react';
import { FormLabel, Grid, makeStyles, Theme } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';
import clsx from 'clsx';

import QDatePicker, { IQDatePickerProps } from './QDatePicker';

interface IDatePickerProps extends Omit<IQDatePickerProps, 'selected' | 'onChange'> {
  label?: string;
  error?: boolean;
  helperText?: string;
}

interface IQRangePickerProps {
  startDate: Date;
  endDate: Date;
  onStartDateChange: (date: Date) => void;
  onEndDateChange: (date: Date) => void;
  startDateProps?: IDatePickerProps;
  endDateProps?: IDatePickerProps;
}

const QRangePicker: React.FC<IQRangePickerProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  startDateProps,
  endDateProps,
}) => {
  const classes = useStyles();

  return (
    <>
      <FormLabel>Период повтора</FormLabel>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <QDatePicker
            placeholderText="Выберите дату"
            label="Начало"
            selectsStart
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate}
            onChange={onStartDateChange}
            calendarClassName={clsx(classes.calendar, { hover: !endDate }, 'start-calendar')}
            {...startDateProps}
          />
        </Grid>
        <Grid item md={6}>
          <QDatePicker
            placeholderText="Выберите дату"
            label="Конец"
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            selected={endDate}
            onChange={onEndDateChange}
            calendarClassName={clsx(classes.calendar, { hover: !startDate }, 'end-calendar')}
            {...endDateProps}
          />
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  calendar: {
    '& .react-datepicker': {
      '&__month': {
        // In selecting range: reset selected styles
        '&--selecting-range': {
          '& .react-datepicker__day': {
            '&--in-selecting-range': {
              '&::before': {
                backgroundColor: '#fbf5b4',
              },
            },
            '&--in-range': {
              '&::before': {
                left: 0,
                width: 44,
                backgroundColor: 'transparent',
              },
              '& div': {
                backgroundColor: 'transparent',
              },
            },
          },
        },
      },
      '&__day': {
        // day range bg
        '&::before': {
          content: `''`,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
        },

        // Selected range styles
        '&--range-start, &--range-end': {
          color: '#000',
          '&::before': {
            width: 44 - 12,
            backgroundColor: '#fbf5b4',
          },
          '& div': {
            backgroundColor: theme.palette.primary.main,
          },
        },
        '&--in-selecting-range, &--in-range': {
          color: '#000',
          '&::before': {
            backgroundColor: '#fbf5b4',
          },
        },
        '&--range-start': {
          '&::before': {
            left: 12,
          },
        },
        // startDay = endDay
        '&--range-start.react-datepicker__day--range-end': {
          '&::before': {
            backgroundColor: 'transparent',
          },
        },
      },
    },

    // In selecting range styles
    '&.start-calendar, &.end-calendar': {
      '& .react-datepicker__month--selecting-range .react-datepicker__day': {
        '&--selecting-range-start': {
          '&::before': {
            left: 12,
            width: 44 - 12,
          },
          '& div': {
            backgroundColor: theme.palette.primary.main,
          },
        },
        '&--selecting-range-end': {
          '&::before': {
            width: 44 - 12,
          },
          '& div': {
            backgroundColor: theme.palette.primary.main,
          },
        },
        '&--selecting-range-start.react-datepicker__day--selecting-range-end': {
          '&::before': {
            backgroundColor: 'transparent',
          },
          '& div': {
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    },

    // Only Start calendar styles
    '&.start-calendar.hover .react-datepicker': {
      '&__day': {
        '&:hover:not(&--selected)': {
          '& div': {
            backgroundColor: '#fbf5b4',
          },
        },
      },
    },

    // Only End calendar styles
    '&.end-calendar.hover .react-datepicker': {
      '&__day': {
        '&:hover:not(&--selected)': {
          '& div': {
            backgroundColor: '#fbf5b4',
          },
        },
      },
    },
  },
}));

export default QRangePicker;
