import AbstractApiRepository from '../../base/repositories/AbstractApiRepository';
import { ICheckPayment, IPaymentLink } from './types/PaymentTypes';

export default class PaymentApiRepository extends AbstractApiRepository {
  getPaymentLink = (contractId: number, amount: number) => {
    return this.post<IPaymentLink>({
      url: 'v2/payment/pay',
      data: {
        amount,
        contractId,
      },
    });
  };

  checkPayment = (orderId: string) => {
    return this.post<ICheckPayment>({ url: 'v2/payment/check', data: { orderId } });
  };
}
