import React from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';

import { ExclamationMiniIcon } from '../../../components/UI/Icons/SvgIcons';
import QIcon from '../../../components/UI/Icons/QIcon';

interface IWarningProps {}

const WarningCard: React.FC<IWarningProps> = props => {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box mr={4}>
        <QIcon component={ExclamationMiniIcon} size={{ width: 17, height: 48 }} />
      </Box>
      {children}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2, '32px'),
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 12,
  },
}));

export default WarningCard;
