import React, { useEffect, useRef } from 'react';
import { Box, Button, FormControlLabel, Grid, RadioGroup, TextField, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useFormik } from 'formik';
import { useRootStore } from 'base/hooks/useRootStore';
import QDatePicker from 'components/UI/QDatePicker';
import QRadio from 'components/UI/QRadio';
import { useIsTablet } from 'hooks/useIsTablet';
import { isBetween, isValidDate, MIN_BIRTHDAY_DATE } from 'helpers/dateHelpers';
import { IProfileUpdateValues } from 'modules/profile/types/ProfileTypes';
import { Gender } from 'modules/auth/models/Auth';
import { Profile } from 'modules/profile/models/Profile';

const REQUIRED_TEXT = 'Обязательное поле';
const ERROR_NAME_TEXT = 'Неверный формат ввода';

const validateName = (name: string): string | null => {
  if (name.length < 2 || name.search(/\d/g) !== -1 || name.search(/\S/g) === -1) {
    return ERROR_NAME_TEXT;
  }

  return null;
};

const setInitialValues = (profile: Profile | null) => ({
  name: profile?.name || '',
  lastname: profile?.lastname || '',
  middlename: profile?.middlename || '',
  gender: profile?.gender || '',
  birthday: profile?.birthday || null,
  birthdayValid: true,
  email: profile?.email || null,
});

const SettingsProfileData: React.VFC = observer(() => {
  const isTablet = useIsTablet();
  const { profileStore } = useRootStore();
  const { profile, loadingUpdateProfile } = profileStore;

  useEffect(() => {
    setValues({
      name: profile?.name || '',
      lastname: profile?.lastname || '',
      middlename: profile?.middlename || '',
      gender: profile?.gender || '',
      birthday: profile?.birthday || null,
      birthdayValid: true,
      email: profile?.email || null,
    });
  }, [profile]);

  const minBirthdayDate = useRef<Date>(MIN_BIRTHDAY_DATE);
  const maxBirthdayDate = useRef<Date>(new Date());

  const {
    setValues,
    values,
    errors,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleBlur,
  } = useFormik<IProfileUpdateValues>({
    initialValues: setInitialValues(profile),
    validateOnChange: true,
    validate(values) {
      const errors: any = {};

      if (!values.name) {
        errors.name = REQUIRED_TEXT;
      } else {
        const error = validateName(values.name);

        if (error) {
          errors.name = error;
        }
      }

      if (!values.lastname) {
        errors.lastname = REQUIRED_TEXT;
      } else {
        const error = validateName(values.lastname);

        if (error) {
          errors.lastname = error;
        }
      }

      if (values.middlename) {
        const error = validateName(values.middlename);

        if (error) {
          errors.middlename = error;
        }
      }

      if (
        !values.birthdayValid ||
        (values.birthday &&
          (!isValidDate(values.birthday) ||
            !isBetween(minBirthdayDate.current, values.birthday, maxBirthdayDate.current)))
      ) {
        errors.birthday = 'Введен некорректный формат даты';
      }

      return errors;
    },
    onSubmit(values: IProfileUpdateValues) {
      profileStore.updateProfile(values);
    },
  });

  const setBirthday = (date: Date | null) => {
    const isValid = isValidDate(date) || date === null;

    setFieldValue('birthdayValid', isValid);

    if (isValid) {
      setFieldValue('birthday', date);
    }
  };

  return (
    <Box mb="40px">
      <Box mb={isTablet ? 2 : 3}>
        <Typography variant={isTablet ? 'h4' : 'h3'}>Данные родителя</Typography>
      </Box>

      <Grid container spacing={isTablet ? 2 : 4}>
        <Grid item md={6} xl={4}>
          <Box mb={{ md: '4px', xl: 0 }}>
            <TextField
              variant="outlined"
              size={isTablet ? 'small' : 'medium'}
              label="Имя"
              name="name"
              onChange={handleChange}
              value={values.name}
              error={!!errors.name}
              helperText={errors.name}
              inputProps={{ maxLength: 30 }}
              fullWidth
              required
            />
          </Box>
        </Grid>

        <Grid item md={6} xl={4}>
          <Box mb={{ md: '4px', xl: 0 }}>
            <TextField
              variant="outlined"
              size={isTablet ? 'small' : 'medium'}
              label="Фамилия"
              name="lastname"
              onChange={handleChange}
              value={values.lastname}
              error={!!errors.lastname}
              helperText={errors.lastname}
              inputProps={{ maxLength: 30 }}
              fullWidth
              required
            />
          </Box>
        </Grid>

        <Grid item md={6} xl={4}>
          <TextField
            variant="outlined"
            size={isTablet ? 'small' : 'medium'}
            label="Отчество"
            name="middlename"
            onChange={handleChange}
            value={values.middlename}
            error={!!errors.middlename}
            helperText={errors.middlename}
            inputProps={{ maxLength: 30 }}
            fullWidth
          />
        </Grid>

        <Grid item md={6} xl={4}>
          <QDatePicker
            name="birthday"
            label="Дата рождения"
            size={isTablet ? 'small' : 'medium'}
            onChange={date => {
              if (!Array.isArray(date)) {
                setBirthday(date);
              }
            }}
            onBlur={(e: any, date: Date | null) => {
              setBirthday(date);
              handleBlur(e);
            }}
            selected={values.birthday}
            minDate={minBirthdayDate.current}
            maxDate={maxBirthdayDate.current}
            error={!!errors.birthday}
            helperText={errors.birthday}
          />
        </Grid>

        <Grid item md={12} xl={4}>
          <Box display="flex" alignItems="center" height={isTablet ? 56 : 64}>
            <RadioGroup name="gender" value={values.gender} onChange={handleChange} style={{ flexDirection: 'row' }}>
              <FormControlLabel
                value={Gender.male}
                name="gender"
                control={<QRadio color="primary" />}
                label="Мужчина"
                labelPlacement="end"
              />
              <FormControlLabel
                value={Gender.female}
                name="gender"
                control={<QRadio color="primary" />}
                label="Женщина"
                labelPlacement="end"
              />
            </RadioGroup>
          </Box>
        </Grid>

        <Box pt={isTablet ? 0 : 2} px={isTablet ? 1 : 2} width="100%">
          <Button
            variant="contained"
            color="primary"
            size={isTablet ? 'medium' : 'large'}
            onClick={() => handleSubmit()}
            disabled={loadingUpdateProfile}
          >
            Сохранить изменения
          </Button>
        </Box>
      </Grid>
    </Box>
  );
});

export default SettingsProfileData;
