import AbstractModel from '../../../base/AbstractModel';

export default class OrderInDay extends AbstractModel {
  date: Date | null = null;
  isHoliday: boolean | null = null;
  preorderQuantity: number | null = null;
  address: string | null = null;
  preorderSum: number | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
