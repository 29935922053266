import React from 'react';

import { AuthStore } from '../modules/auth/stores/AuthStore';
import { ProfileStore } from '../modules/profile/stores/ProfileStore';
import { ChildrenStore } from '../modules/children/stores/ChildrenStore';
import PaymentStore from '../modules/payment/PaymentStore';
import LibraryStore from '../modules/library/LibraryStore';
import NewsStore from '../modules/news/NewsStore';
import AttendanceStore from '../modules/attendance/AttendanceStore';
import PurchasesStore from '../modules/purchases/PurchasesStore';
import MenuSetupStore from '../modules/menu-setup/MenuSetupStore';
import PreorderStore from '../modules/preorder/PreorderStore';
import OperationsStore from '../modules/operations/OperationsStore';
import StoriesStore from '../modules/stories/StoriesStore';
import CallToInstallStore from '../modules/call-to-install/CallToInstallStore';
import TCardStore from '../modules/transport-card/TCardStore';

class RootStore {
  preorderStore: PreorderStore;
  authStore: AuthStore;
  profileStore: ProfileStore;
  childrenStore: ChildrenStore;
  paymentStore: PaymentStore;
  libraryStore: LibraryStore;
  newsStore: NewsStore;
  purchasesStore: PurchasesStore;
  menuSetupStore: MenuSetupStore;
  attendanceStore: AttendanceStore;
  operationsStore: OperationsStore;
  storiesStore: StoriesStore;
  callToInstallStore: CallToInstallStore;
  tCardStore: TCardStore;

  constructor() {
    this.preorderStore = new PreorderStore();
    this.authStore = new AuthStore();
    this.profileStore = new ProfileStore();
    this.childrenStore = new ChildrenStore();
    this.paymentStore = new PaymentStore();
    this.libraryStore = new LibraryStore();
    this.newsStore = new NewsStore();
    this.purchasesStore = new PurchasesStore();
    this.menuSetupStore = new MenuSetupStore();
    this.attendanceStore = new AttendanceStore();
    this.operationsStore = new OperationsStore();
    this.storiesStore = new StoriesStore();
    this.callToInstallStore = new CallToInstallStore();
    this.tCardStore = new TCardStore();
  }
}

export const rootStore = new RootStore();

export const storesContext = React.createContext(rootStore);
