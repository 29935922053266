import AbstractModel from '../../../base/AbstractModel';

export default class Publication extends AbstractModel {
  id: number | null = null;
  author: string | null = null;
  title: string | null = null;
  genre: string | null = null;
  publicationDate: string | null = null;
  publisher: string | null = null;
  issuanceDate: string | null = null; // '2021-01-19'
  refundDate: string | null = null; // '2021-01-19'
  status: {
    color: string; // '#E7294A'
    title: string;
  } | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
