import { makeAutoObservable } from 'mobx';

import MenuSetupService from './MenuSetupService';
import MenuCategory from './models/MenuCategory';
import MenuItemModel from './models/MenuItemModel';

export default class MenuSetupStore {
  menu: MenuCategory[] = [];
  loading: boolean = false;
  loadingToggle: boolean = false;
  private menuSetupService: MenuSetupService;

  constructor() {
    makeAutoObservable(this);
    this.menuSetupService = new MenuSetupService();
  }

  getMenu = (contractId: number) => {
    this.setLoading(true);

    this.menuSetupService
      .getMenu(contractId)
      .then(menu => {
        this.setMenu(menu);
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  getMenuSection = async (contractId: number, menuCategoryName: string) => {
    const menuCategory = await this.menuSetupService.getMenuCategory(contractId, menuCategoryName);
    const foundIndex = this.menu.findIndex(menu => menu.title === menuCategoryName);

    if (foundIndex > -1) {
      const newMenu = this.menu.slice();

      newMenu[foundIndex] = menuCategory;
      this.setMenu(newMenu);
    }
  };

  toggleMenuItem = (contractId: number, menuCategoryName: string, menuItem: MenuItemModel) => {
    this.setLoadingToggle(true);

    this.menuSetupService
      .toggleMenuItem(contractId, menuItem)
      .then(() => this.getMenuSection(contractId, menuCategoryName))
      .finally(() => this.setLoadingToggle(false));
  };

  private setMenu = (menu: MenuCategory[]) => (this.menu = menu);

  private setLoading = (loading: boolean) => (this.loading = loading);

  private setLoadingToggle = (loading: boolean) => (this.loadingToggle = loading);
}
