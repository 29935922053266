import React, { FC } from 'react';
import { Box } from '@material-ui/core';

import QRadio from '../UI/QRadio';

interface IValidationRulesProps {
  isEnoughLength: boolean;
  isLatin: boolean;
  hasAtLeastOneBigLetter: boolean;
  hasAtLeastOneNumber: boolean;
}

const ValidationRules: FC<IValidationRulesProps> = ({
  isEnoughLength,
  isLatin,
  hasAtLeastOneBigLetter,
  hasAtLeastOneNumber,
}) => {
  // Renders
  return (
    <Box mt={3} mb={4} ml={-1}>
      <Box>
        <QRadio radioSize={16} radioType="checked" disabled checked={isEnoughLength} /> минимум 8 знаков
      </Box>
      <Box>
        <QRadio radioSize={16} radioType="checked" disabled checked={isLatin} /> буквы латинского алфавита
      </Box>
      <Box>
        <QRadio radioSize={16} radioType="checked" disabled checked={hasAtLeastOneBigLetter} /> минимум одну заглавную
        букву
      </Box>
      <Box>
        <QRadio radioSize={16} radioType="checked" disabled checked={hasAtLeastOneNumber} /> минимум одну цифру
      </Box>
    </Box>
  );
};

export default ValidationRules;
