import AbstractApiRepository from '../../../base/repositories/AbstractApiRepository';
import { IChildCardValues } from '../types/ChildrenTypes';
import { Child } from '../models/Child';
import { RestoreTokenData } from '../models/RestoreTokenData';

type Children = {
  popup: {
    needChangePassword: boolean;
    contractId: number;
  };
  contracts: Child[];
};

export default class ChildrenApiRepository extends AbstractApiRepository {
  getChildren = () => {
    return this.get<Children>({
      url: 'v2/accounts',
    });
  };

  addChild = (data: IChildCardValues) => {
    return this.post<Child>({
      url: 'v2/accounts',
      data,
    });
  };

  checkPassword = (contractId: number, password: string) => {
    return this.post<{}>({
      url: '/v2/accounts/check',
      data: {
        contractId,
        password,
      },
    });
  };

  restorePassword = (contractId: number) => {
    return this.post<RestoreTokenData>({
      url: '/v2/child-profile/password/restore',
      data: {
        contractId,
      },
    });
  };

  changeEmail = (contractId: number, email: string) =>
    this.post({
      url: '/v2/child-profile/email',
      data: {
        contractId,
        email,
      },
    });

  changePhone = (contractId: number, phone: string) =>
    this.post({
      url: '/v2/child-profile/phone',
      data: {
        contractId,
        phone,
      },
    });

  changePassword = (contractId: number, currentPassword: string, newPassword: string, repeatPassword: string) =>
    this.post({
      url: '/v2/child-profile/password',
      data: {
        contractId,
        currentPassword,
        newPassword,
        repeatPassword,
      },
    });

  changePasswordIfForgot = (contractId: number, newPassword: string, repeatPassword: string, token: string) =>
    this.post({
      url: '/v2/child-profile/password',
      data: {
        contractId,
        token: `Bearer ${token}`,
        newPassword,
        repeatPassword,
      },
    });

  changeExpenditureLimit = (contractId: number, limit: number) =>
    this.post({
      url: '/v2/child-profile/expenditure-limit',
      data: {
        contractId,
        limit,
      },
    });

  uploadAvatar = (contractId: number, formData: FormData) =>
    this.post({
      url: `/v2/avatars/${contractId}`,
      data: formData,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    });

  removeAvatar = (contractId: number) =>
    this.delete({
      url: `/v2/avatars/${contractId}`,
    });

  unbindChild = (contractId: number) =>
    this.delete({
      url: `/v2/accounts/${contractId}`,
    });
}
