import React from 'react';
import { Box, Breadcrumbs, makeStyles, Theme, Typography } from '@material-ui/core';
import { useLocation, Link } from 'react-router-dom';
import clsx from 'clsx';

import { IRouteWithOriginPath } from '../types/RouteTypes';
import SquareIcon from '../../../components/UI/Icons/SquareIcon';
import { BreadcrumbsArrowIcon, HomeIcon } from '../../../components/UI/Icons/SvgIcons';
import { useRootStore } from '../../hooks/useRootStore';
import useContractId from '../../../hooks/useContractId';
import { formatRu, isValidDate, parseApiDate } from '../../../helpers/dateHelpers';
import { firstLetterUpperCase } from '../../../helpers/stringHelper';
import { routes } from '../../../screens/routes';

const DAY_MONTH = 'EEEE dd.MM';
const getDateFromQueryParam = (urlQuery: string): string | null => {
  const params = new URLSearchParams(urlQuery);
  const date = parseApiDate(params.get('date') || '');

  if (isValidDate(date)) {
    return firstLetterUpperCase(formatRu(date, DAY_MONTH));
  }

  return null;
};

const ROUTES = {
  root: routes.MainScreen.path,
  attachChildren: routes.AttachChildren.path,
  childScreen: routes.ChildScreen.path,
  cartScreen: routes.CartScreen.path,
  child: routes.ChildScreen.path,
};

const HIDE_BREADCRUMBS_ROUTES = [ROUTES.root, ROUTES.attachChildren, ROUTES.childScreen];

interface IQBreadcrumbsProps {
  breadcrumbs: IRouteWithOriginPath[];
}

const QBreadcrumbs: React.FC<IQBreadcrumbsProps> = props => {
  const { breadcrumbs } = props;
  const classes = useStyles();
  const { search } = useLocation();
  const contractId = useContractId();
  const { childrenStore } = useRootStore();
  const { children } = childrenStore;

  const lastRoute = breadcrumbs[breadcrumbs.length - 1];
  const isAbsolute = lastRoute.originPath.indexOf(ROUTES.cartScreen) !== -1;

  if (HIDE_BREADCRUMBS_ROUTES.indexOf(lastRoute.originPath) !== -1) {
    return null;
  }

  return (
    <Breadcrumbs
      separator={<SquareIcon component={BreadcrumbsArrowIcon} size={16} />}
      className={clsx(classes.root, isAbsolute && classes.absolute)}
    >
      {breadcrumbs.map(route => {
        let title = route.title;

        if (route.path === ROUTES.root) {
          return (
            <Link className="router-link" key={route.path} to={route.path}>
              <Box display="flex" alignItems="center">
                <SquareIcon component={HomeIcon} size={16} />
              </Box>
            </Link>
          );
        } else if (route.originPath === ROUTES.cartScreen) {
          const newTitle = getDateFromQueryParam(search);

          if (newTitle) {
            title = newTitle;
          }
        } else if (route.originPath === ROUTES.child) {
          const child = children.find(child => child.contractId === contractId);

          if (child) {
            title = child.firstLastName;
          }
        }

        return (
          <Link className="router-link" key={route.path} color="inherit" to={route.path}>
            <Typography variant="body2" className={classes.link}>
              {title}
            </Typography>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: 12,
  },
  link: {
    color: theme.palette.neonGray['700'],
  },
  absolute: {
    position: 'absolute',
  },
}));

export default QBreadcrumbs;
