export function getMobileOS(): 'Windows Phone' | 'Android' | 'iOS' | 'Huawei' | 'unknown' {
  const userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'iOS';
  }

  if (/HMSCore/.test(userAgent) && !/GMSCore/.test(userAgent)) {
    return 'Huawei';
  }

  return 'unknown';
}
