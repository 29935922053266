import { addDays, format, fromUnixTime, isSameDay } from 'date-fns';
import { makeAutoObservable, reaction, runInAction } from 'mobx';

import ChildrenService from '../services/ChildrenService';
import { IChildCardValues, IPurchaseByDate, IRestoreContractIdData } from '../types/ChildrenTypes';
import { Child } from '../models/Child';
import { routes } from '../../../screens/routes';
import history from '../../../base/routes/history';
import { RestoreTokenData } from '../models/RestoreTokenData';

const weekDaysModifiers = [-3, -2, -1, 0, 1, 2, 3] as const;

export class ChildrenStore {
  children: Child[] = [];
  child: Child | null = null;
  currentDay: Date = new Date();
  currentWeek: Date[] = weekDaysModifiers.map(dayModifier => addDays(this.currentDay, dayModifier));
  loading: boolean = false;
  addChildLoading: boolean = false;
  needChangePassword: boolean = false;
  contracIdToChangePassword: number | null = null;

  restoreTokenData: RestoreTokenData | null = null;
  restoreContractIdData: IRestoreContractIdData | null = null;

  currentRestoreContactId: number | null = null;
  tokenToRestoreChildPassword: string | null = null;

  private childrenService: ChildrenService;

  constructor() {
    makeAutoObservable(this);
    this.childrenService = new ChildrenService();

    reaction(
      () => this.currentDay,
      day => {
        this.setCurrentWeek(weekDaysModifiers.map(dayModifier => addDays(day, dayModifier)));
      },
    );
  }

  get currentDayFood() {
    return this.child?.purchases?.items.filter(
      ({ timestamp }) => timestamp && isSameDay(fromUnixTime(timestamp), this.currentDay),
    );
  }

  get lastPurchasedFood(): IPurchaseByDate {
    const obj: IPurchaseByDate = {};
    let counter = 0;

    this.child?.purchases?.items.forEach(purchase => {
      if (counter >= 5) return;
      const date = purchase.timestamp && format(fromUnixTime(purchase.timestamp), 'yyyy-MM-dd');

      if (date) {
        if (obj[date]) {
          obj[date].push(purchase);
          counter++;
        } else {
          obj[date] = [purchase];
          counter++;
        }
      }
    });

    return obj;
  }

  get currentDayAttendance() {
    return this.child?.attendance?.items.filter(({ date }) => date && isSameDay(new Date(date), this.currentDay))[0]
      ?.attendance;
  }

  get currentRestorePasswordChildName() {
    if (this.child) {
      return this.child.firstLastName;
    }

    const child = this.children.find(child => child.contractId === this.currentRestoreContactId);
    if (child) {
      return child.firstLastName;
    }

    return '';
  }

  getChildren = (): Promise<Child[]> => {
    this.setLoading(true);

    return this.childrenService
      .getChildren()
      .then(({ popup, children }) => {
        runInAction(() => {
          this.contracIdToChangePassword = popup.contractId;
          this.needChangePassword = popup.needChangePassword;
          this.children = children;
        });
        return children;
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  getChildByContractId = (contractId: number): Child | undefined =>
    this.children.find(child => child.contractId === contractId);

  addChild = (values: IChildCardValues, callback?: () => void) => {
    this.setAddChildLoading(true);

    return this.childrenService
      .addChild(values)
      .then(child => {
        this.setChild(child);
        this.getChildren();
        callback?.();
      })
      .finally(() => {
        this.setAddChildLoading(false);
      });
  };

  restorePassword = (contractId: number, resolveCallback?: () => void, rejectCallback?: () => void) => {
    this.setAddChildLoading(true);

    this.setCurrentRestoreContactId(contractId);
    this.childrenService
      .restorePassword(contractId)
      .then(data => {
        this.setRestoreTokenData(data);
        resolveCallback?.();
      })
      .catch(err => {
        if (err.status === 406) {
          rejectCallback?.();
        }
      })
      .finally(() => {
        this.setAddChildLoading(false);
      });
  };

  checkPassword = (
    contractId: number,
    password: string,
    callbackForPasswordChange?: () => void,
    callback?: () => void,
  ) => {
    this.setAddChildLoading(true);

    this.childrenService
      .checkPassword(contractId, password)
      .then(data => {
        if (!data.needChangePassword) {
          this.addChild(
            {
              contractId: `${contractId}`,
              password,
            },
            callback,
          );
          return;
        }
        this.setRestoreContractIdData({ contractId, password });
        callbackForPasswordChange?.();
      })
      .catch(() => false)
      .finally(() => {
        this.setAddChildLoading(false);
      });
  };

  changeEmail = (contractId: number, email: string) => this.childrenService.changeEmail(contractId, email);

  changePhone = (contractId: number, phone: string) => this.childrenService.changePhone(contractId, phone);

  changeExpenditureLimit = (contractId: number, limit: number) =>
    this.childrenService.changeExpenditureLimit(contractId, limit);

  changePassword = (contractId: number, currentPassword: string, newPassword: string, repeatPassword: string) =>
    this.childrenService.changePassword(contractId, currentPassword, newPassword, repeatPassword);

  changePasswordIfForgot = (
    contractId: number,
    newPassword: string,
    repeatPassword: string,
    token: string,
    callback: () => void,
  ) => {
    this.setAddChildLoading(true);

    this.childrenService
      .changePasswordIfForgot(contractId, newPassword, repeatPassword, token)
      .then(() => {
        callback();
      })
      .catch(() => {})
      .finally(() => {
        this.setAddChildLoading(false);
      });
  };

  changeAvatar = (contractId: number, avatar: File) => {
    this.setLoading(true);

    return this.childrenService
      .changeAvatar(contractId, avatar)
      .then(() => this.getChildren())
      .finally(() => {
        this.setLoading(false);
      });
  };

  removeAvatar = (contractId: number) => {
    this.setLoading(true);

    return this.childrenService.removeAvatar(contractId).then(() => this.getChildren());
  };

  unbindChild = (contractId: number) => {
    this.setLoading(true);

    return this.childrenService
      .unbindChild(contractId)
      .then(this.getChildren)
      .finally(() => {
        this.setLoading(false);
      });
  };

  setChild = (contractId: number | Child): boolean => {
    if (typeof contractId !== 'number') {
      this.child = contractId;
      return true;
    }

    const child = this.children.find(item => item.contractId === contractId);

    if (child) {
      this.child = child;
      return true;
    }
    history.push(routes.NotFoundScreen.path);
    return false;
  };

  setAddChildLoading = (loading: boolean) => {
    this.addChildLoading = loading;
  };

  setCurrentDay = (date: Date): void => {
    this.currentDay = date;
  };

  setCurrentWeek = (week: Date[]): void => {
    this.currentWeek = week;
  };

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  setRestoreTokenData = (data: RestoreTokenData | null) => {
    this.restoreTokenData = data;
  };

  setCurrentRestoreContactId = (contactId: number | null) => {
    this.currentRestoreContactId = contactId;
  };

  setRestoreContractIdData = (data: IRestoreContractIdData | null) => {
    this.restoreContractIdData = data;
  };

  setTokenToRestoreChildPassword = (token: string | null) => {
    this.tokenToRestoreChildPassword = token;
  };

  handleSetPrevDate = (): void => {
    this.setCurrentDay(addDays(this.currentDay, -1));
  };

  handleSetNextDate = (): void => {
    this.setCurrentDay(addDays(this.currentDay, 1));
  };

  handleSetDate = (page: number): void => {
    this.setCurrentDay(this.currentWeek[page - 1]);
  };

  handleReplenishOCClick = (contractId: number) => {
    history.push(routes.ReplenishObrCardScreen.path, {
      state: {
        contractId,
      },
    });
    window.scrollTo(0, 0);
  };

  handleReplenishTKClick = (contractId: number) => {
    history.push(routes.ReplenishTkCardScreen.path, {
      state: {
        contractId,
      },
    });
    window.scrollTo(0, 0);
  };
}
