import PreorderApiRepository from './PreorderApiRepository';
import PreorderFactory from './PreorderFactory';
import { toApiDateStr } from '../../helpers/dateHelpers';
import Food from './models/Food';

export default class PreorderService {
  private api: PreorderApiRepository;
  private factory: PreorderFactory;

  constructor() {
    this.api = new PreorderApiRepository();
    this.factory = new PreorderFactory();
  }

  getAvailableFood = async (contractId: number, date: Date): Promise<Food[]> => {
    const { data } = await this.api.getAvailableFood(contractId, toApiDateStr(date));

    return this.factory.createDishes(data.data);
  };

  getSumPreorderAndWorkingDays = async (contractId: number, phone: string) => {
    const { data } = await this.api.getSumPreorderAndWorkingDays(contractId, phone);

    return this.factory.createSumPreorderAndWorkingDays(data.data);
  };
}
