import React from 'react';
import { observer } from 'mobx-react-lite';
import { intlFormat } from 'date-fns';
import { Box, makeStyles, Typography } from '@material-ui/core';

import { routes } from '../../../routes';
import ChildPurchaseLine from './ChildPurchaseLine';
import ChildScheduleHeader from '../ChildScheduleHeader';
import ChildPurchaseAccordion from './ChildPurchaseAccordion';
import { makePath } from '../../../../base/routes/utils/makePath';
import { useRootStore } from '../../../../base/hooks/useRootStore';

interface IChildPurchaseProps {
  contractId: number;
}

const ChildPurchase: React.FC<IChildPurchaseProps> = observer(props => {
  const { contractId } = props;
  const { childrenStore } = useRootStore();
  const classes = useStyles();

  return (
    <>
      <ChildScheduleHeader
        header="Последние покупки"
        urlText="Посмотреть все покупки"
        url={makePath(routes.ChildPurchasesScreen.path, contractId)}
      />
      {Object.entries(childrenStore.lastPurchasedFood).map(([date, purchases]) => {
        const dateObj = new Date(date);
        return (
          <Box mt={3} key={date}>
            <Typography variant="subtitle2" color="textSecondary" className={classes.date}>
              {intlFormat(dateObj, { day: 'numeric', month: 'long' })}
            </Typography>
            {purchases.map(purchase => {
              const isDinner = purchase.items && purchase.items.length > 1;
              return (
                <React.Fragment key={purchase.timestamp}>
                  {isDinner ? (
                    <ChildPurchaseAccordion purchase={purchase} />
                  ) : (
                    <ChildPurchaseLine purchase={purchase} />
                  )}
                </React.Fragment>
              );
            })}
          </Box>
        );
      })}
    </>
  );
});

const useStyles = makeStyles({
  date: {
    textTransform: 'capitalize',
  },
});

export default ChildPurchase;
