import { useState } from 'react';

export interface StepSettingOptions {
  step?: number;
  action?: 'next' | 'prev';
}

const useStepHandler = () => {
  const [step, setStep] = useState<number>(0);

  const handleSetStep = (options: StepSettingOptions) => {
    if (options.step || options.step === 0) {
      setStep(options.step);
      return;
    }

    if (options.action === 'next') {
      setStep(step => step + 1);
      return;
    }

    setStep(step => step - 1);
  };

  return {
    step,
    handleSetStep,
  };
};

export default useStepHandler;
