import ProfileApiRepository from '../repositories/ProfileApiRepository';
import { ProfileFactory } from '../factories/ProfileFactory';
import ProfileDto from '../forms/ProfileDto';

export default class ProfileService {
  api: ProfileApiRepository;
  factory: ProfileFactory;

  constructor() {
    this.api = new ProfileApiRepository();
    this.factory = new ProfileFactory();
  }

  getProfile = async () => {
    const { data } = await this.api.getProfile();

    return this.factory.createProfile(data.data);
  };

  updateProfile = (data: ProfileDto) => {
    return this.api.updateProfile(data);
  };

  removeProfile = () => {
    return this.api.removeProfile();
  };
}
