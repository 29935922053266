import PaymentFactory from './PaymentFactory';
import PaymentApiRepository from './PaymentApiRepository';
import PaymentLink from './models/PaymentLink';
import PaymentCheck from './models/PaymentCheck';

export default class PaymentService {
  private api: PaymentApiRepository;
  private factory: PaymentFactory;

  constructor() {
    this.api = new PaymentApiRepository();
    this.factory = new PaymentFactory();
  }

  getPaymentLink = async (contractId: number, amount: number): Promise<PaymentLink> => {
    const { data } = await this.api.getPaymentLink(contractId, amount);
    return this.factory.create(PaymentLink, data.data);
  };

  checkPayment = async (orderId: string) => {
    const { data } = await this.api.checkPayment(orderId);
    return this.factory.create<PaymentCheck>(PaymentCheck, data.data);
  };
}
