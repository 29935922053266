import React, { useState } from 'react';
import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { useIsTablet } from 'hooks/useIsTablet';

import RemoveAccountModal from './RemoveAccountModal';

interface ISettingsRemoveAccountProps {}

const SettingsRemoveAccount: React.FC<ISettingsRemoveAccountProps> = () => {
  const classes = useStyles();
  const isTablet = useIsTablet();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Box mb={2}>
        <Typography variant={isTablet ? 'h4' : 'h3'}>Удалить аккаунт родителя</Typography>
      </Box>

      <Box mb={2}>
        <Typography variant={isTablet ? 'body2' : 'body1'} className={classes.grayText}>
          После удаления аккаунта все данные будут потеряны.
        </Typography>
      </Box>

      <Button variant="outlined" size={isTablet ? 'medium' : 'large'} onClick={handleOpenModal}>
        Удалить аккаунт
      </Button>

      <RemoveAccountModal open={openModal} onClose={handleCloseModal} />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  grayText: {
    color: theme.palette.neonGray['500'],
  },
}));

export default SettingsRemoveAccount;
