import React, { useEffect, useState } from 'react';
import { Box, makeStyles, TextField, Theme } from '@material-ui/core';

import { NumberInput } from './UI/NumberInputs';

interface ICodeConfirmProps {
  length: number;
  onFill: (code: string) => void;
  error: boolean;
}

let refsArray: HTMLInputElement[] = [];

const CodeConfirm: React.VFC<ICodeConfirmProps> = ({ length, onFill, error }) => {
  const classes = useStyles();
  const [code, setCode] = useState<string[]>([]);
  const inputs = new Array(length).fill('');

  // Effects
  useEffect(() => {
    // focus first input
    if (refsArray.length) {
      refsArray[0].focus();
    }

    return () => {
      refsArray = [];
    };
  }, []);

  // Handlers
  const handleKeyDown = (e: any, index: number) => {
    if (e.nativeEvent.key === 'Backspace') {
      if (refsArray[index - 1]) {
        setTimeout(() => refsArray[index - 1].focus(), 0);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const value = e.target.value;
    const isLastInput = index === length - 1;

    // change value
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    // return code
    if (isLastInput) {
      onFill(newCode.join(''));

      return;
    }

    // focus next input
    if (value.length && refsArray[index + 1]) {
      setTimeout(() => refsArray[index + 1].focus(), 0);
    }
  };

  const handleFocus = (index: number) => {
    // clear value on focus
    setCode(prevCode => {
      const newCode = [...prevCode];

      newCode[index] = '';
      return newCode;
    });
  };

  // Renders
  const renderInput = (index: number) => {
    return (
      <TextField
        value={code[index]}
        key={index}
        variant="outlined"
        className={classes.textField}
        inputProps={{ style: { textAlign: 'center' }, maxLength: 1 }}
        inputRef={input => (input ? (refsArray[index] = input) : null)}
        onKeyDown={e => handleKeyDown(e, index)}
        onFocus={() => handleFocus(index)}
        onChange={e => handleChange(e, index)}
        InputProps={{ inputComponent: NumberInput, className: classes.input }}
        error={error}
      />
    );
  };

  return (
    <Box display="flex" justifyContent="center">
      {inputs.map((item, index) => renderInput(index))}
    </Box>
  );
};

export default CodeConfirm;

export const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: 64,
    [theme.breakpoints.down('lg')]: {
      width: 54,
    },
    '&:not(:last-child)': {
      marginRight: '30px',
      [theme.breakpoints.down('lg')]: {
        marginRight: theme.spacing(2),
      },
    },
  },
  input: {
    '& > input': {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '24px',
      padding: '18px 7px',
    },
  },
}));
