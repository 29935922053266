import AbstractFactory from '../../../base/AbstractFactory';
import { Profile } from '../models/Profile';
import { isValidDate, parseApiDate } from '../../../helpers/dateHelpers';

export class ProfileFactory extends AbstractFactory {
  createProfile = (data: any) => {
    const profile = new Profile(data);

    profile.birthday = parseApiDate(data.birthday);

    if (!isValidDate(profile.birthday)) {
      profile.birthday = null;
    }

    return profile;
  };
}
