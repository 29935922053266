import { RouteComponentProps } from 'react-router';

import { IRoute, IRouteWithOriginPath } from '../types/RouteTypes';

interface Params {
  [key: string]: string;
}

// Замена динамических роутов значениями параметров
// "/pizza/:pizzaId" станет "/pizza/1"
const fillValuesInPath = (pathOrigin: string, props: RouteComponentProps<Params>): string => {
  const { params } = props.match;
  const paramKeys = Object.keys(params);

  if (paramKeys.length) {
    return paramKeys.reduce((path, paramKey) => path.replace(`:${paramKey}`, params[paramKey]), pathOrigin);
  }
  return pathOrigin;
};

/**
 * по строке '/:contractId/settings'
 * вернёт: ['/', '/:contractId', '/:contractId/settings']
 */
const getRoutes = (path: string): string[] => {
  const routes: string[] = [];
  const matches = Array.from(path.matchAll(/\//g));

  for (let i = 0; i < matches.length; i++) {
    const nextMatch = matches[i + 1];

    if (i === 0) {
      routes.push('/');
    }

    if (nextMatch) {
      routes.push(path.substring(0, nextMatch.index));
    } else {
      if (path !== '/') {
        // lats route with full path
        routes.push(path);
      }
    }
  }

  return routes;
};

const getBreadcrumb = (routes: IRoute[], props: RouteComponentProps): IRouteWithOriginPath[] => {
  const parsedRoutes = getRoutes(props.match.path);
  const filteredRoutes = routes.filter(({ path }) => parsedRoutes.includes(path));

  return filteredRoutes.map(({ path, ...rest }, index) => {
    let newPath = fillValuesInPath(path, props);

    let search = null;
    if (index === filteredRoutes.length - 1) {
      search = props.location.search;
    }

    return {
      path: newPath,
      originPath: path,
      search,
      ...rest,
    };
  });
};

export default getBreadcrumb;
