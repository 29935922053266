import React from 'react';
import { Box, Button, Grid, makeStyles, TextField, Theme, Tooltip, Typography } from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

import QDialog from '../../../components/UI/QDialog';
import { Child } from '../../../modules/children/models/Child';
import { useIsTablet } from '../../../hooks/useIsTablet';

const TooltipText: React.FC = () => {
  const classes = useStyles();

  return (
    <Box m={1}>
      <Typography variant="caption" className={classes.tooltipText}>
        Номер транспортной карты отображен на развороте пластиковой карты.
      </Typography>
    </Box>
  );
};

interface IAddTkCardModalProps {
  child: Child | undefined;
  open: boolean;
  onClose: () => void;
}

const AddTkCardModal: React.FC<IAddTkCardModalProps> = props => {
  const { open, onClose, child } = props;
  const classes = useStyles();
  const isTablet = useIsTablet();

  return (
    <QDialog onClose={onClose} open={open} onBackdropClick={onClose}>
      <Box mb={1}>
        <Typography variant="h3">Добавить транспортную карту</Typography>
      </Box>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography variant="subtitle1" className={classes.name}>
            {child?.firstLastName}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.text}>
            Для добавления транспортной карты, пожалуйста, введите номер транспортной карты, принадлежащей ребенку.
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            size={isTablet ? 'small' : 'medium'}
            fullWidth
            placeholder="Номер ТК"
            InputProps={{
              endAdornment: (
                <Tooltip
                  placement="right-start"
                  classes={{ tooltip: classes.tooltip, tooltipPlacementRight: classes.tooltipPlacement }}
                  title={<TooltipText />}
                >
                  <HelpOutlineOutlinedIcon className={classes.icon} />
                </Tooltip>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" size={isTablet ? 'small' : 'medium'} fullWidth>
            Добавить
          </Button>
        </Grid>
      </Grid>
    </QDialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  name: {
    color: theme.palette.neonGray['700'],
  },
  text: {
    color: theme.palette.neonGray['500'],
  },
  icon: {
    color: theme.palette.neonGray[600],
  },
  tooltipText: {
    color: 'black',
  },
  tooltip: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
  },
  tooltipPlacement: {
    marginLeft: 65,
    position: 'relative',
    width: 172,

    '&:before': {
      position: 'absolute',
      width: 8,
      height: 8,
      backgroundColor: '#ffffff',
      content: `''`,
      left: -4,
      transform: 'rotate(45deg)',
      top: 8,
    },
  },
}));

export default AddTkCardModal;
