import NumberFormat, { NumberFormatProps } from 'react-number-format';

export const BaseNumberInput = (props: any, otherProps?: NumberFormatProps) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      {...otherProps}
    />
  );
};

export const NumberInput = (props: any) => {
  return BaseNumberInput(props, {
    decimalScale: 0,
  });
};

export const MoneyInput = (props: any) => {
  return BaseNumberInput(props, {
    thousandSeparator: true,
  });
};
