import { Box, makeStyles, Typography } from '@material-ui/core';
import Account from 'assets/images/account-tooltip.png';
import React, { FC } from 'react';

interface IAccountTooltipProps {}

const AccountTooltip: FC<IAccountTooltipProps> = () => {
  const classes = useStyles();
  // Effects

  // Handlers

  // Renders
  return (
    <>
      <Box className={classes.tooltipData} display="flex" justifyContent="flex-end">
        <img src={Account} alt="" />
      </Box>
      <Box mt={0.5} mb={1} ml={5}>
        <Typography variant="caption" color="textPrimary">
          Номер счета
        </Typography>
      </Box>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  tooltipData: {
    position: 'relative',
    '&:before': {
      position: 'absolute',
      top: 39,
      left: 23,
      content: `''`,
      width: 8,
      height: 97,
      border: '1px solid #E1E4E7',
      borderRight: 'none',
      borderTopLeftRadius: 24,
      borderBottomLeftRadius: 24,
    },
  },
}));

export default AccountTooltip;
