import AbstractModel from '../../../base/AbstractModel';
import Attendance from './Attendance';

export default class AttendanceDay extends AbstractModel {
  date: Date | null = null;
  attendance: Attendance[] | null = null;

  constructor(data: any) {
    super();
    this.load(data);
  }
}
