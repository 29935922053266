import { makeStyles, Theme } from '@material-ui/core';

export const useCommonStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: 'none',
    color: '#000000',
  },
  bold: {
    fontWeight: 500,
  },
  grey: {
    color: theme.palette.neonGray['500'],
  },
  menuItem: () => {
    const borderRadius = theme.shape.borderRadius + 'px';

    return {
      padding: '12px 16px',
      border: `1px solid ${theme.palette.neonGray['200']}`,
      borderBottom: 'none',
      '&:first-child': {
        borderRadius: theme.spacing(borderRadius, borderRadius, 0, 0),
      },
      '&:last-child': {
        borderRadius: theme.spacing(0, 0, borderRadius, borderRadius),
        borderBottom: `1px solid ${theme.palette.neonGray['200']}`,
      },
    };
  },
}));

export const useAddChildCommonStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.neonGray[600],
  },
  tooltip: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
  },
  tooltipPlacement: {
    marginLeft: 65,
    position: 'relative',
    width: 228,

    '&:before': {
      position: 'absolute',
      width: 8,
      height: 8,
      backgroundColor: '#ffffff',
      content: `''`,
      left: -4,
      transform: 'rotate(45deg)',
      top: 8,
    },
  },
}));
