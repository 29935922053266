import AbstractModel from '../../../base/AbstractModel';

export default class MenuItemModel extends AbstractModel {
  text: string | null = null;
  price: number | null = null;
  weight: number | null = null;
  enabled: boolean | null = null;
  blockId: number | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
