import React from 'react';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import SelectMenuItem from './SelectMenuItem';
import { useRootStore } from '../../base/hooks/useRootStore';
import QSelectChildren from './QSelectChildren';
import { useCommonStyles } from '../../styles/CommonStyles';

interface IChildrenSelectProps {
  contractId: number | string; // empty string to show empty QSelect
  onChange: (contractId: number) => void;
  numberType: 'obrcard' | 'tkcard';
}

const ChildrenSelect: React.FC<IChildrenSelectProps> = observer(props => {
  const { contractId, onChange, numberType } = props;
  const { childrenStore } = useRootStore();
  const { children } = childrenStore;
  const classes = useCommonStyles();

  return (
    <FormControl variant="outlined" fullWidth>
      {!contractId && <InputLabel id="select-label">Счет пополнения</InputLabel>}
      <QSelectChildren value={contractId} onChange={onChange}>
        {children.map(child => (
          <MenuItem value={child.contractId || 0} classes={{ root: classes.menuItem }}>
            <SelectMenuItem
              avatarUrl={child.avatarUrl}
              primary={child.firstLastName}
              secondary={
                numberType === 'obrcard'
                  ? String(child.contractId || 0)
                  : String(child.cards.find(card => card.type === 1)?.number || 0)
              }
            />
          </MenuItem>
        ))}
      </QSelectChildren>
    </FormControl>
  );
});

export default ChildrenSelect;
