import { makeAutoObservable } from 'mobx';

import PurchasesService from './PurchasesService';
import Purchase from './models/Purchase';
import { Child } from '../children/models/Child';

export default class PurchasesStore {
  purchases: Purchase[] = [];
  loading: boolean = false;

  private purchasesService: PurchasesService;

  constructor() {
    makeAutoObservable(this);
    this.purchasesService = new PurchasesService();
  }

  getPurchases = (filterByContractId: number | undefined, children: Child[]) => {
    this.setLoading(true);

    this.purchasesService
      .getPurchases(filterByContractId, children)
      .then(purchases => {
        this.setPurchases(purchases);
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  getPurchasesById = (contractId?: number) => {
    this.setLoading(true);

    return this.purchasesService
      .getPurchasesById(contractId)
      .then(purchases => {
        this.setPurchases(purchases);
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  private setLoading = (loading: boolean) => (this.loading = loading);

  private setPurchases = (purchases: Purchase[]) => (this.purchases = purchases);
}
