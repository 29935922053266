import React, { useState } from 'react';
import { Box, Grid, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { postfixHelper } from '../../../helpers/stringHelper';
import currencyFormat from '../../../helpers/currencyFormat';
import Food from '../../../modules/preorder/models/Food';
import SquareIcon from '../../../components/UI/Icons/SquareIcon';
import { FoodCartIcon, FoodRepeatIcon } from '../../../components/UI/Icons/SvgIcons';
import BadgeDot from '../../../components/UI/BadgeDot';
import { useRootStore } from '../../../base/hooks/useRootStore';
import RepeatOrderModal from './RepeatOrderModal';

interface IDishItemProps {
  food: Food;
}

const DishItem: React.FC<IDishItemProps> = observer(props => {
  const { food } = props;
  const classes = useStyles();
  const { preorderStore } = useRootStore();
  const preorderItem = preorderStore.getFoodInCart(food.id);

  const [open, setOpen] = useState<boolean>(false);
  const [mountModal, setMountModal] = useState<boolean>(false);

  const addToCart = () => {
    if (!preorderItem) {
      preorderStore.addFoodToCart(food);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setMountModal(true);
  };

  return (
    <Box mx={2} my={{ md: '12px', xl: 2 }} className={classes.root}>
      <Box mb="12px">
        <Grid container>
          <Grid item md={7}>
            <Box display="flex" justifyContent="">
              <Box mr="9px">
                <Typography className={classes.name}>{food.name}</Typography>
              </Box>
              <Typography className={classes.grey}>{postfixHelper(food.weight, 'гр.')}</Typography>
            </Box>
          </Grid>
          <Grid item md={5}>
            <Box display="flex" justifyContent="space-between">
              <Typography className={classes.price}>{currencyFormat(food.price)}</Typography>
              {preorderItem && (
                <Tooltip
                  placement="right-start"
                  classes={{ tooltip: classes.tooltip, tooltipPlacementRight: classes.tooltipPlacement }}
                  title={
                    <Typography variant="caption">
                      Добавьте повтор питания, чтобы не создавать предзаказ на это блюдо снова.
                    </Typography>
                  }
                >
                  <Box>
                    <BadgeDot active={!!preorderItem.regular}>
                      <SquareIcon className={classes.btn} component={FoodRepeatIcon} size={24} onClick={handleOpen} />
                    </BadgeDot>
                  </Box>
                </Tooltip>
              )}
              <BadgeDot active={!!preorderItem}>
                <SquareIcon className={classes.btn} component={FoodCartIcon} size={24} onClick={addToCart} />
              </BadgeDot>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <div className={classes.dishContent}>
        {food.items?.map((foodComponent, index) => (
          <Box key={index} display="flex" justifyContent="space-between">
            <Typography className={classes.grey}>{foodComponent.name}</Typography>
            <Typography className={classes.grey}>{postfixHelper(foodComponent.weight, 'гр.')}</Typography>
          </Box>
        ))}
      </div>
      {mountModal && <RepeatOrderModal food={food} open={open} onClose={handleClose} />}
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  name: {
    fontWeight: 500,
  },
  price: {
    fontWeight: 500,
  },
  grey: {
    color: theme.palette.neonGray['500'],
  },
  dishContent: {
    width: 400,
  },
  btn: {
    cursor: 'pointer',
    fill: 'none',
  },
  dot: {
    height: 6,
    width: 6,
    color: theme.palette.red['500'],
  },
  tooltip: {
    width: 172,
    padding: 16,
    borderRadius: 4,
    color: 'black',
    backgroundColor: 'white',
    boxShadow: theme.shadows[10],
  },
  tooltipPlacement: {
    position: 'relative',
    '&:before': {
      position: 'absolute',
      width: 8,
      height: 8,
      backgroundColor: '#ffffff',
      content: `''`,
      left: -4,
      transform: 'rotate(45deg)',
      top: 8,
    },
  },
}));

export default DishItem;
