// [1, 2, 3] => [1, '-', 2, '-', 3]
const arrayFillGaps = <T, U>(arr: T[], pastData: U): (T | U)[] => {
  const result: (T | U)[] = [];
  for (let i = 0; i < arr.length; i++) {
    result.push(arr[i]);
    if (i !== arr.length - 1) {
      result.push(pastData);
    }
  }
  return result;
};

export default arrayFillGaps;
