import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';

import Loader from '../../../components/UI/Loader';
import { rubSignFormat } from '../../../helpers/currencyFormat';
import { useRootStore } from '../../../base/hooks/useRootStore';
import { useIsTablet } from '../../../hooks/useIsTablet';
import { useCommonStyles } from '../../../styles/CommonStyles';
import { isNumber } from '../../../utils/isNumber';

interface ITCardBalanceProps {
  contractId: number | null | undefined;
  amount: number | null | undefined;
}

const TCardBalance: React.VFC<ITCardBalanceProps> = observer(({ contractId, amount }) => {
  const { tCardStore } = useRootStore();
  const classes = useCommonStyles();
  const isTablet = useIsTablet();

  const handleRefreshData = () => {
    contractId && tCardStore.getChildTCard(contractId);
  };

  if (tCardStore.loading) {
    return <Loader minHeight={230} />;
  }

  return (
    <>
      <Typography className={clsx(classes.bold, classes.grey)}>Баланс транспорной карты</Typography>
      <Box mb={2}>
        <Typography variant={isTablet ? 'h3' : 'h1'}>{isNumber(amount) ? rubSignFormat(amount) : ''}</Typography>
      </Box>
      <Box mb={2}>
        <Typography className={classes.grey}>
          Внимание! Для отображения корректного баланса карты необходимо обновить данные.
        </Typography>
      </Box>
      <Box mb={7}>
        <Button variant="outlined" size="small" onClick={handleRefreshData}>
          Обновить данные
        </Button>
      </Box>
    </>
  );
});

export default TCardBalance;
