import React from 'react';
import { Box, Switch, SwitchProps, Typography } from '@material-ui/core';

interface ISwitchWithTextProps extends SwitchProps {}

const SwitchWithText: React.FC<ISwitchWithTextProps> = props => {
  const { checked } = props;

  return (
    <Box display="flex">
      <Box mr={1}>
        <Typography>{checked ? 'Разрешено' : 'Запрещено'}</Typography>
      </Box>
      <Switch {...props} />
    </Box>
  );
};

export default SwitchWithText;
