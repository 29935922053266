import { useState } from 'react';

interface IUseModal {
  open: boolean;
  openModal: () => void;
  closeModal: () => void;
}

export const useModal = (initOpen?: boolean): IUseModal => {
  const [open, setOpen] = useState(initOpen || false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return { open, openModal, closeModal };
};
