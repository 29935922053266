import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'base/hooks/useRootStore';
import useStepHandler from 'hooks/useStepHandler';

import BaseAddChild from './BaseAddChild';
import QDialog from '../UI/QDialog';
import Pages from '../Pages';
import PasswordResetFirstStep from './PasswordResetFirstStep';
import PasswordResetSecondStep from './PasswordResetSecondStep';
import NoBoundPhoneStep from './NoBoundPhoneStep';
import PasswordResetThirdStep from './PasswordResetThirdStep';
import PasswordChangedModal from './PasswordChangedModal';
import NewPasswordModal from './NewPasswordModal';
import ChildAddedModal from './ChildAddedModal';

interface IAddChildModalProps {
  open: boolean;
  onClose: () => void;
}

const AddChildModal: React.FC<IAddChildModalProps> = observer(props => {
  const { open, onClose } = props;
  const { childrenStore } = useRootStore();
  const { addChildLoading } = childrenStore;

  const { step, handleSetStep } = useStepHandler();

  // Handlers
  const handleClose = () => {
    onClose();
    childrenStore.setCurrentRestoreContactId(null);

    setTimeout(() => {
      handleSetStep({ step: 0 });
    }, 150);
  };

  // Renders
  return (
    <QDialog open={open} onClose={handleClose} onBackdropClick={handleClose}>
      <Pages
        activeStep={step}
        pages={[
          <BaseAddChild
            checkPassword={childrenStore.checkPassword}
            loading={addChildLoading}
            handleSetStep={handleSetStep}
          />,
          <NewPasswordModal
            contractId={childrenStore.restoreContractIdData?.contractId}
            password={childrenStore.restoreContractIdData?.password}
            loading={addChildLoading}
            handleSetStep={handleSetStep}
          />,
          <PasswordResetFirstStep handleSetStep={handleSetStep} loading={addChildLoading} />,
          <NoBoundPhoneStep handleSetStep={handleSetStep} handleClose={handleClose} />,
          <PasswordResetSecondStep
            isChildAddingCase
            handleSetStep={handleSetStep}
            message={childrenStore.restoreTokenData?.message}
            restoreId={childrenStore.restoreTokenData?.id}
            contractId={childrenStore.currentRestoreContactId}
          />,
          <PasswordResetThirdStep
            handleSetStep={handleSetStep}
            contractId={childrenStore.currentRestoreContactId}
            loading={addChildLoading}
            token={childrenStore.tokenToRestoreChildPassword}
          />,
          <PasswordChangedModal childName={childrenStore.currentRestorePasswordChildName} />,
          <ChildAddedModal childName={childrenStore.currentRestorePasswordChildName} />,
        ]}
      />
    </QDialog>
  );
});

export default AddChildModal;
