import AbstractModel from '../../base/AbstractModel';

export default class Operation extends AbstractModel {
  type: string | null = null;
  timestamp: number | null = null;
  sum: number | null = null;
  discount: number | null = null;
  text: string | null = null;
  canceled: string | null = null;
  details: string[] | null = null;
  contractId: string | null = null;
  firstName: string | null = null;
  lastName: string | null = null;
  middleName: string | null = null;
  balance: number | null = null;
  date: Date | null = null;
  avatarUrl: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }

  get firstLastName() {
    return `${this.firstName} ${this.lastName}`;
  }
}
