import React from 'react';
import { Radio, RadioProps } from '@material-ui/core';

import SquareIcon from './Icons/SquareIcon';
import { RadioBtnDisabledIcon, RadioBtnEnabledIcon, RadioChecked, RadioUnchecked } from './Icons/SvgIcons';

interface RadioBtnProps extends RadioProps {
  radioSize?: number;
  radioType?: 'enabled' | 'checked';
}

const QRadio: React.FC<RadioBtnProps> = ({ radioSize = 20, radioType, ...props }) => {
  const isEnabledType = radioType === 'enabled';
  return (
    <Radio
      color="primary"
      icon={<SquareIcon component={isEnabledType ? RadioBtnDisabledIcon : RadioUnchecked} size={radioSize} />}
      checkedIcon={<SquareIcon component={isEnabledType ? RadioBtnEnabledIcon : RadioChecked} size={radioSize} />}
      {...props}
    />
  );
};

export default QRadio;
