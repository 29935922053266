import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Button, Divider, IconButton, makeStyles, MenuItem, Theme, Typography } from '@material-ui/core';
import { computed } from 'mobx';
import { isSameDay, isSameMonth } from 'date-fns';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
// import QTabs from './UI/QTabs';
// import QTab from './UI/QTab';

import OperationItem from './OperationItem';
import mapWithPrev from '../helpers/mapWithPrev';
import { getDateWithoutTime, getDay, getMonthSubjCase, getStartDateAndEndDate } from '../helpers/dateHelpers';
import { useIsTablet } from '../hooks/useIsTablet';
import SquareIcon from './UI/Icons/SquareIcon';
import { OpenInNewTabIcon } from './UI/Icons/SvgIcons';
import { useRootStore } from '../base/hooks/useRootStore';
import Loader from './UI/Loader';
import { routes } from '../screens/routes';
import QSelect from './UI/QSelect';
import QDatePicker from './UI/QDatePicker';
import { OperationsParams } from '../modules/operations/interfaces/OperationsInterfaces';

interface IOperationsProps {
  isLimit?: boolean;
}

// type Card = 'all' | 'obrcard' | 'tkcard';

const { today, weekAgo, yesterday, threeMonthsAgo } = getStartDateAndEndDate();

const Operations: React.FC<IOperationsProps> = observer(({ isLimit }) => {
  const classes = useStyles();
  const isTablet = useIsTablet();
  const rootStore = useRootStore();
  const { operationsStore, childrenStore } = rootStore;
  const [contractId, setContractId] = useState<number>(0);
  // const [cardType, setCardType] = useState<Card>('all');
  const [startDate, setStartDate] = useState<Date>(weekAgo);
  const [endDate, setEndDate] = useState<Date>(today);

  const dates: OperationsParams = useMemo(() => {
    return {
      filterStartedAt: getDateWithoutTime(startDate),
      filterEndedAt: getDateWithoutTime(endDate),
    };
  }, [startDate, endDate]);

  // Effects
  useEffect(() => {
    operationsStore.getOperations(dates);
  }, [dates]);

  const operations = contractId
    ? computed(() => operationsStore.filterOperations(contractId)).get()
    : operationsStore.operations;

  // Handlers
  const handleChildChange = (e: ChangeEvent<{ value: unknown }>) => {
    setContractId(e.target.value as number);
  };

  // const handleCardChange = (e: React.ChangeEvent<{ value: unknown }>) => {
  //   setCardType(e.target.value as Card);
  // };

  const handleStartDateInputChange = (date: Date) => {
    setStartDate(date);
  };

  const handleEndDateInputChange = (date: Date) => {
    setEndDate(date);
  };

  const handleDownloadReport = () => {
    operationsStore.getReportFile({ ...dates, contractId });
  };

  // Renders
  return (
    <div>
      <Box display="flex" alignItems="center" mb={{ md: 1, xl: '18px' }}>
        <Box mr="4px" display="flex" justifyContent="space-between" width="100%">
          <Typography variant={isTablet ? 'h3' : 'h1'}>Операции</Typography>
          {!isLimit && (
            <Button variant="contained" color="primary" size="small" onClick={handleDownloadReport}>
              Скачать
            </Button>
          )}
        </Box>

        {isLimit && (
          <Link to={routes.OperationsScreen.path}>
            <IconButton>
              <SquareIcon component={OpenInNewTabIcon} size={24} />
            </IconButton>
          </Link>
        )}
      </Box>

      <Box mb={3.5} className={classes.filters}>
        <Box width="30%">
          <QSelect value={contractId} onChange={handleChildChange} fullWidth className={classes.selects}>
            <MenuItem value={0}>Все дети</MenuItem>
            {childrenStore.children.map(
              child =>
                child.contractId && (
                  <MenuItem key={child.contractId} value={child.contractId} className={classes.childItem}>
                    <Avatar src={child.avatarUrl || ''} />
                    {child.firstName}
                  </MenuItem>
                ),
            )}
          </QSelect>
        </Box>
        {/* <QTabs value={contractId} onChange={handleTabChange}>
          <QTab label="Все дети" value={0} />
          {childrenStore.children.map(child => (
            <QTab key={child.contractId} label={child.firstName} value={child.contractId} />
          ))}
        </QTabs> */}
        <Box width="20%">
          <QDatePicker
            showYearDropdown
            onChange={handleStartDateInputChange}
            selected={startDate}
            dateFormat="dd.MM.yyyy"
            maxDate={today}
            minDate={threeMonthsAgo}
            label="Начало"
          />
        </Box>
        <Box width="20%">
          <QDatePicker
            showYearDropdown
            onChange={handleEndDateInputChange}
            selected={endDate}
            dateFormat="dd.MM.yyyy"
            minDate={threeMonthsAgo}
            maxDate={yesterday}
            label="Конец"
          />
        </Box>
      </Box>

      {/*<RadioGroup row name="card-filter" value={cardType} onChange={handleRadioChange}>*/}
      {/*  <Box mr={5}>*/}
      {/*    <FormControlLabel value="all" control={<QRadio />} label="Все карты" />*/}
      {/*  </Box>*/}
      {/*  <Box mr={5}>*/}
      {/*    <FormControlLabel value="obrcard" control={<QRadio />} label="Обркарта" />*/}
      {/*  </Box>*/}
      {/*  <Box mr={5}>*/}
      {/*    <FormControlLabel value="tkcard" control={<QRadio />} label="Транспортная карта" />*/}
      {/*  </Box>*/}
      {/*</RadioGroup>*/}

      {operationsStore.loading ? (
        <Loader />
      ) : (
        operations &&
        mapWithPrev(operations, (prev, item, index) => {
          let month: JSX.Element | undefined;
          let day: JSX.Element | undefined;

          if (!prev?.date || (item.date && !isSameMonth(prev.date, item.date))) {
            month = (
              <Box mt={3}>
                <Typography variant={isTablet ? 'h6' : 'h3'}>{getMonthSubjCase(item.date || new Date())}</Typography>
              </Box>
            );
          }

          if (!prev?.date || (item.date && !isSameDay(prev.date, item.date))) {
            day = (
              <Box mt={3} mb={1}>
                <Typography
                  component="div"
                  variant={isTablet ? 'inherit' : 'subtitle1'}
                  className={clsx(classes.dayStyle, isTablet && classes.dayStyleTablet)}
                >
                  {getDay(item.date || new Date())}
                </Typography>
              </Box>
            );
          }

          return (
            <React.Fragment key={index}>
              {month}
              {day}
              <OperationItem operation={item} />
              <Divider />
            </React.Fragment>
          );
        })
      )}
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  filters: {
    display: 'flex',
    gap: theme.spacing(4),
  },
  selects: {
    padding: theme.spacing(2, 3),
  },
  childItem: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  dayStyle: {
    color: theme.palette.neonGray['500'],
  },
  dayStyleTablet: {
    fontFamily: 'Roboto',
    fontSize: 16,
    lineHeight: '24px',
  },
}));

export default Operations;
