import AbstractFactory from '../../base/AbstractFactory';
import ColorMap from './types/ColorMap';

export default class LibraryFactory extends AbstractFactory {
  createPublicationList = <T>(Model: any, data: any[]): T[] =>
    data.map(json => {
      const model = new Model(json);

      if (model.status) {
        model.status.color = ColorMap[json.status.color] || json.status.color;
      }

      return model;
    });
}
