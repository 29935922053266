import StoriesApiRepository from './StoriesApiRepository';
import { StoriesFactory } from './StoriesFactory';
import Story from './models/Story';

export default class StoriesService {
  private api: StoriesApiRepository;
  private factory: StoriesFactory;

  constructor() {
    this.api = new StoriesApiRepository();
    this.factory = new StoriesFactory();
  }

  getStories = async () => {
    const { data } = await this.api.getStories();

    return this.factory.createList<Story>(Story, data.data.stories);
  };
}
