import AbstractModel from '../../../base/AbstractModel';
import FoodComponent from './FoodComponent';

export default class Food extends AbstractModel {
  id: number | null = null;
  name: string | null = null;
  price: number | null = null;
  amount: number | null = null;
  weight: number | null = null;
  isRegular: boolean | null = null;
  regular: boolean | null = null;
  items: FoodComponent[] | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
