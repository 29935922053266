import AbstractFactory from '../../../base/AbstractFactory';
import { Auth } from '../models/Auth';
import { parseApiDate } from '../../../helpers/dateHelpers';

export class AuthFactory extends AbstractFactory {
  createAuth = (data: any) => {
    const newData = {
      ...data,
      birthday: data.birthday ? parseApiDate(data.birthday) : null,
    };

    return this.create<Auth>(Auth, newData);
  };
}
