import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useLocation } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import history from './base/routes/history';
import Routes from './base/routes/components/Routes';
import Header from './components/Header';
import Footer from './components/Footer';
import FooterPhoneApk from './components/FooterPhoneApk';
import Loader from './components/UI/Loader';
import { routes } from './screens/routes';
import { useRootStore } from './base/hooks/useRootStore';
import AuthScreen from './screens/auth/AuthScreen';
import CallToInstallApp from './screens/call-to-install-app/CallToInstallApp';
import PassThroughAuthScreen from './screens/auth/PassThroughAuthScreen';
import AuthDoubleCheck from './screens/auth/AuthDoubleCheck';
import { appConfig } from './appConfig';

const App: React.FC = observer(() => {
  const [loading, setLoading] = useState(true);
  const { authStore, childrenStore, profileStore } = useRootStore();
  const { pathname } = useLocation();

  const isPassThroughAuthPath = pathname.includes('/auth/by-token');

  useEffect(() => {
    authStore
      .checkAuth()
      .then(isAuthenticated => {
        if (isPassThroughAuthPath) {
          return;
        }

        if (!isAuthenticated) {
          history.push('/auth');
          return;
        }

        if (!profileStore.profile?.name) {
          profileStore.getProfile().then(({ name, lastname }) => {
            if (!name && !lastname) {
              history.push('/auth');
            }
          });
        }

        if (!childrenStore.children.length) {
          childrenStore.getChildren();
        }
      })

      .finally(() => {
        setLoading(false);
      });
  }, [childrenStore, profileStore, authStore, pathname, isPassThroughAuthPath]);

  if (loading) {
    return <Loader minHeight="100vh" />;
  }

  if (pathname === '/auth') {
    return (
      <GoogleReCaptchaProvider reCaptchaKey={appConfig.recaptchPublicKey}>
        <AuthDoubleCheck checkAuth={authStore.checkAuth}>
          <AuthScreen />

          <CallToInstallApp />

          <ReactNotification />
        </AuthDoubleCheck>
      </GoogleReCaptchaProvider>
    );
  }

  if (isPassThroughAuthPath) {
    return (
      <AuthDoubleCheck checkAuth={authStore.checkAuth}>
        <PassThroughAuthScreen />

        <CallToInstallApp />

        <ReactNotification />
      </AuthDoubleCheck>
    );
  }

  return (
    <>
      <Header />

      <Routes routes={routes} />

      <FooterPhoneApk />
      <Footer />

      <ReactNotification />

      <CallToInstallApp />
    </>
  );
});

export default App;
