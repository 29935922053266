import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Divider, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { Pagination, PaginationItem } from '@material-ui/lab';

import { ExclamationIcon } from '../../../components/UI/Icons/SvgIcons';
import { CloseIcon } from '../../../components/UI/Icons/SvgIcons';
import { useIsTablet } from '../../../hooks/useIsTablet';
import Loader from '../../../components/UI/Loader';
import { useRootStore } from '../../../base/hooks/useRootStore';

interface Props {
  contractId: number;
  handleHideLib?: () => void;
}

const ChildLibrary: React.VFC<Props> = observer(({ contractId, handleHideLib }) => {
  const isTablet = useIsTablet();
  const classes = useStyles();
  const { libraryStore } = useRootStore();
  const { loading } = libraryStore;

  const [page, setPage] = React.useState(1);
  const expiredPublications = libraryStore.publicationsWithStatus;
  const activeBook = expiredPublications[page - 1];

  const handleChange = (event: React.ChangeEvent<unknown>, page: number): void => {
    setPage(page);
  };

  if (loading) {
    return <Loader />;
  }

  if (!activeBook) {
    return null;
  }

  return (
    <Paper elevation={10} className={classes.paper}>
      <Box pt={2} px={3} pb={1} width={{ md: 520, xl: 'auto' }} m={{ md: 'auto' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h3">Книга хочет домой</Typography>
          {isTablet && <CloseIcon onClick={handleHideLib} className={classes.close} />}
        </Box>

        <Box my={1.5}>
          <Divider />
        </Box>

        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <ExclamationIcon />
          </Grid>

          <Grid item>
            <Typography variant="h5">{activeBook?.status?.title}</Typography>
            <Typography variant="body2" color="textSecondary">
              {`"${activeBook?.title}`}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {`(${activeBook?.author})"`}
            </Typography>
          </Grid>
        </Grid>

        <Box my={1.5}>
          <Divider />
        </Box>

        {expiredPublications.length > 1 && (
          <Pagination
            count={expiredPublications.length}
            page={page}
            onChange={handleChange}
            shape="rounded"
            classes={{
              ul: classes.ul,
            }}
            renderItem={item => (
              <PaginationItem {...item} classes={{ root: classes.itemRoot, selected: classes.itemSelected }} />
            )}
          />
        )}
      </Box>
    </Paper>
  );
});

const useStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.down('lg')]: {
      marginLeft: -25,
      marginRight: -25,
    },
  },
  ul: {
    width: 'calc(100% + 1px)',
    justifyContent: 'center',
  },
  itemRoot: {
    '&:hover': {
      background: '#fff',
    },
  },
  itemSelected: {
    background: '#fff !important',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
  },
  close: {
    cursor: 'pointer',
  },
}));

export default ChildLibrary;
