import { makeAutoObservable, runInAction } from 'mobx';

import PreorderService from './PreorderService';
import Food from './models/Food';
import { IPreorderItem, IRegularPreorder } from './types/Preorder';
import PreorderAndWorkingDays from './models/PreorderAndWorkingDays';

export default class PreorderStore {
  availableFood: Food[] = [];
  availableFoodLoading: boolean = false;
  cart: IPreorderItem[] = [];
  preorderAndWorkingDays?: PreorderAndWorkingDays;
  preorderAndWorkingDaysFetched: boolean = false;
  preorderAndWorkingDaysLoading: boolean = false;
  private preorderService: PreorderService;

  constructor() {
    makeAutoObservable(this);
    this.preorderService = new PreorderService();
  }

  get totalCartPrice() {
    return this.cart.reduce((acc, item) => acc + item.amount * (item?.food?.price || 0), 0);
  }

  getAvailableFood = (contractId: number, date: Date) => {
    this.availableFoodLoading = true;

    this.preorderService
      .getAvailableFood(contractId, date)
      .then(food => {
        this.setAvailableFood(food);
        this.setCartItems([]);
      })
      .finally(() => {
        runInAction(() => {
          this.availableFoodLoading = false;
        });
      });
  };

  getPreorderAndWorkingDays = (contractId: number, phone: string) => {
    if (this.preorderAndWorkingDaysFetched) {
      return;
    }

    this.preorderAndWorkingDaysLoading = true;

    this.preorderService
      .getSumPreorderAndWorkingDays(contractId, phone)
      .then(data => {
        this.setPreorderAndWorkingDays(data);
        this.setPreorderAndWorkingDaysFetched(true);
      })
      .finally(() => {
        runInAction(() => {
          this.preorderAndWorkingDaysLoading = false;
        });
      });
  };

  addFoodToCart = (food: Food) => {
    this.cart.push({
      id: food.id || 0,
      amount: 1,
      food,
    });
  };

  setAmount = (addAmount: number, foodId: number) => {
    const found = this.cart.find(item => item.id === foodId);

    if (found) {
      found.amount += addAmount;

      if (found.amount <= 0) {
        this.cart = this.cart.filter(i => i.id !== foodId);
      }
    }
  };

  setRepeat = (foodId: number, regular: IRegularPreorder) => {
    const foundCartItem = this.getFoodInCart(foodId);

    if (foundCartItem) {
      foundCartItem.regular = regular;
    }
  };

  removeRepeat = (foodId: number) => {
    const foundCartItem = this.getFoodInCart(foodId);

    if (foundCartItem) {
      foundCartItem.regular = undefined;
    }
  };

  getFoodInCart = (foodId: number | null) => this.cart.find(orderItem => orderItem.id === foodId);

  setAvailableFood = (food: Food[]) => (this.availableFood = food);

  setCartItems = (cartItems: IPreorderItem[]) => (this.cart = cartItems);

  setPreorderAndWorkingDays = (preorderAndWorkingDays: PreorderAndWorkingDays) =>
    (this.preorderAndWorkingDays = preorderAndWorkingDays);

  setPreorderAndWorkingDaysFetched = (fetched: boolean) => (this.preorderAndWorkingDaysFetched = fetched);
}
