import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Typography } from '@material-ui/core';

import { routes } from '../../routes';
import ChildScheduleHeader from './ChildScheduleHeader';
import { makePath } from '../../../base/routes/utils/makePath';
import { useRootStore } from '../../../base/hooks/useRootStore';
import { addCentsSeparator } from '../../../utils/AddCentsSeparator';

interface IChildFoodProps {
  contractId: number;
}

const ChildFood: React.FC<IChildFoodProps> = observer(props => {
  const { contractId } = props;
  const { childrenStore: store } = useRootStore();

  if (!store.currentDayFood) {
    return null;
  }

  const totalSpent = store.currentDayFood.reduce((acc, { sum }) => acc + Math.abs(sum!), 0);

  return (
    <Box mb={5}>
      <ChildScheduleHeader
        header="Что сегодня ел"
        urlText="Настроить меню"
        url={makePath(routes.MenuSetupScreen.path, contractId)}
      />
      {store?.currentDayFood.length ? (
        <Box mt={2}>
          <Grid container justify="space-between">
            {store.currentDayFood?.map(({ items, timestamp }) => (
              <Grid item md={10} key={timestamp}>
                <Box py={1}>
                  {items?.map(item => (
                    <Typography>{item.name}</Typography>
                  ))}
                </Box>
              </Grid>
            ))}
            <Box ml="auto">
              <Typography color="textSecondary" align="center">
                Потрачено
              </Typography>
              <Typography variant="subtitle1" align="center">
                {addCentsSeparator(totalSpent)} ₽
              </Typography>
            </Box>
          </Grid>
        </Box>
      ) : null}
    </Box>
  );
});

export default ChildFood;
