import { Box, Button, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useIsTablet } from 'hooks/useIsTablet';
import { StepSettingOptions } from 'hooks/useStepHandler';

import PasswordResetModal from './PasswordResetModal';

interface INoBoundPhoneStepProps {
  handleSetStep: (options: StepSettingOptions) => void;
  handleClose: () => void;
}

const NoBoundPhoneStep: FC<INoBoundPhoneStepProps> = ({ handleSetStep, handleClose }) => {
  const isTablet = useIsTablet();

  // Handlers
  const handleBackButton = () => {
    handleSetStep({ action: 'prev' });
  };

  // Renders
  return (
    <PasswordResetModal title="Нет привязанного телефона" handleBackButton={handleBackButton}>
      <Typography>
        Для восстановления пароля нужен номер телефона, привязанный к лицевому счёту. Для добавления номера обратитесь
        по телефону 8 (843) 561-05-05 или на почту support@obrkarta.ru
      </Typography>

      <Box mt={4}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size={isTablet ? 'small' : 'medium'}
          onClick={handleClose}
        >
          Понятно
        </Button>
      </Box>
    </PasswordResetModal>
  );
};

export default NoBoundPhoneStep;
