import { makeAutoObservable, runInAction } from 'mobx';

import TCard from './models/TCard';
import TCardService from './TCardService';

export default class TCardStore {
  childrenTCards: Record<number, TCard> = {};
  loading: boolean = false;

  private tCardService: TCardService;

  constructor() {
    makeAutoObservable(this);
    this.tCardService = new TCardService();
  }

  getTCard = (contractId: number) => {
    return this.childrenTCards[contractId];
  };

  getChildTCard = (contractId: number) => {
    this.setLoading(true);

    this.tCardService
      .getTCardInfo(contractId)
      .then(tCard => {
        runInAction(() => {
          this.childrenTCards[contractId] = tCard;
        });
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  handleClickReplenish = async () => {
    this.setLoading(true);

    window.open('https://social-card.ru/payment', 'socialCardWindow');

    this.setLoading(false);
  };

  setLoading = (value: boolean): void => {
    this.loading = value;
  };
}
