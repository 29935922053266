import React, { useEffect } from 'react';
import { Box, Container, Grid, makeStyles, Typography, Divider } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { isSameMonth, intlFormat, getDate } from 'date-fns';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

import ChildAttendanceTime from '../child/components/ChildAttendanceTime';
import { useRootStore } from '../../base/hooks/useRootStore';
import getContractIdFromLocation from '../../helpers/getContractIdFromLocation';
import mapWithPrev from '../../helpers/mapWithPrev';
import Loader from '../../components/UI/Loader';
import EmptyAttendance from './components/EmptyAttendance';

const ChildAttendanceScreen: React.VFC = observer(() => {
  const { attendanceStore } = useRootStore();
  const classes = useStyles();
  const location = useLocation();
  const contractId = getContractIdFromLocation(location.pathname);
  const { attendance, loading } = attendanceStore;

  useEffect(() => {
    attendanceStore.getAttendance(contractId);
  }, [attendanceStore, contractId]);

  const renderAttendance = () => {
    if (attendance.length) {
      return mapWithPrev(attendance, (prevAttendance, attendanceItem) => {
        const currentDate = attendanceItem.date || new Date();
        const isMonthSame = prevAttendance?.date && isSameMonth(prevAttendance.date, currentDate);

        return (
          <Grid container key={currentDate.toString()} justify="flex-start">
            {!isMonthSame && (
              <Typography variant="h3" className={classes.month}>
                {intlFormat(currentDate, {
                  month: 'long',
                  year: 'numeric',
                }).slice(0, -2)}
              </Typography>
            )}

            <Grid container>
              <Grid
                container
                item
                md={2}
                className={clsx(!isMonthSame ? classes.topDate : classes.date)}
                alignItems="baseline"
              >
                <Box mr={2}>
                  <Typography variant="subtitle1">{getDate(currentDate)}</Typography>
                </Box>
                <Grid item md={2}>
                  <Typography color="textSecondary">{intlFormat(currentDate, { weekday: 'long' })}</Typography>
                </Grid>
              </Grid>

              <Grid container item md={8} direction="column" className={classes.day}>
                {!isMonthSame && (
                  <Box mb={2}>
                    <Grid container justify="space-around">
                      <Typography color="textSecondary">вход</Typography>
                      <div />
                      <Typography color="textSecondary">выход</Typography>
                    </Grid>
                    <Divider className={classes.divider} />
                  </Box>
                )}

                <ChildAttendanceTime attendances={attendanceItem.attendance || []} />
                <Divider variant="fullWidth" className={classes.daysDivider} />
              </Grid>
            </Grid>
          </Grid>
        );
      });
    }

    return <EmptyAttendance />;
  };
  return (
    <Container maxWidth="xl">
      <Typography variant="h1">Посещаемость</Typography>

      <Box mt={3}>{loading ? <Loader minHeight="100vh" /> : renderAttendance()}</Box>
    </Container>
  );
});

const useStyles = makeStyles(theme => ({
  month: {
    marginTop: theme.spacing(3),
    textTransform: 'capitalize',
  },
  topDate: {
    position: 'relative',
    top: 50,
  },
  date: {
    position: 'relative',
    top: 14,
  },
  divider: {
    position: 'absolute',
    left: '-25%',
    right: 0,
    top: 35,
  },
  day: {
    position: 'relative',
  },
  daysDivider: {
    position: 'absolute',
    left: '-25%',
    right: 0,
    bottom: 0,
  },
}));

export default ChildAttendanceScreen;
