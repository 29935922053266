import React from 'react';
import { Box, Button, Container, IconButton, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import TextButton from './UI/TextButton';
import HeaderLink from './UI/header/HeaderLink';
import MyChildren from './UI/header/MyChildren';
import ChildrenTabBar from './UI/header/ChildrenTabBar';
import { CloseIconWhite, QuestionIcon } from './UI/Icons/SvgIcons';
import SquareIcon from './UI/Icons/SquareIcon';
import Logo from './UI/Icons/Logo';
import ProfileMenu from './UI/header/ProfileMenu';
import { useIsTablet } from '../hooks/useIsTablet';
import { createViewBoxHelper } from './UI/Icons/createViewBoxHelper';
import { useRootStore } from '../base/hooks/useRootStore';
import { appConfig } from '../appConfig';

const VIEWBOX = createViewBoxHelper(16);

const Header: React.FC = () => {
  const { profileStore, childrenStore } = useRootStore();
  const classes = useStyles();
  const isTablet = useIsTablet();

  const history = useHistory();

  const [showAlert, setShowAlert] = React.useState(true);

  // Handlers
  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleChangePassword = () => {
    if (!childrenStore.contracIdToChangePassword) {
      return;
    }

    history.push(`${childrenStore.contracIdToChangePassword}/settings/changePassword`);
  };

  // Renders
  return (
    <>
      <div className={classes.root}>
        <Container maxWidth="xl">
          <Box display="flex">
            <Link href={appConfig.obrCarta}>
              <Logo />
            </Link>
            <Box display="flex" flexDirection="column" flex={1}>
              <Box display="flex" height={{ md: '40px', xl: '56px' }} className={classes.firstContainer}>
                <Box py={{ md: 0.5, xl: 1 }} display="flex" flexGrow={1}>
                  <Box mr={2}>
                    <Link href={`https://obrkarta.ru/order?hash=${profileStore.profile?.hash}`}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={clsx(classes.smallestBtn, classes.containedSmallestBtn)}
                      >
                        Заказать карту
                      </Button>
                    </Link>
                  </Box>
                  <Link href={appConfig.insuranceUrl}>
                    <Button
                      variant="outlined"
                      size="small"
                      className={clsx(classes.smallestBtn, classes.outlinedSmallestBtn)}
                    >
                      Страхование
                    </Button>
                  </Link>
                  <Box ml={{ md: '6px', xl: 7 }} display="flex" alignItems="stretch" flexGrow={1}>
                    <Link href={appConfig.faqUrl}>
                      <TextButton
                        className={classes.helpBtn}
                        startIcon={
                          <SquareIcon component={QuestionIcon} size={isTablet ? 12 : 16} viewBox={VIEWBOX} fillNone />
                        }
                        size="small"
                      >
                        Помощь
                      </TextButton>
                    </Link>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box mr={{ md: '16px', xl: 5 }} display="flex" alignItems="stretch">
                    <HeaderLink to="/replenish-obrcard">Пополнить Обркарту</HeaderLink>
                  </Box>
                  <HeaderLink to="/replenish-tkcard">Пополнить Транспортную карту</HeaderLink>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" height={isTablet ? 47 : 64}>
                <div className={classes.myChildren}>
                  <MyChildren to="/" />
                </div>
                <div className={classes.children}>
                  <ChildrenTabBar />
                </div>
                <ProfileMenu />
              </Box>
            </Box>
          </Box>
        </Container>
      </div>
      {showAlert && childrenStore.needChangePassword ? (
        <Box p={1.5} className={classes.alert}>
          <Typography variant="body2" className={classes.alertText}>
            Пожалуйста, смените пароли Обркарт всех детей на более безопасные в настройках
          </Typography>
          <Button className={classes.alertButton} onClick={handleChangePassword}>
            Сменить
          </Button>
          <IconButton className={classes.alertCloseButton} onClick={handleCloseAlert}>
            <CloseIconWhite />
          </IconButton>
        </Box>
      ) : null}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: `2px solid ${theme.palette.neonGray['150']}`,
  },
  alert: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(3),
    height: theme.spacing(8),
    color: theme.palette.common.white,
    backgroundColor: 'rgba(236, 78, 0, 1)',
  },
  alertText: {
    fontSize: theme.spacing(2),
  },
  alertButton: {
    fontSize: '13px',
    fontWeight: 400,
    color: theme.palette.common.white,
    lineHeight: `${theme.spacing(2)}px`,
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.palette.common.white}`,
  },
  alertCloseButton: {
    position: 'absolute',
    top: '50%',
    right: theme.spacing(1.5),
    transform: 'translateY(-50%)',
    '& svg': {
      fill: theme.palette.common.white,
    },
  },
  logo: {
    marginTop: 16,
    marginRight: 23,
  },
  firstContainer: {
    borderBottom: `1px solid ${theme.palette.neonGray['150']}`,
  },
  helpBtn: {
    [theme.breakpoints.down('lg')]: {
      fontSize: 12,
      lineHeight: '16px',

      '& .MuiButton-startIcon': {
        marginRight: 7,
      },
    },
  },
  myChildren: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    height: '100%',
    borderRight: `1px solid ${theme.palette.neonGray['150']}`,
    paddingRight: 50,
    [theme.breakpoints.down('lg')]: {
      paddingRight: 26,
    },
  },
  children: {
    flex: 1,
    width: 0,
  },
  smallestBtn: {
    fontSize: 13,
    lineHeight: '16px',
    whiteSpace: 'nowrap',
  },
  containedSmallestBtn: {
    padding: '12px 16px',
    [theme.breakpoints.down('lg')]: {
      padding: '8px 12px',
    },
  },
  outlinedSmallestBtn: {
    padding: '11px 16px',
    [theme.breakpoints.down('lg')]: {
      padding: '7px 12px',
    },
  },
}));

export default observer(Header);
