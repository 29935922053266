import AbstractModel from '../../../base/AbstractModel';
import { Child } from '../../children/models/Child';
import PurchaseItem from './PurchaseItem';

export default class Purchase extends AbstractModel {
  type: string | null = null;
  date: Date | null = null;
  sum: number | null = null;
  discount: number | null = null;
  text: string | null = null;
  canceled: boolean | null = null;
  items: PurchaseItem[] | null = null;
  child: Child | null = null;
  timestamp: number | null = null;

  constructor(data: any) {
    super();
    this.load(data);
  }
}
