import AbstractModel from '../../../base/AbstractModel';

export class CheckPassword extends AbstractModel {
  needChangePassword: boolean = false;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
