import AbstractModel from '../../../base/AbstractModel';

export class Profile extends AbstractModel {
  hash: string | null = null;
  name: string | null = null;
  middlename: string | null = null;
  lastname: string | null = null;
  gender: string | null = null;
  birthday: Date | null = null;
  phone: string | null = null;
  email: string | null = null;
  status: number | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }

  get firstLastName() {
    return `${this.name} ${this.lastname}`;
  }
}
