import React from 'react';
import { makeStyles, MenuItem, MenuItemProps } from '@material-ui/core';

const QMenuItem: React.FC<MenuItemProps> = React.forwardRef((props, ref) => {
  const classes = useStyles();
  // Исключил button чтобы избежать ошибки типов c button
  const { children, button, ...rest } = props;

  return (
    <MenuItem ref={ref} className={classes.root} {...rest}>
      {children}
    </MenuItem>
  );
});

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 44,
  },
}));

export default QMenuItem;
