import React, { useEffect, useState } from 'react';
import { Box, Container, Divider, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import history from 'base/routes/history';
import AddChildBtn from 'components/AddChildBtn';
import { useRootStore } from 'base/hooks/useRootStore';
import Operations from 'components/Operations';
import arrayFillGaps from 'helpers/arrayFillGaps';
import AddChildModal from 'components/add-child-modals/AddChildModal';
import Loader from 'components/UI/Loader';

import News from './components/News';
import ChildItem from './components/ChildItem';
import { routes } from '../routes';
import StoriesList from './components/StoriesList';

const MainScreen: React.FC = observer(() => {
  const { childrenStore } = useRootStore();
  const { children } = childrenStore;

  const [open, setOpen] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    if (!childrenStore.loading && !childrenStore.children.length) {
      history.push(routes.AttachChildren.path);
    }
  }, [childrenStore]);

  // Handlers
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Renders
  return (
    <>
      <StoriesList />
      <Container maxWidth="xl">
        <Box mt="40px" mb="7px">
          <Typography variant="h1">Мои дети</Typography>
        </Box>

        <Box mb={3}>
          {childrenStore.loading ? (
            <Loader />
          ) : (
            arrayFillGaps(
              children.map(child => <ChildItem key={child.contractId} child={child} />),
              <Divider />,
            )
          )}
        </Box>

        <Box mb={10}>
          <AddChildBtn onClick={handleOpen} />
        </Box>
        <Operations isLimit={true} />
        <News />
        <AddChildModal open={open} onClose={handleClose} />
      </Container>
    </>
  );
});

export default MainScreen;
