import React from 'react';
import { Avatar, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

interface IHeaderAvatarProps {
  active: boolean;
}

const HeaderAvatar: React.FC<IHeaderAvatarProps> = props => {
  const { active, children } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.avatarContainer, active && classes.active)}>
      <Avatar className={classes.avatar}>{children}</Avatar>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
    width: 48,

    [theme.breakpoints.down('lg')]: {
      height: 36,
      width: 36,
    },
  },
  avatar: {
    [theme.breakpoints.down('lg')]: {
      height: 28,
      width: 28,

      '& img': {
        height: 28,
        width: 28,
      },
    },
  },
  active: {
    padding: '2px',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
  },
}));

export default HeaderAvatar;
