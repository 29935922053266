import React from 'react';
import { Box, Button, Container, Divider, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useIsTablet } from 'hooks/useIsTablet';
import AddChildBtn from 'components/AddChildBtn';
import { useModal } from 'hooks/useModal';
import { useRootStore } from 'base/hooks/useRootStore';
import AddChildModal from 'components/add-child-modals/AddChildModal';
import Loader from 'components/UI/Loader';

import ChildItem from './components/ChildItem';

const AttachChildren: React.VFC = observer(() => {
  const isTablet = useIsTablet();
  const history = useHistory();
  const { childrenStore } = useRootStore();
  const { children } = childrenStore;

  const { open, openModal, closeModal } = useModal();

  const handleContinue = () => {
    history.push('/');
  };

  return (
    <Container maxWidth="xl">
      <Box mt={{ md: 3, xl: '40px' }} mb={{ md: 3, xl: 2 }}>
        <Typography variant={isTablet ? 'h3' : 'h1'}>Присоединить детей</Typography>
      </Box>

      <Box mb={{ md: 2, xl: 4 }}>
        {childrenStore.loading ? (
          <Loader />
        ) : (
          children.map((child, index) => (
            <div key={child.contractId}>
              <ChildItem child={child} />
              {index !== children.length - 1 && <Divider />}
            </div>
          ))
        )}
      </Box>

      <Box mb={{ md: 2, xl: 4 }}>
        <AddChildBtn onClick={openModal} />
      </Box>

      <Button variant="contained" color="primary" disabled={children.length === 0} onClick={handleContinue}>
        Продолжить
      </Button>

      <AddChildModal open={open} onClose={closeModal} />
    </Container>
  );
});

export default AttachChildren;
