import AbstractApiRepository from '../../../base/repositories/AbstractApiRepository';
import ProfileDto from '../forms/ProfileDto';

export default class ProfileApiRepository extends AbstractApiRepository {
  getProfile = () => {
    return this.get({
      url: '/v2/user',
    });
  };

  updateProfile = (data: ProfileDto) => {
    return this.post({
      url: 'v2/user',
      data,
    });
  };

  removeProfile = () => {
    return this.delete({
      url: '/v2/user',
    });
  };
}
