import React from 'react';
import { Box, Button, makeStyles, TextField, Tooltip, Typography } from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useIsTablet } from 'hooks/useIsTablet';
import { useAddChildCommonStyles } from 'styles/CommonStyles';
import { StepSettingOptions } from 'hooks/useStepHandler';
import { useOnlyDigitsHandleChange } from 'hooks/useOnlyDigitsHandleChange';

import QPassword from '../UI/input/QPassword';
import LoaderButton from '../UI/LoaderButton';
import AccountTooltip from './AccountTooltip';

interface IBaseAddChildProps {
  checkPassword: (
    contractId: number,
    password: string,
    callbackForPasswordChange?: () => void,
    callback?: () => void,
  ) => void;
  handleSetStep: (options: StepSettingOptions) => void;
  loading?: boolean;
}

export const REQUIRED_FIELD_TEXT = 'Обязательное поле';

const BaseAddChild: React.FC<IBaseAddChildProps> = observer(props => {
  const { checkPassword, loading } = props;
  const classes = useStyles();
  const addChildCommonClasses = useAddChildCommonStyles();
  const isTablet = useIsTablet();

  const { values, errors, handleSubmit, handleChange } = useFormik({
    initialValues: {
      contractId: '',
      password: '',
    },
    validateOnChange: false,
    validate(values) {
      const errors: any = {};

      if (!values.contractId) {
        errors.contractId = REQUIRED_FIELD_TEXT;
      }

      if (!values.password) {
        errors.password = REQUIRED_FIELD_TEXT;
      }

      return errors;
    },
    onSubmit(values) {
      checkPassword(
        +values.contractId,
        values.password,
        () => props.handleSetStep({ step: 1 }),
        () => {
          props.handleSetStep({ step: 7 });
        },
      );
    },
  });

  // Handlers
  const handleForgotPassword = () => props.handleSetStep({ step: 2 });

  const onlyDigitHandleChange = useOnlyDigitsHandleChange(handleChange);

  // Renders
  return (
    <div>
      <Box mb={{ md: 2, xl: 4 }}>
        <Typography variant={isTablet ? 'h4' : 'h3'}>Добавить ребенка</Typography>
      </Box>

      <Box mb={{ md: '28px', xl: 3 }}>
        <TextField
          variant="outlined"
          type="text"
          placeholder="Номер лицевого счета"
          name="contractId"
          size={isTablet ? 'small' : 'medium'}
          onChange={onlyDigitHandleChange}
          value={values.contractId}
          error={!!errors.contractId}
          helperText={errors.contractId}
          fullWidth
          InputProps={{
            endAdornment: (
              <Tooltip
                placement="right-start"
                classes={{
                  tooltip: addChildCommonClasses.tooltip,
                  tooltipPlacementRight: addChildCommonClasses.tooltipPlacement,
                }}
                title={<AccountTooltip />}
              >
                <HelpOutlineOutlinedIcon className={addChildCommonClasses.icon} />
              </Tooltip>
            ),
          }}
        />
      </Box>

      <Box mb={3}>
        <QPassword
          name="password"
          placeholder="Пароль"
          password={values.password}
          error={errors.password}
          onChange={handleChange}
          showTooltip
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" mb={4}>
        <Button variant="text" className={classes.forgotPassword} onClick={handleForgotPassword}>
          Забыли пароль?
        </Button>
      </Box>

      <LoaderButton
        variant="contained"
        color="primary"
        fullWidth
        size={isTablet ? 'small' : 'medium'}
        loading={loading || false}
        onClick={() => handleSubmit()}
      >
        Добавить ребенка
      </LoaderButton>
    </div>
  );
});

const useStyles = makeStyles(theme => ({
  tooltipCard: {
    filter: 'drop-shadow(0px 2px 2px rgba(0, 11, 38, 0.15)) drop-shadow(0px 0px 8px rgba(0, 11, 38, 0.1))',
  },
  forgotPassword: {
    padding: theme.spacing(0),
  },
}));

export default BaseAddChild;
