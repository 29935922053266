import React from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { routes } from '../../routes';
import ChildScheduleHeader from './ChildScheduleHeader';
import ChildAttendanceTime from './ChildAttendanceTime';
import { makePath } from '../../../base/routes/utils/makePath';
import { useRootStore } from '../../../base/hooks/useRootStore';

interface IChildAttendanceProps {
  contractId: number;
}

const ChildAttendance: React.FC<IChildAttendanceProps> = observer(({ contractId }) => {
  const { childrenStore } = useRootStore();
  const attendance = childrenStore.currentDayAttendance;

  return (
    <Box mt={2} mb={5}>
      <ChildScheduleHeader
        header="Во сколько был в школе"
        urlText="История посещаемости"
        url={makePath(routes.ChildAttendanceScreen.path, contractId)}
      />

      <Grid container>
        <Grid item md={6}>
          <Box textAlign="center" mt={2}>
            <Grid container justify="space-around">
              <Typography variant="body2" color="textSecondary">
                вход
              </Typography>
              <div />
              <Typography variant="body2" color="textSecondary">
                выход
              </Typography>
            </Grid>
            <Box my={1}>
              <Divider />
            </Box>
            {attendance && <ChildAttendanceTime attendances={attendance} />}
          </Box>
        </Grid>

        {/* TODO use API
          <Box ml="auto" mt={3.5} mr={4}>
            <Typography variant="subtitle1">Не опоздал на уроки</Typography>
          </Box>
         */}
      </Grid>
    </Box>
  );
});

export default ChildAttendance;
