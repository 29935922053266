import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

interface IBadgeDotProps {
  active?: boolean;
}

const BadgeDot: React.FC<IBadgeDotProps> = props => {
  const { active, children } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {children}
      {active && (
        <div className={classes.dotBorder}>
          <div className={classes.dot} />
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  dotBorder: {
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: '50%',
    padding: 2,
    backgroundColor: 'white',
  },
  dot: {
    height: 6,
    width: 6,
    borderRadius: '50%',
    backgroundColor: theme.palette.red['500'],
  },
}));

export default BadgeDot;
