import AbstractApiRepository from '../../base/repositories/AbstractApiRepository';
import Operation from './OperationModel';

interface IOperations {
  histories: Operation[];
}

export default class OperationsApiRepository extends AbstractApiRepository {
  getOperations = (query: string) =>
    this.get<IOperations>({
      url: `v2/accounts/histories${query}`,
    });

  getReportFile = (query: string) =>
    this.get<{ url: string }>({
      url: `/v2/accounts/histories/import${query}`,
    });
}
