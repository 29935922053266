import { makeAutoObservable, runInAction } from 'mobx';

import ProfileService from '../services/ProfileService';
import TokenService from '../../token/TokenService';
import ProfileDto from '../forms/ProfileDto';
import { IProfileUpdateValues } from '../types/ProfileTypes';
import { Profile } from '../models/Profile';
import { toApiV1DateStr } from '../../../helpers/dateHelpers';

export class ProfileStore {
  profile: Profile | null = null;
  loading: boolean = false;
  loadingUpdateProfile: boolean = false;
  loadingUpdateEmail: boolean = false;
  private profileService: ProfileService;
  private tokenService: TokenService;

  constructor() {
    makeAutoObservable(this);
    this.profileService = new ProfileService();
    this.tokenService = new TokenService();
  }

  getProfile = () => {
    return this.profileService.getProfile().then(data => {
      runInAction(() => {
        this.profile = data;
      });
      return data;
    });
  };

  removeProfile = () => {
    this.loading = true;

    return this.profileService
      .removeProfile()
      .then(this.tokenService.deleteToken)
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  };

  updateProfile = (values: IProfileUpdateValues) => {
    this.loadingUpdateProfile = true;

    this.requestUpdateProfile(values).finally(() => {
      runInAction(() => {
        this.loadingUpdateProfile = false;
      });
    });
  };

  updateEmail = (values: IProfileUpdateValues) => {
    this.loadingUpdateEmail = true;

    this.requestUpdateProfile(values).finally(() => {
      runInAction(() => {
        this.loadingUpdateEmail = false;
      });
    });
  };

  private requestUpdateProfile = (values: IProfileUpdateValues) => {
    const dto = ProfileDto.populate<ProfileDto>({
      ...values,
      birthday: values.birthday ? toApiV1DateStr(values.birthday) : null,
    });

    return this.profileService.updateProfile(dto).then(() => this.getProfile());
  };
}
