import React from 'react';
import useStepHandler from 'hooks/useStepHandler';
import { useRootStore } from 'base/hooks/useRootStore';

import QDialog from './UI/QDialog';
import Pages from './Pages';
import PasswordResetSecondStep from './add-child-modals/PasswordResetSecondStep';
import PasswordResetThirdStep from './add-child-modals/PasswordResetThirdStep';
import PasswordChangedModal from './add-child-modals/PasswordChangedModal';

interface IChangeChildPasswordModalProps {
  contractId: number;
  open: boolean;
  onClose: () => void;
}

const ChangeChildPasswordModal: React.FC<IChangeChildPasswordModalProps> = props => {
  const { open, onClose } = props;
  const { childrenStore } = useRootStore();

  const { step, handleSetStep } = useStepHandler();

  // Handlers
  const handleClose = () => {
    onClose();

    setTimeout(() => {
      handleSetStep({ step: 0 });
    }, 150);
  };

  // Renders
  return (
    <QDialog open={open} onBackdropClick={handleClose} onClose={handleClose}>
      <Pages
        activeStep={step}
        pages={[
          <PasswordResetSecondStep
            title="Введите код"
            handleSetStep={handleSetStep}
            message={childrenStore.restoreTokenData?.message}
            restoreId={childrenStore.restoreTokenData?.id}
            contractId={childrenStore.currentRestoreContactId}
          />,
          <PasswordResetThirdStep
            handleSetStep={handleSetStep}
            onClose={handleClose}
            contractId={childrenStore.currentRestoreContactId}
            loading={childrenStore.loading}
            token={childrenStore.tokenToRestoreChildPassword}
          />,
          <PasswordChangedModal childName={childrenStore.currentRestorePasswordChildName} />,
        ]}
      />
    </QDialog>
  );
};

export default ChangeChildPasswordModal;
