import React from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useIsTablet } from '../../../hooks/useIsTablet';

interface IHeaderTabLabelProps {
  active: boolean;
  textTop: string;
  textBottom: string;
}

const ChildrenTabLabel: React.FC<IHeaderTabLabelProps> = ({ active, textTop, textBottom }) => {
  const classes = useStyles();
  const isTablet = useIsTablet();

  return (
    <Box display="flex" flexDirection="column" className={clsx(classes.root, active ? classes.active : undefined)}>
      <Typography variant={isTablet ? 'caption' : 'body2'} className={classes.top}>
        {textTop}
      </Typography>
      <Typography variant={isTablet ? 'caption' : 'body2'} className={classes.bottom}>
        {textBottom}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'left',
    color: theme.palette.neonGray['500'],
  },
  top: {
    fontWeight: 400,
  },
  bottom: {
    fontWeight: 500,
  },
  active: {
    color: 'black',
  },
}));

export default ChildrenTabLabel;
