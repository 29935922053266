import AbstractModel from '../../../base/AbstractModel';
import { CheckStatus } from '../types/PaymentTypes';

export default class PaymentCheck extends AbstractModel {
  text: string | null = null;
  value: CheckStatus | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
