import AbstractApiRepository from '../../../base/repositories/AbstractApiRepository';
import { AuthPhone } from '../models/AuthPhone';
import { IAuthCode } from '../types/AuthTypes';
import { appConfig } from '../../../appConfig';

const authUrl = process.env.NODE_ENV !== 'production' ? appConfig.authApiUrl.dev : appConfig.authApiUrl.prod;

export default class AuthApiRepository extends AbstractApiRepository {
  getToken = (accessToken: string) => {
    return this.post({
      url: '/v2/auth',
      data: {
        type: 'WEB',
      },
      config: {
        headers: {
          ZAuthorization: `Bearer ${accessToken}`,
        },
      },
    });
  };

  passThrough = (accessToken: string) => {
    return this.get({ url: `v2/auth/by-token?token=${accessToken}` });
  };

  register = (phone: string, gRecaptchaResponse: string) => {
    return this.post<AuthPhone>({
      url: 'v1/codes',
      data: { phone, gRecaptchaResponse },
      config: {
        baseURL: authUrl,
      },
    });
  };

  sendCode = (data: IAuthCode) => {
    return this.put<AuthPhone>({
      url: 'v1/codes',
      data,
      config: {
        baseURL: authUrl,
      },
    });
  };
}
