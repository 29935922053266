import { OperationsFactory } from './OperationsFactory';
import OperationsApiRepository from './OperationsApiRepository';
import Operation from './OperationModel';
import { Child } from '../children/models/Child';
import { OperationsParams } from './interfaces/OperationsInterfaces';

export default class OperationsService {
  private factory: OperationsFactory;
  private api: OperationsApiRepository;

  constructor() {
    this.factory = new OperationsFactory();
    this.api = new OperationsApiRepository();
  }

  getOperations = async (children: Child[], params: OperationsParams): Promise<Operation[]> => {
    const query = this.prepareOperationsParams(params);
    const data = await this.api.getOperations(query);
    return this.factory.createOperations(Operation, data.data.data, children);
  };

  getReportFile = async (params: OperationsParams) => {
    const query = this.prepareReportParams(params);
    const res = await this.api.getReportFile(query);
    return res.data.data;
  };

  prepareReportParams = (params: OperationsParams) => {
    return `?filterStartedAt=${params.filterStartedAt}&filterEndedAt=${params.filterEndedAt}${
      params.contractId ? `&contractId=${params.contractId}` : ''
    }`;
  };

  prepareOperationsParams = (params: OperationsParams) => {
    return `?filterStartedAt=${params.filterStartedAt}&filterEndedAt=${params.filterEndedAt}`;
  };
}
