import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Container, Grid } from '@material-ui/core';

import ChildSidebar from './components/ChildSidebar';
import ChildCalendar from './components/ChildCalendar';
import { useIsTablet } from '../../hooks/useIsTablet';
import ChildLibrary from './components/ChildLibrary';
import ChildAttendance from './components/ChildAttendance';
import { useRootStore } from '../../base/hooks/useRootStore';
import ChildFood from './components/ChildFood';
import ChildPurchase from './components/ChildPurchase/ChildPurchase';
import useContractId from '../../hooks/useContractId';
import Loader from '../../components/UI/Loader';
import { useModal } from '../../hooks/useModal';

const ChildScreen: React.VFC = observer(() => {
  const { childrenStore, libraryStore } = useRootStore();
  const { loading } = childrenStore;
  const isTablet = useIsTablet();
  const contractId = useContractId();
  const { open: showLib, closeModal } = useModal(true);

  useEffect(() => {
    childrenStore.children.length
      ? childrenStore.setChild(contractId)
      : childrenStore.getChildren().then(() => {
          const childExist = childrenStore.setChild(contractId);
          if (childExist) {
            childrenStore.setChild(contractId);
            libraryStore.getPublications(contractId);
          }
        });
  }, [contractId, childrenStore, libraryStore]);

  if (loading) {
    return <Loader minHeight="100vh" />;
  }

  return (
    <Container maxWidth="xl">
      <Box mt={{ xl: 2 }}>
        {isTablet && showLib && <ChildLibrary contractId={contractId} handleHideLib={closeModal} />}

        <Grid container spacing={4}>
          {!isTablet && <ChildSidebar />}

          <Grid item md={12} xl={8}>
            <ChildCalendar />
            {isTablet && <ChildSidebar />}
            <ChildAttendance contractId={contractId} />
            <ChildFood contractId={contractId} />
            <ChildPurchase contractId={contractId} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
});

export default ChildScreen;
