import React, { useEffect } from 'react';
import {
  Box,
  Chip,
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import { useIsTablet } from '../../hooks/useIsTablet';
import { useRootStore } from '../../base/hooks/useRootStore';
import Loader from '../../components/UI/Loader';
import EmptyLibrary from './components/EmptyLibrary';
import useContractId from '../../hooks/useContractId';

const LibraryScreen: React.FC = observer(() => {
  const classes = useStyles();
  const isTablet = useIsTablet();
  const contractId = useContractId();
  const { libraryStore } = useRootStore();
  const { publications, loading } = libraryStore;

  useEffect(() => {
    libraryStore.getPublications(Number(contractId));
  }, [contractId]);

  if (loading) {
    return <Loader minHeight="100vh" />;
  }

  return (
    <Container maxWidth="xl">
      <Box mb={3}>
        <Typography variant={isTablet ? 'h3' : 'h1'}>Библиотека</Typography>
      </Box>
      {publications.length ? (
        <div>
          <Box mb="21px">
            <Typography variant={isTablet ? 'h4' : 'h3'}>
              Эти книги из школьной библиотеки находятся на руках у ребёнка
            </Typography>
          </Box>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>
                  <Typography variant={isTablet ? 'body2' : 'body1'}>Название</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={isTablet ? 'body2' : 'body1'}>Автор</Typography>
                </TableCell>
                <TableCell className={classes.dataCell}>
                  <Typography variant={isTablet ? 'body2' : 'body1'}>Дата выдачи</Typography>
                </TableCell>
                <TableCell className={classes.dataCell}>
                  <Typography variant={isTablet ? 'body2' : 'body1'}>Срок возврата</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={isTablet ? 'body2' : 'body1'}>Идентификатор записи</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {publications.map(publication => (
                <TableRow key={publication.id} className={classes.tableRow}>
                  <TableCell className={clsx(classes.col, classes.bookTitleCol)}>
                    <Typography variant={isTablet ? 'body2' : 'body1'} className={classes.bookTitle}>
                      {publication.title}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.col}>
                    <Typography variant={isTablet ? 'body2' : 'body1'}>{publication.author}</Typography>
                  </TableCell>
                  <TableCell className={classes.col}>
                    <Typography variant={isTablet ? 'body2' : 'body1'}>{publication.issuanceDate}</Typography>
                  </TableCell>
                  <TableCell className={classes.col}>
                    <Box mb="4px">
                      <Typography variant={isTablet ? 'body2' : 'body1'}>{publication.refundDate}</Typography>
                    </Box>
                    {publication.status && (
                      <Chip label={publication.status.title} style={{ backgroundColor: publication.status.color }} />
                    )}
                  </TableCell>
                  <TableCell className={classes.col}>
                    <Typography variant={isTablet ? 'body2' : 'body1'}>{publication.id}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <EmptyLibrary />
      )}
    </Container>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  tableHead: {
    '& th:first-child': {
      padding: '12px 24px',
      [theme.breakpoints.down('lg')]: {
        padding: '16px',
      },
    },
    '& th': {
      padding: '12px 32px 12px 0',
      [theme.breakpoints.down('lg')]: {
        padding: '12px 16px 12px 0',
      },
    },
  },
  tableRow: {
    '& td:first-child': {
      padding: '24px',
      [theme.breakpoints.down('lg')]: {
        padding: '16px 16px 16px 8px',
      },
    },
    '& td': {
      padding: '24px 32px 24px 0',
      [theme.breakpoints.down('lg')]: {
        padding: '16px 16px 16px 0',
      },
    },
  },
  col: {
    verticalAlign: 'top',
  },
  bookTitleCol: {
    maxWidth: 324,
    [theme.breakpoints.down('lg')]: {
      maxWidth: 257,
    },
  },
  bookTitle: {
    fontWeight: 500,
  },
  dataCell: {
    minWidth: 130,
  },
  redChip: {
    backgroundColor: theme.palette.red['100'],
  },
  greenChip: {
    backgroundColor: theme.palette.green['150'],
  },
}));

export default LibraryScreen;
