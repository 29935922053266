import React from 'react';
import { observer } from 'mobx-react-lite';
import { Container } from '@material-ui/core';

import Operations from '../../components/Operations';

const OperationsScreen: React.VFC = observer(() => {
  return (
    <Container maxWidth="xl">
      <Operations />
    </Container>
  );
});

export default OperationsScreen;
