import { makeAutoObservable, runInAction } from 'mobx';

import Story from './models/Story';
import StoriesService from './StoriesService';
import ProgressTimer from '../../components/Stories/ProgressTimer';

export default class StoriesStore {
  private storiesService: StoriesService;
  defaultDurationMs = 5 * 1000;
  activeStoryIndex: number = 0;
  activeStorySlideIndex: number = 0;
  storiesOpen: boolean = false;
  stories: Story[] = [];
  timerObj: ProgressTimer = new ProgressTimer();
  timerId: number | null = null;
  pause: boolean = false;
  storiesSlidesAmount: number = 0;

  constructor() {
    makeAutoObservable(this);
    this.storiesService = new StoriesService();
  }

  getStories = async () => {
    const stories = await this.storiesService.getStories();

    runInAction(() => {
      this.stories = stories;
    });
  };

  setActiveStory = (index: number) => {
    const slidesAmount = this.stories[index].images?.length!;

    if (!slidesAmount) {
      if (this.storiesOpen) {
        this.setStoriesClose();
      }
      return;
    }
    this.activeStoryIndex = index;
    this.storiesOpen = true;
    this.activeStorySlideIndex = 0;
    this.storiesSlidesAmount = slidesAmount;
    this.handlePlay();
  };

  setActiveStorySlide = (index: number) => {
    this.activeStorySlideIndex = index;
    this.startTimer();
  };

  setStoriesOpen = () => {
    this.storiesOpen = true;
  };

  setStoriesClose = () => {
    this.storiesOpen = false;

    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  };

  // passing the current activeSlide to keep it up to date

  startTimer = (delayMs?: number) => {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }

    if (this.activeStorySlideIndex < this.storiesSlidesAmount) {
      this.timerObj.start(delayMs || this.defaultDurationMs);

      this.timerId = window.setTimeout(() => {
        if (this.activeStorySlideIndex < this.storiesSlidesAmount) {
          this.changeActiveSlide(1);
          this.startTimer();
        }
      }, this.defaultDurationMs);
    }
  };

  changeActiveSlideWithSideEffect = (move: -1 | 1) => {
    const newIndex = this.activeStoryIndex + move;

    if (newIndex < 0 || newIndex > this.stories.length - 1) {
      this.storiesOpen = false;
      return;
    }

    this.setActiveStorySlide(0);
    this.setActiveStory(newIndex);
    this.startTimer();
  };

  handlePlay = () => {
    this.pause = false;

    this.startTimer(this.timerObj.getRestTime());
  };

  handlePause = () => {
    this.pause = true;

    this.timerObj.pause();

    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  };

  changeActiveSlide = (move: -1 | 1) => {
    const newIndex = this.activeStorySlideIndex + move;

    if (newIndex < 0) {
      this.changeActiveSlideWithSideEffect(-1);
      return;
    }

    if (newIndex > this.storiesSlidesAmount - 1) {
      this.changeActiveSlideWithSideEffect(1);
      return;
    }

    this.setActiveStorySlide(newIndex);
  };
}
