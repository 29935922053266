import LibraryFactory from './LibraryFactory';
import LibraryApiRepository from './LibraryApiRepository';
import Publication from './models/Publication';

export default class LibraryService {
  private factory: LibraryFactory;
  private api: LibraryApiRepository;

  constructor() {
    this.factory = new LibraryFactory();
    this.api = new LibraryApiRepository();
  }

  getPublications = async (contractId: number): Promise<Publication[]> => {
    const { data } = await this.api.getPublications(contractId);
    return this.factory.createPublicationList(Publication, data.data?.publications || []);
  };
}
