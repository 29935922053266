import React from 'react';
import { makeStyles, TextField, TextFieldProps, Theme } from '@material-ui/core';

interface IQTextFieldSmallestProps extends Omit<TextFieldProps, 'size'> {}

const QTextFieldSmallest: React.FC<IQTextFieldSmallestProps> = props => {
  const classes = useStyles();

  return <TextField className={classes.textFieldSizeSmallest} inputProps={{ className: classes.input }} {...props} />;
};

const useStyles = makeStyles((theme: Theme) => ({
  textFieldSizeSmallest: {
    '& .MuiFormHelperText-root': {
      marginLeft: 16,
      marginRight: 16,
    },
  },
  input: {
    padding: '14px 16px',
    fontSize: 14,
    lineHeight: '20px',
    height: 20,
    fontWeight: 500,
  },
}));

export default QTextFieldSmallest;
