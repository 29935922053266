import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Container, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField/TextField';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import history from 'base/routes/history';
import { useIsTablet } from 'hooks/useIsTablet';
import { useRootStore } from 'base/hooks/useRootStore';
import SquareIcon from 'components/UI/Icons/SquareIcon';
import TkCard from 'assets/images/kazan-tk-icon.png';
import { ObrCardIcon } from 'components/UI/Icons/SvgIcons';
import { ICard } from 'modules/children/types/ChildrenTypes';
import currencyFormat, { RUB_SIGN } from 'helpers/currencyFormat';
import UploadPhotoModal from 'components/UploadPhotoModal';
import QAvatar from 'components/UI/QAvatar';
import useContractId from 'hooks/useContractId';
import QTextFieldSmallest from 'components/UI/QTextFieldSmallest';
import ChangeChildPasswordModal from 'components/ChangeChildPasswordModal';
import { numberToString, parseNumber } from 'helpers/numberHelper';
import Loader from 'components/UI/Loader';
import { useParams } from 'react-router-dom';

import { routes } from '../routes';

interface IFormItemProps {
  label: string | JSX.Element;
  value: string | JSX.Element | undefined;
}

const FormItem: React.FC<IFormItemProps> = observer(props => {
  const { label, value } = props;
  const classes = useStyles();

  return (
    <Grid container className={classes.formItemContainer}>
      <Grid item md={5} className={classes.formLabelContainer}>
        {typeof label === 'string' ? <Typography className={classes.formLabel}>{label}</Typography> : label}
      </Grid>
      <Grid item md={7}>
        {typeof value === 'string' ? <Typography className={classes.formValue}>{value}</Typography> : value}
      </Grid>
    </Grid>
  );
});

interface IFormTextFieldWithSaveBtnProps extends Omit<Omit<OutlinedTextFieldProps, 'variant'>, 'error'> {
  onSubmit: (e: any) => void;
  error?: string;
  getValue?: (isView: boolean) => string;
}

const FormTextFieldWithSaveBtn: React.FC<IFormTextFieldWithSaveBtnProps> = observer(props => {
  const { value, getValue, error, onSubmit, ...rest } = props;
  const classes = useStyles();

  const [editable, setEditable] = useState(false);

  const handleClick = () => {
    if (editable) {
      onSubmit(undefined);
    }

    setEditable(!editable);
  };

  return (
    <Box display="flex" alignItems="flex-start" justifyContent="space-between">
      <Box mr={2} display="flex" alignItems={editable ? 'baseline' : 'center'} height={48} justifyContent="center">
        {editable ? (
          <QTextFieldSmallest
            value={getValue ? getValue(false) : value}
            variant="outlined"
            error={!!error}
            helperText={error}
            {...rest}
          />
        ) : (
          <Typography className={classes.formValue}>{getValue ? getValue(true) : (value as string)}</Typography>
        )}
      </Box>
      <Button variant="contained" color="primary" size="small" onClick={handleClick} disabled={editable && !!error}>
        {editable ? 'Сохранить' : 'Изменить'}
      </Button>
    </Box>
  );
});

const ChildSettingsScreen: React.FC = observer(() => {
  const { childrenStore } = useRootStore();
  const { loading, children } = childrenStore;

  const classes = useStyles();
  const isTablet = useIsTablet();
  const contractId = useContractId();

  const { changePassword } = useParams<{ changePassword: string }>();

  const [openChangePsw, setOpenChangePsw] = useState(false);
  const [openUploadPhoto, setOpenUploadPhoto] = useState(false);

  useEffect(() => {
    const childExist = childrenStore.setChild(contractId);
    if (!childExist) {
      childrenStore.getChildren().then(() => {
        const childExist = childrenStore.setChild(contractId);
        if (!childExist) {
          history.push(routes.NotFoundScreen.path);
        }
      });
    }
  }, [contractId, childrenStore]);

  useEffect(() => {
    if (changePassword) {
      handleChangePasswordClick();
    }
  }, [changePassword]);

  const child = useMemo(
    () => childrenStore.getChildByContractId(contractId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [childrenStore, contractId, children],
  );
  const card: ICard | undefined = useMemo(() => child?.cards.find(card => card.type === 1), [child]);

  const expenditureLimitForm = useFormik({
    initialValues: {
      expenditureLimit: child?.expenditureLimit ? numberToString(child?.expenditureLimit / 100) : '',
    },
    enableReinitialize: true,
    validate(values) {
      const errors: any = {};

      if (!values.expenditureLimit) {
        errors.expenditureLimit = 'Пустое поле';
      } else if (isNaN(parseNumber(values.expenditureLimit))) {
        errors.expenditureLimit = 'Неверный формат';
      }

      return errors;
    },
    onSubmit(values) {
      const { expenditureLimit } = values;
      const newExpenditureLimitNum = Math.round(parseNumber(expenditureLimit) * 100);

      if (newExpenditureLimitNum !== child?.expenditureLimit) {
        childrenStore.changeExpenditureLimit(contractId, newExpenditureLimitNum);
      }
    },
  });

  // Handlers
  const handleChangePasswordClick = () => {
    const contractId = childrenStore.child?.contractId;
    if (contractId) {
      childrenStore.restorePassword(contractId, () => {
        setOpenChangePsw(true);
      });
    }
  };

  // Renders
  if (loading) {
    return <Loader />;
  }

  return (
    <Container maxWidth="xl">
      <Box mb={3}>
        <Typography variant={isTablet ? 'h3' : 'h1'}>Настройки</Typography>
      </Box>

      <Grid container>
        <Grid item md={6} xl={4}>
          <Box mr={isTablet ? 0 : 3}>
            <Box display="flex" alignItems="center" mb="15px">
              <Box mr="24px">
                <QAvatar
                  avatarUrl={child?.avatarUrl}
                  size={86}
                  showBadge
                  onClickBadge={() => setOpenUploadPhoto(true)}
                />
              </Box>
              <Typography variant="h2" className={classes.childName}>
                {child?.firstLastName}
              </Typography>
            </Box>
            <Divider />
            <Box my={3} display="flex" alignItems="center">
              <Box mr={2}>
                <SquareIcon component={ObrCardIcon} size={40} />
              </Box>
              <Box flex={1}>
                <Typography>Обркарта</Typography>
                <Typography variant="body2" className={classes.cardNum}>
                  {contractId}
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.changePasswordBtn}
                onClick={handleChangePasswordClick}
              >
                Сменить пароль
              </Button>
            </Box>
            <Divider />
            <Box my={3} display="flex" alignItems="center">
              <Box mr={2}>
                <img src={TkCard} alt="tk-card" />
              </Box>
              <Box flex={1}>
                <Typography>Транспортная карта</Typography>
                <Typography variant="body2" className={classes.cardNum}>
                  {card?.number || 0}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item md={10} xl={8}>
          <Typography variant="h3" className={classes.title}>
            1. Информация о счете Обркарты
          </Typography>
          <Box mb={{ md: '28px', xl: '40px' }}>
            <FormItem label="Номер лицевого счета" value={String(child?.contractId || 0)} />
          </Box>

          <Typography variant="h3" className={classes.title}>
            3. Информация о балансе Обркарты
          </Typography>
          <Box mb={{ md: '28px', xl: '40px' }}>
            <FormItem label="Текущий баланс счета" value={child && currencyFormat(child.balance)} />
            <FormItem
              label={
                <div>
                  <Typography className={classes.formLabel}>Лимит расходов в день на буфетную продукцию</Typography>
                  <Typography className={classes.formLabel} variant="body2">
                    (ноль-без ограничений)
                  </Typography>
                </div>
              }
              value={
                <FormTextFieldWithSaveBtn
                  name="expenditureLimit"
                  getValue={(isView: boolean) => {
                    const value = expenditureLimitForm.values.expenditureLimit;
                    return isView ? currencyFormat(parseNumber(value), true) : String(value);
                  }}
                  error={expenditureLimitForm.errors.expenditureLimit}
                  onChange={expenditureLimitForm.handleChange}
                  onSubmit={expenditureLimitForm.handleSubmit}
                  InputProps={{
                    endAdornment: RUB_SIGN,
                  }}
                />
              }
            />
            <FormItem
              label="Текущее количество покупок без предъявления карты"
              value={String(child?.freePayCount || 0)}
            />
          </Box>
        </Grid>
      </Grid>
      <ChangeChildPasswordModal contractId={contractId} open={openChangePsw} onClose={() => setOpenChangePsw(false)} />
      <UploadPhotoModal contractId={contractId} open={openUploadPhoto} onClose={() => setOpenUploadPhoto(false)} />
    </Container>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  avatar: {
    height: 86,
    width: 86,
    borderRadius: 43,
    [theme.breakpoints.down('lg')]: {
      height: 96,
      width: 96,
      borderRadius: 48,
    },
  },
  childName: {
    [theme.breakpoints.down('lg')]: {
      fontSize: 21,
      lineHeight: '24px',
    },
  },
  cardNum: {
    color: theme.palette.neonGray['500'],
  },
  changePasswordBtn: {
    padding: '10px 16px',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  formItemContainer: {
    marginBottom: theme.spacing(2),
  },
  formLabelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  formLabel: {
    color: theme.palette.neonGray['700'],
  },
  formValue: {
    fontWeight: 500,
  },
}));

export default ChildSettingsScreen;
