import rubrikNewBoldTtf from '../assets/fonts/RubrikNew/RubrikNew-Bold.ttf';
import rubrikNewBoldEot from '../assets/fonts/RubrikNew/RubrikNew-Bold.eot';
import rubrikNewBoldWoff from '../assets/fonts/RubrikNew/RubrikNew-Bold.woff';

export const rubrikNewBold = {
  fontFamily: 'Rubrik New',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    local('Rubrik New'),
    local('RubrikNew-Bold'),
    url(${rubrikNewBoldTtf}) format('ttf'),
    url(${rubrikNewBoldEot}) format('eot'),
    url(${rubrikNewBoldWoff}) format('woff2')
  `,
};
