import React from 'react';
import { observer } from 'mobx-react-lite';
import { Container, Typography, Box } from '@material-ui/core';
import { useIsTablet } from 'hooks/useIsTablet';

import SettingsProfileData from './components/SettingsProfileData';
import SettingsContactData from './components/SettingsContactData';
import SettingsChildData from './components/SettingsChildData';
import SettingsRemoveAccount from './components/SettingsRemoveAccount';

const SettingsScreen: React.VFC = observer(() => {
  const isTablet = useIsTablet();

  return (
    <Container maxWidth="xl">
      <Box mb={4}>
        <Typography variant={isTablet ? 'h3' : 'h1'}>Настройки</Typography>
      </Box>

      <SettingsProfileData />

      <SettingsContactData />

      <SettingsChildData />

      <SettingsRemoveAccount />
    </Container>
  );
});

export default SettingsScreen;
