import React, { useEffect } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { isSameDay, isSameMonth } from 'date-fns';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';

import Loader from '../../components/UI/Loader';
import mapWithPrev from '../../helpers/mapWithPrev';
import { useIsTablet } from '../../hooks/useIsTablet';
import EmptyPurchases from './components/EmptyPurchases';
import { getDay, getMonthSubjCase } from '../../helpers/dateHelpers';
import { useRootStore } from '../../base/hooks/useRootStore';
import getContractIdFromLocation from '../../helpers/getContractIdFromLocation';
import ChildPurchaseLine from '../child/components/ChildPurchase/ChildPurchaseLine';
import ChildPurchaseAccordion from '../child/components/ChildPurchase/ChildPurchaseAccordion';

const ChildPurchasesScreen: React.VFC = observer(() => {
  const { purchasesStore } = useRootStore();
  const classes = useStyles();
  const isTablet = useIsTablet();
  const location = useLocation();

  const contractId = getContractIdFromLocation(location.pathname);
  const { purchases, loading } = purchasesStore;

  useEffect(() => {
    purchasesStore.getPurchasesById(contractId);
  }, [contractId, purchasesStore]);

  const renderContent = () => {
    if (purchases.length) {
      return mapWithPrev(purchases, (prev, item, index) => {
        let month: JSX.Element | undefined;
        let day: JSX.Element | undefined;
        const isDinner = item.items && item.items.length > 1;

        if (!prev?.date || (item.date && !isSameMonth(prev.date, item.date))) {
          month = (
            <Box mt={3}>
              <Typography variant={isTablet ? 'h6' : 'h3'}>{getMonthSubjCase(item.date || new Date())}</Typography>
            </Box>
          );
        }

        if (!prev?.date || (item.date && !isSameDay(prev.date, item.date))) {
          day = (
            <Box mt={3} mb={1}>
              <Typography
                component="div"
                variant={isTablet ? 'inherit' : 'subtitle1'}
                className={clsx(classes.dayStyle, isTablet && classes.dayStyleTablet)}
              >
                {getDay(item.date || new Date())}
              </Typography>
            </Box>
          );
        }

        return (
          <div className={classes.wrap} key={index}>
            {month}
            {day}
            {isDinner ? <ChildPurchaseAccordion purchase={item} /> : <ChildPurchaseLine purchase={item} />}
          </div>
        );
      });
    }

    return <EmptyPurchases />;
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h1">Покупки</Typography>

      <Box mt={3}>{loading ? <Loader minHeight="500px" /> : renderContent()}</Box>
    </Container>
  );
});

const useStyles = makeStyles(theme => ({
  wrap: {
    maxWidth: 800 + theme.spacing(4),
  },
  dayStyle: {
    color: theme.palette.neonGray['500'],
  },
  dayStyleTablet: {
    fontFamily: 'Roboto',
    fontSize: 16,
    lineHeight: '24px',
  },
}));

export default ChildPurchasesScreen;
