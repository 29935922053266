const firstLetterUpperCase = (text: string) => {
  if (!text.length) {
    return text;
  }

  return `${text[0].toUpperCase()}${text.substr(1)}`;
};

// \u00A0 - неразрывный пробел
const postfixHelper = (text: string | number | null, postfix: string) => `${text || ''}\u00A0${postfix}`;

export { firstLetterUpperCase, postfixHelper };
