import { makeAutoObservable } from 'mobx';
import { setAccessCredentials } from 'base/ApiAdapter';

import AuthService from '../services/AuthService';
import { IValues } from '../types/AuthTypes';
import ProfileService from '../../profile/services/ProfileService';
import TokenService from '../../token/TokenService';
import ProfileDto from '../../profile/forms/ProfileDto';
import { toApiV1DateStr } from '../../../helpers/dateHelpers';
import { rootStore } from '../../../base/RootStore';
import { Auth } from '../models/Auth';
import history from '../../../base/routes/history';
import { routes } from '../../../screens/routes';

export const initialValues = (auth: Auth | null): IValues => ({
  name: auth?.name || '',
  lastname: auth?.lastname || '',
  middlename: auth?.middlename || '',
  birthday: auth?.birthday || null,
  birthdayValid: true,
  gender: auth?.gender || null,
  email: auth?.email || '',
});

export class AuthStore {
  step = 0;
  tokenId = '';
  phone = '';
  gRecaptchaResponse = '';
  auth: Auth | null = null;
  loading: boolean = false;

  private authService: AuthService;
  private profileService: ProfileService;
  private tokenService: TokenService;

  constructor() {
    makeAutoObservable(this);

    this.authService = new AuthService();
    this.profileService = new ProfileService();
    this.tokenService = new TokenService();
  }

  register = (): void => {
    this.setLoading(true);

    this.authService
      .registerPhone(`+7${this.phone}`, this.gRecaptchaResponse)
      .then(({ id }) => {
        if (id) {
          this.tokenId = id;
          this.setStep(1);
        }
      })
      .finally(() => this.setLoading(false));
  };

  sendCode = (code: string) => {
    this.setLoading(true);

    this.authService
      .sendCode({ id: this.tokenId, code })
      .then(({ accessToken }) => {
        if (accessToken) {
          return this.getToken(accessToken);
        }
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  sendCodeToRestorePassword = (values: { id: string; code: string }, callback: (token: string | null) => void) => {
    this.setLoading(true);

    this.authService
      .sendCode(values)
      .then(({ accessToken }) => {
        callback(accessToken);
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  getToken = (access: string | Auth) => {
    if (typeof access === 'string') {
      return this.authService.authorize(access).then(this.checkAuthNameAndLastname);
    }

    this.checkAuthNameAndLastname(access);
  };

  checkAuthNameAndLastname = (auth: Auth) => {
    const { name, lastname } = auth;

    if (name && lastname) {
      this.setUserAuth(auth);
      return;
    }

    const { token, deviceId } = auth;

    if (token && deviceId) {
      setAccessCredentials(token, deviceId);
      this.setAuth(auth);
      this.setStep(2);
    }
  };

  setUserAuth = (auth: Auth) => {
    this.setAuth(auth);
    this.saveToken(auth);

    history.push(routes.MainScreen.path);
  };

  saveToken = (auth: Auth) => {
    const { token, deviceId } = auth;

    if (token && deviceId) {
      this.tokenService.saveToken(token, deviceId);
    }
  };

  passThrough = (accessToken: string) => {
    return this.authService.passThrough(accessToken).then(this.setUserAuth);
  };

  updateProfile = (values: IValues) => {
    this.setLoading(true);

    const dto = ProfileDto.populate<ProfileDto>({
      ...values,
      phone: this.phone,
      birthday: values.birthday ? toApiV1DateStr(values.birthday) : null,
    });

    return this.profileService
      .updateProfile(dto)
      .then(() => {
        if (this.auth) {
          this.saveToken(this.auth);
        }
        rootStore.childrenStore.getChildren();
      })
      .finally(() => this.setLoading(false));
  };

  checkAuth = () => {
    return this.tokenService.checkSavedAuth();
  };

  logout = () => {
    this.tokenService.deleteToken().then(() => {
      window.location.assign('/');
    });
  };

  setLoading = (value: boolean): void => {
    this.loading = value;
  };

  setStep = (step: number): void => {
    this.step = step;
  };

  setPhone = (phone: string): void => {
    this.phone = phone;
  };

  setGRecapthaResponse = (gRecaptchaResponse: string): void => {
    this.gRecaptchaResponse = gRecaptchaResponse;
  };

  setAuth = (auth: Auth) => {
    this.auth = auth;
  };
}
