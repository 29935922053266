import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';

import SquareIcon from '../../../components/UI/Icons/SquareIcon';
import { CartMinusIcon, CartPlusIcon, CartTrashIcon } from '../../../components/UI/Icons/SvgIcons';

interface IAmountBtnProps {
  amount: number;
  changeAmount: (amount: number) => () => void;
}

const AmountBtn: React.FC<IAmountBtnProps> = props => {
  const { changeAmount, amount } = props;
  const classes = useStyles();
  const minusIcon = amount === 1 ? CartTrashIcon : CartMinusIcon;

  return (
    <div className={classes.amountContainer}>
      <Typography className={classes.amount} align="center">
        {amount}
      </Typography>
      <div className={classes.amountPanel}>
        <SquareIcon className={classes.btn} component={CartPlusIcon} size={24} onClick={changeAmount(1)} />
        <Typography align="center">{amount}</Typography>
        <SquareIcon className={classes.btn} component={minusIcon} size={24} onClick={changeAmount(-1)} />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  amountContainer: {
    position: 'relative',
    marginRight: 16,
    '&:hover $amountPanel': {
      display: 'flex',
    },
  },
  amount: {
    width: 32,
  },
  amountPanel: {
    position: 'absolute',
    top: '-4px',
    left: '-37px',
    display: 'none',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    height: 32,
    width: 106,
    padding: '3px 7px',
    borderRadius: 4,
    backgroundColor: theme.palette.neonGray['50'],
    border: `1px solid ${theme.palette.neonGray['200']}`,
  },
  btn: {
    cursor: 'pointer',
  },
}));

export default AmountBtn;
