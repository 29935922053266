import AbstractFactory from '../../base/AbstractFactory';
import MenuItemModel from './models/MenuItemModel';
import MenuCategory from './models/MenuCategory';

export default class MenuSetupFactory extends AbstractFactory {
  createMenuCategories = (menuItems: string[], data: any[][]): MenuCategory[] =>
    menuItems.map((menu, index) => this.createMenuCategory(menu, data[index]));

  createMenuCategory = (menuItem: string, data: any[]): MenuCategory => {
    const menu = data.map(this.createMenuItem);

    return new MenuCategory({
      title: menuItem,
      menu: menu,
    });
  };

  createMenuItem = (data: any) => {
    const menuItem = new MenuItemModel(data);

    menuItem.weight = (data.weight * 1000) || 0;

    return menuItem;
  };
}
