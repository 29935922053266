import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { ChevronIcon } from '../../../components/UI/Icons/SvgIcons';
import { useCommonStyles } from '../../../styles/CommonStyles';

interface Props {
  header: string;
  urlText: string;
  url: string;
}

const ChildScheduleHeader: React.VFC<Props> = ({ header, urlText, url }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <Grid container justify="space-between" alignItems="center" className={classes.container}>
      <Typography variant="h4">{header}</Typography>

      <Link to={url} className={clsx(commonClasses.link, classes.link)}>
        <Typography variant="body2">{urlText}</Typography>

        <ChevronIcon className={classes.icon} />
      </Link>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.neonGray[50],
    borderRadius: 4,
    padding: '8px 16px',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginLeft: theme.spacing(1.5),
  },
}));

export default ChildScheduleHeader;
