import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import clsx from 'clsx';

import { CancelIcon, DinnerIcon, ExpandIcon } from '../../../../components/UI/Icons/SvgIcons';
import { addCentsSeparator } from '../../../../utils/AddCentsSeparator';
import Purchase from '../../../../modules/purchases/models/Purchase';

interface Props {
  purchase: Purchase;
}

const ChildPurchaseAccordion: React.VFC<Props> = ({ purchase }) => {
  const classes = useStyles();
  const { canceled, text, sum, items, timestamp } = purchase;
  
  return (
    <Accordion square classes={{ root: classes.accordionRoot }}>
      <AccordionSummary expandIcon={<ExpandIcon />} classes={{ content: classes.accordionSummaryContent }}>
        <Box className={classes.accordionItem} flexGrow={1}>
          <Typography>{format(timestamp! * 1000, 'HH:mm')}</Typography>
          <DinnerIcon />
          <Typography className={clsx(canceled && classes.canceled)}>{text}</Typography>
        </Box>
        <Box className={classes.accordionItem}>
          {canceled && (
            <>
              <CancelIcon />
              <Typography variant="body2" color="textSecondary" component="span">
                Операция отменена
              </Typography>
            </>
          )}
        </Box>
        <Box minWidth={70} maxWidth={70} textAlign="right">
          <Typography className={clsx(canceled && classes.canceled)}>{sum && addCentsSeparator(sum)} ₽</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          {items?.map(detail => (
            <Box display='flex' justifyContent='space-between' key={detail.id}>
              <Typography variant="caption" color="textSecondary" component="p">
                {detail.name}
              </Typography>
              <Box>
                {detail.quantity && detail.quantity > 1 && 
                  <>
                    <Box className={classes.line}/>
                    <Typography component='b' variant='body2'>{`${detail.quantity} шт. `}</Typography>
                    <Typography component='b' variant='body2'>{`${detail.totalPrice} ${detail.currency}`}</Typography>
                  </>
                }
              </Box>
            </Box>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles(theme => ({
  accordionRoot: {
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.neonGray[150]}`,
    '&:before': {
      content: 'none',
    },
  },
  accordionSummaryContent: {
    width: '100%',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    gap: theme.spacing(2),
  },
  accordionItem: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  canceled: {
    textDecoration: 'line-through',
  },
  line: {
    margin: theme.spacing(0, 1),
    transform: 'translateY(-6px)',
    flexGrow: 1, 
    borderBottom: `1px solid ${theme.palette.neonGray[500]}`
  }
}));

export default ChildPurchaseAccordion;
