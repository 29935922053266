import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { BaseNumberInput } from './NumberInputs';

const BaseMaskInput = (props: any, format: string, otherProps?: NumberFormatProps) => {
  return BaseNumberInput(props, {
    format,
    mask: otherProps?.mask || '_',
    ...otherProps,
  });
};

export const PhoneMaskInput = (props: any) => {
  return BaseMaskInput(props, '+7 ### ###-##-##');
};

export const DateMaskInput = (props: any, otherProps?: NumberFormatProps) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      format="##.##.####"
      mask="_"
      {...otherProps}
    />
  );
};
