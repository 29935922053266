import React, { FC } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

import PasswordResetModal from './PasswordResetModal';

interface IChildAddedModalProps {
  childName: string;
}

const ChildAddedModal: FC<IChildAddedModalProps> = ({ childName }) => {
  const classes = useStyles();

  // Renders
  return (
    <PasswordResetModal title="Ребенок добавлен">
      <Box mb={4}>
        <Typography variant="body1" className={classes.text}>
          {childName} добавлен.
        </Typography>
      </Box>
    </PasswordResetModal>
  );
};

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.secondary,
  },
}));

export default ChildAddedModal;
