import React, { useCallback, useEffect } from 'react';
import { Box, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import akbarsBank from '../../assets/icons/akbars-bank.svg';
import obrCard from '../../assets/icons/obrcard-logo.svg';
import authCats from '../../assets/images/auth-cats.svg';
import RSPhone from './components/steps/RSPhone';
import RSCodeConfirm from './components/steps/RSCodeConfirm';
import RSPersonalData from './components/steps/RSPersonalData';
import Pages from '../../components/Pages';
import { useRootStore } from '../../base/hooks/useRootStore';
import { useIsTablet } from '../../hooks/useIsTablet';

const AuthScreen: React.VFC = observer(() => {
  const { authStore } = useRootStore();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const classes = useStyles();
  const isTablet = useIsTablet();

  // Handlers
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('yourAction');
    authStore.setGRecapthaResponse(token);
  }, [executeRecaptcha]);

  // Effects
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  // Renders
  return (
    <Grid container spacing={isTablet ? 0 : 10} className={classes.container}>
      <Grid item md={12} lg={5} className={classes.login}>
        <Box maxWidth={472} mt={9} mx={13} display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" justifyContent="center">
            <img src={akbarsBank} alt="" />
            <Box mx={1.8} className={classes.separator} />
            <Link href="https://obrkarta.ru">
              <img src={obrCard} alt="" />
            </Link>
          </Box>
          <Box mt={16} mb={5}>
            <Typography variant="h3" align="center">
              Добро пожаловать
            </Typography>
          </Box>

          <Box mb={3}>
            <Pages activeStep={authStore.step} pages={[<RSPhone />, <RSCodeConfirm />, <RSPersonalData />]} />
          </Box>

          <Box mt="auto" textAlign="center">
            <Typography className={classes.helpText} component="span">
              Не получается войти?{' '}
            </Typography>{' '}
            <Link href="mailto:support@obrkarta.ru" underline="none" color="textPrimary">
              <Typography component="span">Напишите в поддержку</Typography>
            </Link>
          </Box>
        </Box>
      </Grid>

      <Grid item md={false} lg={7} className={classes.img}>
        <Box mt={16} ml={7} maxWidth={616}>
          <img className={classes.icon} src={authCats} alt="" />
        </Box>
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '104vh',
  },
  login: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(5),
  },
  separator: {
    borderLeft: `1px solid ${theme.palette.neonGray['200']}`,
    width: 1,
    height: '100%',
  },
  img: {
    background: '#E5E5E5',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  icon: {
    width: '100%',
  },
  helpText: {
    color: theme.palette.neonGray['500'],
  },
}));

export default AuthScreen;
