import NewsApiRepository from './NewsApiRepository';
import NewsFactory from './NewsFactory';
import NewsModel from './models/NewsModel';

export default class NewsService {
  private api: NewsApiRepository;
  private factory: NewsFactory;

  constructor() {
    this.api = new NewsApiRepository();
    this.factory = new NewsFactory();
  }

  getNews = async (): Promise<NewsModel[]> => {
    const { data } = await this.api.getNews();

    return this.factory.createNews(NewsModel, data.data);
  };
}
