import React, { FC, useEffect } from 'react';

import { useRootStore } from '../../base/hooks/useRootStore';
import Loader from '../../components/UI/Loader';
import { useQueryParams } from '../../hooks/useQueryParams';

interface IPassThroughAuthScreenProps {}

const PassThroughAuthScreen: FC<IPassThroughAuthScreenProps> = () => {
  const { authStore } = useRootStore();
  const search = useQueryParams();

  // Effects
  useEffect(() => {
    const token = search.get('token');

    if (token) {
      authStore.passThrough(token);
    }
  }, [search, authStore]);

  // Renders
  return <Loader minHeight="100vh" />;
};

export default PassThroughAuthScreen;
