import React from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';

import QDialog from '../../components/UI/QDialog';
import { useModal } from '../../hooks/useModal';
import { useIsMobile } from '../../hooks/useIsMobile';
import TextButton from '../../components/UI/TextButton';
import obrcardLogo from '../../assets/icons/obrcard-logo.svg';
import mobileLogo from '../../assets/images/mobile_icon.png';
import { useRootStore } from '../../base/hooks/useRootStore';
import { getStoreLink } from '../../modules/call-to-install/helpers/getStoreLink';

const CallToInstallApp = () => {
  const { callToInstallStore } = useRootStore();
  const classes = useStyles();
  const { open, closeModal } = useModal(true);
  const isMobile = useIsMobile();

  const storeLink = getStoreLink();

  const handleCloseModal = () => {
    callToInstallStore.handleFormShow();
    closeModal();
  };

  return (
    <QDialog
      fullScreen
      open={isMobile && open}
      onClose={closeModal}
      classes={{
        paper: classes.paper,
      }}
      contentBoxProps={{
        width: 'auto',
      }}
    >
      <div>
        <img src={obrcardLogo} alt="" />
        <Box display="flex" flexDirection="column" alignItems="center" mt={4.5}>
          <Typography className={classes.header}>В приложении удобнее</Typography>
          <Box mt={1.8} display="flex" flexWrap="noWrap" alignItems="center">
            <a href={storeLink} target="_blank" rel="nofollow noopener noreferrer">
              <Button variant="contained" color="primary" size="small">
                <Typography>Установить</Typography>
              </Button>
            </a>
            <TextButton onClick={handleCloseModal} className={classes.linkColor}>
              Спасибо, не нужно
            </TextButton>
          </Box>
          <img src={mobileLogo} alt="" />
        </Box>
      </div>
    </QDialog>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: 0,
  },
  header: {
    fontSize: 28,
    lineHeight: '36px',
  },
  linkColor: {
    fontWeight: 400,
    color: theme.palette.link['800'],
  },
}));

export default CallToInstallApp;
