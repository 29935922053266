// import FingerprintJS from '@fingerprintjs/fingerprintjs';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import camelCaseKeys from 'camelcase-keys';

import { appConfig } from '../appConfig';
import Notification from '../utils/NotificationUtil';
import { rootStore } from './RootStore';

export const api = axios.create();
// let appDeviceId: string = '';

// statuses
const success = [200, 201];
const errors = [403];

api.defaults.baseURL = process.env.NODE_ENV !== 'production' ? appConfig.apiUrl.dev : appConfig.apiUrl.prod;

if (process.env.NODE_ENV === 'production' && !appConfig.apiUrl.prod) {
  console.error('env.REACT_APP_API_URL - api url is not found!');
}

api.interceptors.request.use(
  async config => {
    // if (!appDeviceId) {
    //   const fingerprint = await FingerprintJS.load();
    //   const { visitorId } = await fingerprint.get();
    //   appDeviceId = visitorId;
    // }

    const newConfig: AxiosRequestConfig = {
      ...config,
      headers: {
        'Content-Type': 'application/json',
        'App-Platform': 'web',
        ...config.headers,
      },
    };

    // if (config.url !== 'v1/codes') {
    //   newConfig.headers = {
    //     ...newConfig.headers,
    //     'App-DeviceId': appDeviceId,
    //   };
    // }
    return newConfig;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  response => {
    if (!success.includes(response.status)) {
      Notification.showError(response.data?.message || 'Неизвестная ошибка');

      return Promise.reject(response);
    }

    if (response?.data?.message?.trim()) {
      Notification.showSuccess(response?.data?.message);
    }

    return { ...response, data: camelCaseKeys(response.data) };
  },
  error => {
    // global showing error messages
    if (error.response?.data) {
      Notification.showError(error.response.data?.message || 'Неизвестная ошибка');

      if (errors.includes(error.response.status)) {
        rootStore.authStore.logout();
      }
    }

    return Promise.reject({
      message: error.response.data?.message || 'Неизвестная ошибка',
      status: error.response.status,
    });
  },
);

export const setAccessCredentials = (token: string, deviceId: number) => {
  api.defaults.headers['ZToken'] = token;
  api.defaults.headers['ZDeviceId'] = deviceId;
};

export const clearAccessCredentials = () => {
  api.defaults.headers['ZToken'] = null;
  api.defaults.headers['ZDeviceId'] = null;
};

export interface IConfig {
  url: string;
  data?: Object;
  config?: AxiosRequestConfig;
}

export interface IResponseV2<T> {
  success: boolean;
  errors: {
    [key: string]: string[];
  } | null;
  message: string | null;
  data: T;
}

export interface IResponseV1<T> {
  status: number;
  timestamp: number;
  items: T;
}

export interface IResponseItems<T> {
  items: T[];
}
