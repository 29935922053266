import { Box, Typography, IconButton, makeStyles } from '@material-ui/core';
import React, { FC, PropsWithChildren } from 'react';
import { useIsTablet } from 'hooks/useIsTablet';

import { ArrowLeft } from '../UI/Icons/SvgIcons';

interface IPasswordResetModalProps {
  title: string;
  handleBackButton?: () => void;
}

const PasswordResetModal: FC<PropsWithChildren<IPasswordResetModalProps>> = ({ title, handleBackButton, children }) => {
  const classes = useStyles();
  const isTablet = useIsTablet();

  // Renders
  return (
    <Box position="relative">
      {handleBackButton ? (
        <IconButton onClick={handleBackButton} className={classes.backButton}>
          <ArrowLeft />
        </IconButton>
      ) : null}
      <Box mb={{ md: 2, xl: 4 }} mt={1}>
        <Typography variant={isTablet ? 'h4' : 'h3'}>{title}</Typography>
      </Box>
      {children}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  backButton: {
    position: 'absolute',
    top: theme.spacing(-5),
    left: theme.spacing(-1.5),
  },
}));

export default PasswordResetModal;
