import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useRootStore } from '../../base/hooks/useRootStore';
import Loader from '../../components/UI/Loader';

const ReplenishObrCardCheckScreen: React.FC = () => {
  const { paymentStore } = useRootStore();
  const { orderId } = useParams<{ orderId: string }>();
  const history = useHistory();

  useEffect(() => {
    const goBack = () => {
      history.push('/');
    };

    const interval = setInterval(() => {
      paymentStore.checkPayment(orderId, cleanup, goBack);
    }, 5000);

    const cleanup = () => {
      clearInterval(interval);
    };

    paymentStore.checkPayment(orderId, cleanup, goBack);

    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loader minHeight="100vh" />;
};

export default ReplenishObrCardCheckScreen;
