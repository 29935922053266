import React from 'react';
import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import { useIsTablet } from '../../../hooks/useIsTablet';
import purchasesCat from '../../../assets/images/purchases-cat.svg';

interface IEmptyPurchasesProps {}

const EmptyPurchases: React.FC<IEmptyPurchasesProps> = () => {
  const isTablet = useIsTablet();
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item md={6} xl={5}>
        <Box display="flex" height="100%" alignItems="center">
          <Typography variant={isTablet ? 'h4' : 'h3'}>
            У вас пока нет расходов по карте. Как только будет совершена первая покупка, здесь появится список расходов.
          </Typography>
        </Box>
      </Grid>
      <Grid item md={6} xl={7}>
        <div className={classes.imgWrap}>
          <img src={purchasesCat} alt="" />
        </div>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  imgWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 696,
    height: 500,
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.down('lg')]: {
      maxWidth: 385,
      height: 282,
    },
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
}));

export default EmptyPurchases;
