import AbstractModel from '../../../base/AbstractModel';
import MenuItemModel from './MenuItemModel';

export default class MenuCategory extends AbstractModel {
  title: string | null = null;
  menu: MenuItemModel[] | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
