import { RoutesType } from '../base/routes/types/RouteTypes';
import MainScreen from './main/MainScreen';
import AttachChildren from './attach-children/AttachChildren';
import LibraryScreen from './library/LibraryScreen';
import NotFoundScreen from './not-found/NotFoundScreen';
// import DiaryScreen from './diary/DiaryScreen';
import PreviewComponentsScreen from './preview/PreviewComponentsScreen';
import ReplenishObrCardScreen from './replenish-obr-card/ReplenishObrCardScreen';
import ReplenishObrCardCheckScreen from './replenish-obr-card/ReplenishObrCardCheckScreen';
import ChildScreen from './child/ChildScreen';
import ChildAttendanceScreen from './attendance/ChildAttendanceScreen';
import ChildPurchasesScreen from './purchases/ChildPurchasesScreen';
import SettingsScreen from './settings/SettingsScreen';
import ChildSettingsScreen from './child-settings/ChildSettingsScreen';
import ReplenishTkCardScreen from './replenish-tk-card/ReplenishTkCardScreen';
import MenuSetupScreen from './menu-setup/MenuSetupScreen';
// import PreorderScreen from './preorder/PreorderScreen';
import CartScreen from './preorder/CartScreen';
import OperationsScreen from './operations/OperationsScreen';

const screens = {
  MainScreen,
  AttachChildren,
  PreviewComponentsScreen,
  NotFoundScreen,
  LibraryScreen,
  // DiaryScreen,
  // PreorderScreen,
  CartScreen,
  ChildScreen,
  ChildAttendanceScreen,
  ChildPurchasesScreen,
  ReplenishObrCardScreen,
  ReplenishObrCardCheckScreen,
  ReplenishTkCardScreen,
  ChildSettingsScreen,
  SettingsScreen,
  MenuSetupScreen,
  OperationsScreen,
};

type RoutesKeys = keyof typeof screens;
export const routes: RoutesType<RoutesKeys> = {
  MainScreen: {
    path: '/',
    exact: true,
    title: 'Мои дети',
    component: screens.MainScreen,
    // credentials: [roles.director],
  },
  AttachChildren: {
    path: '/attach-children',
    exact: true,
    title: 'Присоединить детей',
    component: AttachChildren,
  },
  ReplenishObrCardScreen: {
    path: '/replenish-obrcard',
    exact: true,
    title: 'Пополнить карту',
    component: ReplenishObrCardScreen,
  },
  ReplenishObrCardCheckScreen: {
    path: '/replenish-obrcard/check/:orderId',
    exact: true,
    title: 'Пополнить карту',
    component: ReplenishObrCardCheckScreen,
  },
  ReplenishTkCardScreen: {
    path: '/replenish-tkcard',
    title: 'Транспортная карта',
    component: ReplenishTkCardScreen,
  },
  PreviewComponentsScreen: {
    path: '/components',
    exact: true,
    title: 'Компоненты',
    component: screens.PreviewComponentsScreen,
  },
  SettingsScreen: {
    path: '/settings',
    exact: true,
    title: 'Настройки',
    component: screens.SettingsScreen,
  },
  OperationsScreen: {
    path: '/operations',
    exact: true,
    title: 'Операции',
    component: screens.OperationsScreen,
  },
  NotFoundScreen: {
    path: '/not-found',
    title: '404 Страница не найдена',
    component: screens.NotFoundScreen,
  },
  ChildScreen: {
    path: '/:contractId',
    exact: true,
    title: 'Страница ребенка',
    component: screens.ChildScreen,
  },
  ChildSettingsScreen: {
    path: '/:contractId/settings/:changePassword?',
    exact: true,
    title: 'Настройки',
    component: screens.ChildSettingsScreen,
  },
  ChildAttendanceScreen: {
    path: '/:contractId/attendance',
    exact: true,
    title: 'Посещаемость',
    component: screens.ChildAttendanceScreen,
  },
  ChildPurchasesScreen: {
    path: '/:contractId/purchases',
    exact: true,
    title: 'Покупки',
    component: screens.ChildPurchasesScreen,
  },
  LibraryScreen: {
    path: '/:contractId/library',
    exact: true,
    title: 'Библиотека',
    component: screens.LibraryScreen,
  },
  // DiaryScreen: {
  //   path: '/:contractId/diary',
  //   exact: true,
  //   title: 'Электронный дневник',
  //   component: screens.DiaryScreen,
  // },
  // PreorderScreen: {
  //   path: '/:contractId/preorder',
  //   exact: true,
  //   title: 'Предзаказ питания',
  //   component: screens.PreorderScreen,
  // },
  CartScreen: {
    path: '/:contractId/preorder/edit',
    exact: true,
    title: 'Предзаказ питания',
    component: screens.CartScreen,
  },
  MenuSetupScreen: {
    path: '/:contractId/menu-setup',
    exact: true,
    title: 'Настройка меню',
    component: screens.MenuSetupScreen,
  },
};
