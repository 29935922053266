import TokenLocalRepository from './TokenLocalRepository';
import { clearAccessCredentials, setAccessCredentials } from '../../base/ApiAdapter';
import ITokenData from './types/TokenData';

export default class TokenService {
  tokenLocal: TokenLocalRepository;

  constructor() {
    this.tokenLocal = new TokenLocalRepository();
  }

  saveToken = (token: string, deviceId: number) => {
    this.tokenLocal.set({ token, deviceId });
    setAccessCredentials(token, deviceId);
  };

  deleteToken = async (): Promise<void> => {
    clearAccessCredentials();
    return this.tokenLocal.removeAll();
  };

  checkSavedAuth = async (): Promise<boolean> => {
    const data = await this.getToken();
    if (data) {
      this.saveToken(data.token, data.deviceId);
    }

    return !!data;
  };

  getToken = async (): Promise<ITokenData | null> => {
    return this.tokenLocal.get<ITokenData>();
  };
}
