import AbstractApiRepository from '../../base/repositories/AbstractApiRepository';
import { IResponseItems } from '../../base/ApiAdapter';

export default class MenuSetupApiRepository extends AbstractApiRepository {
  getMenuSections = (contractId: number) =>
    this.get<IResponseItems<any>>({
      url: `/v2/menu-sections/${contractId}`,
    });

  getMenu = (contractId: number, menu: string) =>
    this.get<IResponseItems<any>>({
      url: `/v2/menu/${menu}/${contractId}`,
    });

  enableMenuItem = (contractId: number, blockId: number) =>
    this.post<any>({
      url: `/v2/menu/menu-item/enable`,
      data: {
        contractId,
        blockId,
      },
    });

  disableMenuItem = (contractId: number, name: string) =>
    this.post<any>({
      url: `/v2/menu/menu-item/disable`,
      data: {
        contractId,
        text: name,
      },
    });
}
