import AbstractModel from '../../../base/AbstractModel';

export class AuthPhone extends AbstractModel {
  id: string | null = null;
  code: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
