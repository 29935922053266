import AbstractFactory from '../../base/AbstractFactory';
import FoodComponent from './models/FoodComponent';
import Food from './models/Food';
import PreorderAndWorkingDays from './models/PreorderAndWorkingDays';
import OrderInDay from './models/OrderInDay';
import { parseApiDate } from '../../helpers/dateHelpers';

export default class PreorderFactory extends AbstractFactory {
  createDishes = (data: any[]) => data.map(json => this.createDish(json));

  createDish = (json: any) => {
    const dish = new Food(json);

    dish.items = json.items.map((item: any) => new FoodComponent(item));

    return dish;
  };

  createSumPreorderAndWorkingDays = (data: any): PreorderAndWorkingDays => {
    const preorderAndWorkingDays = new PreorderAndWorkingDays(data);

    const calendar: Record<string, OrderInDay> = {};

    (data.calendar || []).forEach((item: any) => {
      const orderInDay = new OrderInDay(item);

      orderInDay.date = parseApiDate(item.date);

      calendar[item.date] = orderInDay;
    });

    preorderAndWorkingDays.calendar = calendar;

    return preorderAndWorkingDays;
  };
}
