import ruLocale from 'date-fns/locale/ru';
import format from 'date-fns/format';
import { intlFormat, isAfter, isBefore, isSameWeek, parse } from 'date-fns';

import { firstLetterUpperCase } from './stringHelper';

const formatRu = (date: Date, formatStr: string): string => {
  return format(date, formatStr, { locale: ruLocale });
};

const DISPLAY_DATE_FORMAT = 'dd.MM.yyyy';
const getDateFormat = (date: Date | null) => {
  if (date) {
    return format(date, DISPLAY_DATE_FORMAT);
  }

  return '';
};

const MONTH_FORMAT = 'MMMM yyyy';
const getMonth = (date: Date) => {
  const month = formatRu(date, MONTH_FORMAT);
  return firstLetterUpperCase(month);
};

const getMonthSubjCase = (date: Date) => {
  const month = intlFormat(
    date,
    {
      month: 'long',
      year: 'numeric',
    },
    {
      locale: 'ru',
    },
  );
  return firstLetterUpperCase(month);
};

const DAY_FORMAT = 'dd MMMM';
const getDay = (date: Date) => {
  return formatRu(date, DAY_FORMAT);
};

const DATE_API_FORMAT = 'yyyy-MM-dd';
const toApiDateStr = (date: Date) => format(date, DATE_API_FORMAT);

const DATE_API_V1_FORMAT = 'dd.MM.yyyy';
const toApiV1DateStr = (date: Date) => format(date, DATE_API_V1_FORMAT);

const parseApiDate = (dateStr: string) => parse(dateStr, DATE_API_FORMAT, new Date());

const isValidDate = (date: Date | null) => {
  return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date.getTime());
};

const isSameWeekRu = (leftDate: Date, rightDate: Date) => {
  return isSameWeek(leftDate, rightDate, {
    weekStartsOn: 1,
  });
};

export const MIN_BIRTHDAY_DATE = new Date(1900, 0, 1);

export const isBetween = (min: Date, compareDate: Date, max: Date) => {
  return isAfter(compareDate, min) && isBefore(compareDate, max);
};

export const getStartDateAndEndDate = (): { [k: string]: Date } => {
  const today = new Date();

  const year = today.getFullYear();
  const month = today.getMonth();
  const day = today.getDate();
  const hours = today.getHours();
  const minutes = today.getMinutes();
  const seconds = today.getSeconds();
  const milliseconds = today.getMilliseconds();

  const yesterday = new Date(year, month, day - 1, hours, minutes, seconds, milliseconds);
  const weekAgo = new Date(year, month, day - 7, hours, minutes, seconds, milliseconds);
  const threeMonthsAgo = new Date(year, month - 3, day, hours, minutes, seconds, milliseconds);

  return {
    yesterday,
    today,
    weekAgo,
    threeMonthsAgo,
  };
};

export const getDateWithoutTime = (date?: Date) => {
  return date?.toISOString().split('T')[0];
};

export {
  formatRu,
  getDateFormat,
  getMonth,
  getDay,
  toApiDateStr,
  toApiV1DateStr,
  parseApiDate,
  isValidDate,
  isSameWeekRu,
  getMonthSubjCase,
};
