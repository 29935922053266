import { getMobileOS } from './getMobileOS';
import { appConfig } from '../../../appConfig';

export const getStoreLink = (): string => {
  switch (getMobileOS()) {
    case 'iOS': {
      return appConfig.storesLinks.appStore;
    }
    case 'Huawei': {
      return appConfig.storesLinks.huaweiStore;
    }
    default:
      return appConfig.storesLinks.playStore;
  }
};
