import AbstractFactory from '../../base/AbstractFactory';
import { Child } from '../children/models/Child';
import Operation from './OperationModel';

export class OperationsFactory extends AbstractFactory {
  createOperations = (Model: any, data: any, children: Child[]) =>
    data.histories?.map((json: Operation) => {
      const model = new Model(json);

      model.avatarUrl = children.find(child => child.contractId === +model.contractId)?.avatarUrl;
      model.date = json.timestamp && new Date(json.timestamp * 1000);

      return model;
    });
}
