import AbstractApiRepository from '../../base/repositories/AbstractApiRepository';

export default class PreorderApiRepository extends AbstractApiRepository {
  getAvailableFood = (contractId: number, date: string) =>
    this.get<any>({
      url: `/v2/preorder/${contractId}/complexes?date=${date}`,
    });

  getSumPreorderAndWorkingDays = (contractId: number, phone: string) =>
    this.get<any>({
      url: `/v2/preorder/${contractId}?phone=${phone}`,
    });
}
