import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import { useIsTablet } from '../../hooks/useIsTablet';
import ChildrenSelect from '../../components/UI/ChildrenSelect';
import WarningCard from './components/WarningCard';
import TkCardSvg from '../../assets/images/tkcard-image.svg';
import AddTkCardModal from './components/AddTkCardModal';
import { Child } from '../../modules/children/models/Child';
import { useRootStore } from '../../base/hooks/useRootStore';
import Loader from '../../components/UI/Loader';
import TCardBalance from './components/TCardBalance';
import ReplenishmentInstructions from './components/ReplenishmentInstructions';

interface ILocationState {
  state: {
    contractId: number;
  };
}

const ReplenishTkCardScreen: React.FC = observer(() => {
  const classes = useStyles();
  const isTablet = useIsTablet();
  const { state } = useLocation<ILocationState>();
  const { childrenStore, tCardStore } = useRootStore();
  const { children, loading } = childrenStore;
  const selectedContractId = state?.state?.contractId;
  const [childId, setChildId] = useState<number | string>('');
  const [child, setChild] = useState<Child | undefined>();
  const [openModal, setOpenModal] = useState(false);

  const tCard = child?.contractId ? tCardStore.getTCard(child.contractId) : null;

  const [ticketId, setTicketId] = useState<number | string>('');

  useEffect(() => {
    if (!ticketId) {
      setTicketId(tCard?.tickets?.[0]?.pdcode || '');
    }
  }, [tCard]);

  useEffect(() => {
    if (selectedContractId) {
      setChildId(selectedContractId);
    } else if (children.length) {
      setChildId(children[0].contractId || '');
    }
  }, [selectedContractId, children]);

  useEffect(() => {
    if (childId) {
      const child = childrenStore.getChildByContractId(childId as number);

      if (child) {
        setChild(child);
        child?.contractId && tCardStore.getChildTCard(child.contractId);
      }
    }
  }, [childrenStore, children, childId]);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleClick = () => {
    tCardStore.handleClickReplenish();
  };

  if (loading) {
    return <Loader minHeight="50vh" />;
  }

  return (
    <Container maxWidth="xl">
      <Box mb={3}>
        <Typography variant={isTablet ? 'h3' : 'h1'}>Транспортная карта</Typography>
      </Box>

      <Box mb="100px">
        <Grid container>
          <Grid item md={6} xl={5}>
            <Box mr={{ md: 2, xl: 4 }}>
              <Grid container>
                <Grid item md={12} xl={9}>
                  <Box mb={4}>
                    <ChildrenSelect contractId={childId} onChange={setChildId} numberType="tkcard" />
                  </Box>

                  {tCard && <TCardBalance contractId={child?.contractId} amount={tCard?.amount} />}
                </Grid>

                {tCard?.canPay && (
                  <Grid item md={12} xl={9}>
                    <Button
                      variant="contained"
                      color="primary"
                      size={isTablet ? 'medium' : 'large'}
                      onClick={handleClick}
                      disabled={!child || tCardStore.loading}
                    >
                      Пополнить ТК
                    </Button>
                  </Grid>
                )}
              </Grid>

              {tCard && !tCard?.canPay && (
                <WarningCard>
                  <Typography>
                    У вас создано отложенное пополнение. Баланс обновится после активации пополнения.
                  </Typography>
                </WarningCard>
              )}

              {!tCard &&
                (tCardStore.loading ? (
                  <Loader />
                ) : (
                  <>
                    <Box mb="40px">
                      <Typography variant="h3">
                        Мы не нашли транспортную карту ребёнка, пожалуйста добавьте ее.
                      </Typography>
                    </Box>
                    <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
                      Добавить ТК
                    </Button>
                  </>
                ))}
            </Box>
          </Grid>

          <Grid item md={6} xl={7}>
            <img src={TkCardSvg} alt="Транспортная карта" className={classes.illustration} />
          </Grid>
        </Grid>
      </Box>

      {tCard && !tCard?.canPay && <ReplenishmentInstructions />}

      <AddTkCardModal open={openModal} onClose={handleClose} child={child} />
    </Container>
  );
});

const useStyles = makeStyles(() => ({
  illustration: {
    width: '100%',
    height: '100%',
  },
  inputEndAdornment: {
    fontSize: 18,
  },
}));

export default ReplenishTkCardScreen;
