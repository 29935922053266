export default class ProgressTimer {
  private startTime: number | null = null;
  private progressTime: number | null = null; // current - startTime
  private timeout: number | null = null; // delay

  start(timeMs: number): void {
    this.startTime = ProgressTimer.getCurrentTime();
    this.timeout = timeMs;
  }

  pause(): void {
    if (this.startTime) {
      this.progressTime = ProgressTimer.getCurrentTime() - this.startTime;
    }
  }

  getRestTime(): number | undefined {
    if (this.timeout && this.progressTime) {
      return this.timeout - this.progressTime;
    }
  }

  private static getCurrentTime() {
    return new Date().getTime();
  }
}
