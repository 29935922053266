import React, { ChangeEvent, useCallback } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';

const QFioInput: React.FC<TextFieldProps> = props => {
  const { onChange, ...rest } = props;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      event.target.value = value.replaceAll(/\d/g, '');

      if (onChange) {
        onChange(event);
      }
    },
    [onChange],
  );

  return <TextField onChange={handleChange} {...rest} />;
};

export default QFioInput;
