import { FunctionComponent } from 'react';

import {
  AddFoodIcon,
  BreadIcon,
  CandyIcon,
  Cookies2Icon,
  // CookiesIcon,
  DrinkIcon,
  FlourProductsIcon,
  MeetIcon,
  MilkIcon,
  SalatsIcon,
  SnacksIcon,
  SoupIcon,
  // TeaBreadIcon,
} from '../../../components/UI/Icons/SvgIcons';

const icons: Record<string, FunctionComponent> = {
  'Вторые блюда (учителям)': MeetIcon,
  'Молочная продукция': MilkIcon,
  'Кондитерские изделия': Cookies2Icon,
  'Соки пакетированные,мин.вода': DrinkIcon,
  Напитки: DrinkIcon,
  'Салаты (учителям)': SalatsIcon,
  'Салаты (учителям) Солнце': SalatsIcon,
  'Мучные изделия': FlourProductsIcon,
  'Конфеты,шоколад': CandyIcon,
  'Дополнительные кнопки': AddFoodIcon,
  'Холодные закуски': SnacksIcon,
  'Гарниры (учителям)': SoupIcon,
  'Супы (учителям)': SoupIcon,
  'Хлеб (учителям)': BreadIcon,
};

export const FOOD_ICONS: Record<string, FunctionComponent> = {};
Object.entries(icons).forEach(([key, value]) => {
  FOOD_ICONS[toFoodIconKey(key)] = value;
});

export function toFoodIconKey(key: string) {
  return key.replaceAll(' ', '');
}
