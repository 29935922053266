import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';

import QIcon from '../../components/UI/Icons/QIcon';
import ReplenishObrcard from '../../assets/images/replenish-obrcard.svg';
import { useIsTablet } from '../../hooks/useIsTablet';
import { MasterCardIcon, MirIcon, VisaIcon } from '../../components/UI/Icons/SvgIcons';
import { useRootStore } from '../../base/hooks/useRootStore';
import Loader from '../../components/UI/Loader';
import ChildrenSelect from '../../components/UI/ChildrenSelect';
import { RUB_SIGN } from '../../helpers/currencyFormat';

interface ILocationState {
  state: {
    contractId: number;
  };
}

const ReplenishObrCardScreen: React.FC = observer(() => {
  const classes = useStyles();
  const { paymentStore, childrenStore } = useRootStore();
  const { children, loading } = childrenStore;
  const { state } = useLocation<ILocationState>();
  const isTablet = useIsTablet();
  const selectedContractId = state?.state?.contractId;
  const history = useHistory();

  const [childId, setChildId] = useState<number | string>(state?.state.contractId || '');
  const [amount, setAmount] = useState<string | undefined>('500');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (selectedContractId) {
      setChildId(selectedContractId);
    } else if (children.length) {
      setChildId(children[0].contractId || '');
    }
  }, [selectedContractId, children]);

  useEffect(() => {
    setIsDisabled(!amount || isNaN(Number(amount)) || !childId);
  }, [amount, childId]);

  const handleClick = async () => {
    const link = await paymentStore.getPaymentLink(childId as number, Math.round(Number(amount) * 100));

    if (link.url) {
      window.open(link.url, '_blank')?.focus();
      history.push(`/replenish-obrcard/check/${link.orderId}`);
    }
  };

  if (loading) {
    return <Loader minHeight="100vh" />;
  }

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item md={6} xl={5}>
          <Box mb={3}>
            <Typography variant={isTablet ? 'h3' : 'h1'}>Пополнить Обркарту</Typography>
          </Box>
          <Typography variant={isTablet ? 'h4' : 'h3'}>
            Пополните баланс онлайн без комиссии с помощью банковской карты
          </Typography>
          <Grid container spacing={3} className={classes.form}>
            <Grid item md={12} xl={9}>
              <ChildrenSelect contractId={childId} onChange={setChildId} numberType="obrcard" />
            </Grid>
            <Grid item md={12} xl={9}>
              <TextField
                variant="outlined"
                placeholder="Сумма к зачислению"
                fullWidth
                value={amount}
                onChange={e => setAmount(e.target.value)}
                InputProps={{ endAdornment: <span className={classes.inputEndAdornment}>{RUB_SIGN}</span> }}
              />
            </Grid>
            <Grid item md={12} xl={9}>
              <Button
                variant="contained"
                color="primary"
                size={isTablet ? 'medium' : 'large'}
                onClick={handleClick}
                disabled={isDisabled || paymentStore.loading}
              >
                Пополнить Обркарту
              </Button>
            </Grid>
            <Grid item>
              <Typography className={classes.info}>
                Оплата происходит через авторизационный сервер Процессингового центра Банка с использованием Банковских
                кредитных карт следующих платежных систем:
              </Typography>
              <Grid container spacing={5} className={classes.payCards}>
                <Grid item>
                  <QIcon component={MasterCardIcon} size={{ width: 39, height: 24 }} />
                </Grid>
                <Grid item>
                  <QIcon component={VisaIcon} size={{ width: 76, height: 24 }} />
                </Grid>
                <Grid item>
                  <QIcon component={MirIcon} size={{ width: 85, height: 24 }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xl={7}>
          <Box display="flex" justifyContent="center" mt={{ md: '140px', xl: '80px' }}>
            <img
              src={ReplenishObrcard}
              alt="Пополнить обркарту"
              style={isTablet ? { width: 307, height: 265 } : { width: 510, height: 440 }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    marginTop: theme.spacing(4),
  },
  info: {
    paddingTop: 24,
    color: theme.palette.neonGray['500'],
  },
  payCards: {
    marginTop: -4,
  },
  inputEndAdornment: {
    fontSize: 18,
  },
}));

export default ReplenishObrCardScreen;
