import { makeAutoObservable } from 'mobx';

import NotificationUtil from '../../utils/NotificationUtil';
import PaymentService from './PaymentService';
import { CheckStatus } from './types/PaymentTypes';

export default class PaymentStore {
  loading: boolean = false;

  private paymentService: PaymentService;

  constructor() {
    makeAutoObservable(this);
    this.paymentService = new PaymentService();
  }

  getPaymentLink = async (contractId: number, amount: number) => {
    this.setLoading(true);
    try {
      return await this.paymentService.getPaymentLink(contractId, amount);
    } finally {
      this.setLoading(false);
    }
  };

  checkPayment = (orderId: string, clearRequestTimer: () => void, goBack: () => void) => {
    this.paymentService.checkPayment(orderId).then(({ text, value }) => {
      if (value === CheckStatus.EXPIRED) {
        clearRequestTimer();
        goBack();
        text && NotificationUtil.showError(text);
      }

      if (value === CheckStatus.SUCCESS) {
        clearRequestTimer();
        setTimeout(() => {
          goBack();
          text && NotificationUtil.showSuccess(text);
        }, 3000);
      } else {
        text && NotificationUtil.showInfo(text);
      }
    });
  };

  setLoading = (isLoading: boolean) => {
    this.loading = isLoading;
  };
}
