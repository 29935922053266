import React, { ElementType } from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

import QIcon from './QIcon';

interface ISquareIconProps extends SvgIconProps {
  className?: string;
  component: ElementType;
  size: number;
  viewBox?: string;
  fillNone?: boolean;
}

const SquareIcon: React.FC<ISquareIconProps> = props => {
  const { size, ...rest } = props;
  return <QIcon size={{ height: size, width: size }} {...rest} />;
};

export default SquareIcon;
