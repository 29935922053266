import React, { useEffect } from 'react';
import { Box, Button, Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useIsTablet } from 'hooks/useIsTablet';
import { isEmailValid } from 'helpers/validationHelper';
import { useRootStore } from 'base/hooks/useRootStore';
import { hidePhone } from 'helpers/hideTextHelper';

interface ISettingsContactDataProps {}

const SettingsContactData: React.FC<ISettingsContactDataProps> = observer(() => {
  const classes = useStyles();
  const isTablet = useIsTablet();
  const { profileStore } = useRootStore();
  const { profile, loadingUpdateEmail } = profileStore;

  useEffect(() => {
    profile?.email &&
      setValues({
        email: profile?.email,
      });
  }, [profile]);

  const { values, errors, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: {
      email: profile?.email || '',
    },
    validateOnChange: true,
    validate(values) {
      const errors: any = {};

      if (!values.email || !isEmailValid(values.email)) {
        errors.email = 'Введен некорректный формат электронной почты';
      }

      return errors;
    },
    onSubmit(values) {
      profileStore.updateEmail({
        name: profile?.name || '',
        middlename: profile?.middlename || '',
        lastname: profile?.lastname || '',
        gender: profile?.gender || '',
        birthday: profile?.birthday || null,
        birthdayValid: true,
        email: values.email,
      });
    },
  });

  return (
    <Box mb="40px">
      <Box mb={isTablet ? 2 : 3}>
        <Typography variant={isTablet ? 'h4' : 'h3'}>Контактные данные</Typography>
      </Box>

      <Box mb={3}>
        <Grid container spacing={4}>
          <Grid item>
            <Box mt={{ md: 0, xl: 1 }}>
              <Typography variant={isTablet ? 'caption' : 'body2'} className={classes.label}>
                Номер телефона
              </Typography>
              <Typography variant={isTablet ? 'subtitle2' : 'subtitle1'}>{hidePhone(profile?.phone || '')}</Typography>
            </Box>
          </Grid>

          <Grid item md={6} xl={4}>
            <TextField
              variant="outlined"
              size={isTablet ? 'small' : undefined}
              label="Электронная почта"
              name="email"
              onChange={handleChange}
              value={values.email}
              error={!!errors.email}
              helperText={errors.email}
              inputProps={{ maxLength: 50 }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>

      <Button
        variant="contained"
        color="primary"
        size={isTablet ? 'medium' : 'large'}
        onClick={() => handleSubmit()}
        disabled={loadingUpdateEmail}
      >
        Сохранить изменения
      </Button>
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  label: {
    color: theme.palette.neonGray['600'],
  },
}));

export default SettingsContactData;
