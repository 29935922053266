import AbstractFactory from '../../../base/AbstractFactory';
import { parseApiDate } from '../../../helpers/dateHelpers';

export class ChildrenFactory extends AbstractFactory {
  createChildList<T>(Model: any, data: any[]): T[] {
    return data.map(json => this.createChild(Model, json));
  }

  createChild<T>(Model: any, data: any): T {
    const model = new Model(data);

    model.dateOfContract = parseApiDate(data.dateOfContract);

    if (data.cards?.[0]?.number.toString().length < 10) {
      model.cards[0].number = `0${data.cards[0].number}`;
    }

    return model;
  }
}
