import React, { ChangeEvent } from 'react';
import { Box, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useIsTablet } from '../hooks/useIsTablet';
import QDialog from './UI/QDialog';
import { useRootStore } from '../base/hooks/useRootStore';
import Loader from './UI/Loader';

interface IUploadPhotoModalProps {
  contractId: number;
  open: boolean;
  onClose: () => void;
}

const UploadPhotoModal: React.FC<IUploadPhotoModalProps> = observer(props => {
  const { contractId, open, onClose } = props;
  const classes = useStyles();
  const isTablet = useIsTablet();
  const { childrenStore } = useRootStore();
  const fileInput = React.useRef<HTMLInputElement>(null);

  const { loading } = childrenStore;

  const handleClickUpload = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      childrenStore.changeAvatar(contractId, file).then(onClose);
    }
  };

  const handleRemove = () => {
    childrenStore.removeAvatar(contractId).then(onClose);
  };

  return (
    <QDialog open={open} onBackdropClick={onClose} onClose={onClose}>
      {loading && <Loader isAbsolute />}
      <Box mb={{ md: 2, xl: 4 }}>
        <Typography variant={isTablet ? 'h4' : 'h3'}>Изменить фотографию</Typography>
      </Box>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <input
            type="file"
            accept=".jpg, .jpeg"
            ref={fileInput}
            className={classes.hiddenFileInput}
            onChange={handleUpload}
          />
          <Button
            variant="contained"
            color="primary"
            size={isTablet ? 'small' : 'medium'}
            fullWidth
            onClick={handleClickUpload}
          >
            Загрузить новую фотографию
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" size={isTablet ? 'small' : 'medium'} fullWidth onClick={handleRemove}>
            Удалить фотографию
          </Button>
        </Grid>
      </Grid>
    </QDialog>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  hiddenFileInput: {
    display: 'none',
  },
}));

export default UploadPhotoModal;
