import React from 'react';
import { Avatar, Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';

import SquareIcon from './UI/Icons/SquareIcon';
import { CanceledIcon, CardIcon, DinnerIcon } from './UI/Icons/SvgIcons';
import { useIsTablet } from '../hooks/useIsTablet';
import QAvatar from './UI/QAvatar';
import currencyFormat from '../helpers/currencyFormat';
import { hideTextHelper } from '../helpers/hideTextHelper';
import Operation from '../modules/operations/OperationModel';

interface IOperationProps {
  operation: Operation;
}

const OperationItem: React.FC<IOperationProps> = observer(({ operation }) => {
  const classes = useStyles();
  const isTablet = useIsTablet();

  const { date, text, canceled, sum, avatarUrl, contractId, type } = operation;

  return (
    <Grid container className={classes.item}>
      <Grid item md={1} className={classes.column}>
        <Typography variant="body2">{format(date || new Date(), 'HH:mm')}</Typography>
      </Grid>
      <Grid item md={4} className={classes.flexRowCenter}>
        <Box mr={{ md: 2, xl: 3 }}>
          <Avatar>{type === 'payment' ? <CardIcon /> : <DinnerIcon />}</Avatar>
        </Box>
        <div>
          <Typography className={clsx(operation.canceled && classes.canceled)}>{text}</Typography>
          <Typography variant="body2" className={classes.secondText}>
            Обркарта
          </Typography>
        </div>
      </Grid>
      <Grid item md={3} className={classes.flexRowCenter}>
        {!isTablet && (
          <Box mr="7px">
            <QAvatar avatarUrl={avatarUrl} size={40} />
          </Box>
        )}
        <div>
          <Typography variant="body2">{operation.firstLastName}</Typography>
          <Typography variant="body2" className={classes.secondText}>
            {hideTextHelper(String(contractId || 0), 4, 2)}
          </Typography>
        </div>
      </Grid>
      <Grid item md={3} className={classes.column}>
        {canceled && (
          <div className={clsx(classes.flexRowCenter, classes.cancel)}>
            <Box mr={1}>
              <SquareIcon component={CanceledIcon} size={16} />
            </Box>
            <Typography variant="body2" className={classes.secondText}>
              Операция отменена
            </Typography>
          </div>
        )}
      </Grid>
      <Grid item md={1} className={classes.column}>
        <Typography className={clsx(canceled && classes.canceled)} align="right">
          {sum && sum > 0 ? `+${currencyFormat(sum)}` : currencyFormat(sum)}
        </Typography>
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  item: {
    height: 72,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  flexRowCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  cancel: {
    alignSelf: 'flex-end',
  },
  canceled: {
    textDecoration: 'line-through',
  },
  secondText: {
    color: theme.palette.neonGray['500'],
  },
}));

export default OperationItem;
