import React, { useState } from 'react';
import { Box, IconButton, makeStyles, TextField, Theme, Tooltip, Typography } from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

import { VisibilityIcon } from '../Icons/SvgIcons';
import { useIsTablet } from '../../../hooks/useIsTablet';

const PasswordTooltip: React.VFC = () => (
  <Box p={2}>
    <Typography variant="caption" color="textPrimary">
      По умолчанию пароль совпадает с номером лицевого счета. Настоятельно рекомендуем сменить.
    </Typography>
  </Box>
);

interface IQPasswordProps {
  placeholder?: string;
  label?: string;
  name: string;
  error?: string;
  password: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  showTooltip?: boolean;
}

const QPassword: React.FC<IQPasswordProps> = props => {
  const { placeholder, label, name, password, error, onChange, showTooltip } = props;
  const classes = useStyles();
  const isTablet = useIsTablet();

  const [visibility, setVisibility] = useState(false);

  const toggleVisibility = (): void => {
    setVisibility(prevState => !prevState);
  };

  return (
    <TextField
      variant="outlined"
      type={visibility ? 'text' : 'password'}
      placeholder={placeholder}
      label={label}
      name={name}
      size={isTablet ? 'small' : 'medium'}
      onChange={onChange}
      value={password}
      error={!!error}
      helperText={error}
      fullWidth
      InputProps={{
        endAdornment: (
          <>
            <IconButton onClick={toggleVisibility}>
              <VisibilityIcon />
            </IconButton>
            {showTooltip && (
              <Tooltip
                placement="right-start"
                classes={{ tooltip: classes.tooltip, tooltipPlacementRight: classes.tooltipPlacement }}
                title={<PasswordTooltip />}
              >
                <HelpOutlineOutlinedIcon className={classes.icon} />
              </Tooltip>
            )}
          </>
        ),
      }}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  icon: {
    color: theme.palette.neonGray[600],
  },
  tooltip: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
  },
  tooltipPlacement: {
    marginLeft: 65,
    position: 'relative',
    width: 228,

    '&:before': {
      position: 'absolute',
      width: 8,
      height: 8,
      backgroundColor: '#ffffff',
      content: `''`,
      left: -4,
      transform: 'rotate(45deg)',
      top: 8,
    },
  },
}));

export default QPassword;
