import AbstractCommonApiRepository from '../../base/repositories/AbstractCommonApiRepository';
import { IResponseV2 } from '../../base/ApiAdapter';

export default class NewsApiRepository extends AbstractCommonApiRepository {
  getNews = () => {
    return this.get<IResponseV2<any>>({
      url: 'v2/news',
    });
  };
}
