const mapWithPrev = <T, U>(arr: T[], fn: (prev: T | null, i: T, index: number) => U): U[] => {
  const result: U[] = [];
  for (let i = 0; i < arr.length; i++) {
    if (i === 0) {
      result.push(fn(null, arr[i], i));
    } else {
      result.push(fn(arr[i - 1], arr[i], i));
    }
  }
  return result;
};

export default mapWithPrev;
