import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import QDialog from 'components/UI/QDialog';
import { useIsTablet } from 'hooks/useIsTablet';
import LoaderButton from 'components/UI/LoaderButton';
import { useRootStore } from 'base/hooks/useRootStore';

interface IRemoveAccountModalProps {
  open: boolean;
  onClose: () => void;
}

const RemoveAccountModal: React.FC<IRemoveAccountModalProps> = props => {
  const { open, onClose } = props;
  const isTablet = useIsTablet();
  const { profileStore } = useRootStore();
  const { loading } = profileStore;

  const handleRemoveAccount = () => {
    profileStore.removeProfile().then(() => {
      window.location.assign('/');
    });
  };

  return (
    <QDialog open={open} hideCloseBtn={true}>
      <Box mb={{ md: 2, xl: 4 }}>
        <Typography variant={isTablet ? 'h4' : 'h3'}>Удалить аккаунт</Typography>
      </Box>

      <Box mb={{ md: 2, xl: 4 }}>
        <Typography variant={isTablet ? 'body2' : 'body1'}>Вы уверены, что хотите удалить аккаунт?</Typography>
      </Box>

      <Grid container spacing={2} direction={isTablet ? 'column-reverse' : 'row'}>
        <Grid item md={12} xl={6}>
          <LoaderButton
            variant="outlined"
            fullWidth
            size={isTablet ? 'small' : 'medium'}
            loading={loading}
            onClick={handleRemoveAccount}
          >
            Удалить аккаунт
          </LoaderButton>
        </Grid>
        <Grid item md={12} xl={6}>
          <Button variant="contained" color="primary" fullWidth size={isTablet ? 'small' : 'medium'} onClick={onClose}>
            Не удалять
          </Button>
        </Grid>
      </Grid>
    </QDialog>
  );
};

export default RemoveAccountModal;
