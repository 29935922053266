import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import clsx from 'clsx';

import { CancelIcon, PurchaseIcon } from '../../../../components/UI/Icons/SvgIcons';
import { addCentsSeparator } from '../../../../utils/AddCentsSeparator';
import Purchase from '../../../../modules/purchases/models/Purchase';

interface Props {
  purchase: Purchase;
}

const ChildPurchaseLine: React.VFC<Props> = ({ purchase }) => {
  const classes = useStyles();
  const { canceled, text, sum, timestamp } = purchase;

  return (
    <Box className={classes.purchase}>
      <Box className={classes.item} flexGrow={1}>
        <Typography>{format(timestamp! * 1000, 'HH:mm')}</Typography>
        <PurchaseIcon />
        <Typography className={clsx(canceled && classes.canceled)}>{text}</Typography>
      </Box>
      <Box className={classes.item}>
        {canceled && (
          <>
            <CancelIcon />
            <Typography variant="body2" color="textSecondary" component="span">
              Операция отменена
            </Typography>
          </>
        )}
      </Box>
      <Box minWidth={70} maxWidth={70} textAlign="right">
        <Typography className={clsx(canceled && classes.canceled)}>{sum && addCentsSeparator(sum)} ₽</Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  purchase: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 7, 2, 4),
    borderBottom: `1px solid ${theme.palette.neonGray[150]}`,
  },
  item: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  canceled: {
    textDecoration: 'line-through',
  },
}));

export default ChildPurchaseLine;
