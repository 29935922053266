import AbstractModel from '../../../base/AbstractModel';
import OrderInDay from './OrderInDay';

export default class PreorderAndWorkingDays extends AbstractModel {
  preorderSum14Days: number | null = null;
  calendar: Record<string, OrderInDay> | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
