import { api, IConfig, IResponseV2 } from '../ApiAdapter';

export default abstract class AbstractApiRepository {
  get = <T extends {}>(config: IConfig) => {
    return api.get<IResponseV2<T>>(config.url);
  };

  post = <T extends {}>(config: IConfig) => {
    return api.post<IResponseV2<T>>(config.url, config.data, config.config);
  };

  put = <T extends {}>(config: IConfig) => {
    return api.put<IResponseV2<T>>(config.url, config.data, config.config);
  };

  delete = <T extends {}>(config: IConfig) => {
    return api.delete<IResponseV2<T>>(config.url, config.config);
  };
}
