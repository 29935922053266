import { parse } from 'date-fns';

import AbstractFactory from '../../base/AbstractFactory';
import { formatRu } from '../../helpers/dateHelpers';

export default class NewsFactory extends AbstractFactory {
  createNews = <T>(Model: any, data: any[]): T[] =>
    data.map(json => {
      const date = parse(json.created, 'yyyy-MM-dd HH:mm:ss', new Date());
      const formattedDate = formatRu(date, 'dd MMMM yyyy г.');

      return new Model({
        ...json,
        date: formattedDate,
      });
    });
}
