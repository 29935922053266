import { appConfig } from '../../appConfig';
import CallToInstallLocalRepository from './CallToInstallLocalRepository';

export default class CallToInstallService {
  private callToInstallLocalRepository: CallToInstallLocalRepository;

  constructor() {
    this.callToInstallLocalRepository = new CallToInstallLocalRepository();
  }

  handleFormShow = async (): Promise<void> => {
    const isFormVisited = await this.callToInstallLocalRepository.get();

    if (!isFormVisited) {
      window.location.assign(appConfig.formLink);

      this.callToInstallLocalRepository.set('true');
    }
  };
}
