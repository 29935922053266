import PurchasesApiRepository from './PurchasesApiRepository';
import PurchasesFactory from './PurchasesFactory';
import Purchase from './models/Purchase';
import ChildrenService from '../children/services/ChildrenService';
import { Child } from '../children/models/Child';

export default class PurchasesService {
  private api: PurchasesApiRepository;
  private factory: PurchasesFactory;
  private childrenService: ChildrenService;

  constructor() {
    this.api = new PurchasesApiRepository();
    this.factory = new PurchasesFactory();
    this.childrenService = new ChildrenService();
  }

  getPurchases = async (filterByContractId: number | undefined, children: Child[]): Promise<Purchase[]> => {
    const purchases = await this.api.getPurchases(filterByContractId);

    return this.factory.createPurchases(Purchase, purchases.data.data.items, children);
  };

  getPurchasesById = async (contractId: number | undefined): Promise<Purchase[]> => {
    const { data } = await this.api.getPurchases(contractId);

    return this.factory.createPurchasesWithoutChild(Purchase, data.data.items);
  };
}
