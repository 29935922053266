import React from 'react';
import { Dialog, makeStyles, DialogProps, Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { BoxProps } from '@material-ui/core/Box/Box';

interface IQDialog extends DialogProps {
  onClose?: () => void;
  hideCloseBtn?: boolean;
  contentBoxProps?: BoxProps;
}

const QDialog: React.FC<IQDialog> = props => {
  const { children, onClose, hideCloseBtn, contentBoxProps, ...rest } = props;
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      scroll="body"
      {...rest}
    >
      <Box width={{ md: 382, xl: 488 }} p={3} {...contentBoxProps}>
        {!hideCloseBtn && (
          <Box ml="auto" my={-2} mr={-2} width="fit-content">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        {children}
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    border: `1px solid ${theme.palette.neonGray[100]}`,
    borderRadius: 12,
    minWidth: 382,
  },
}));

export default QDialog;
