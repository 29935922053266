import AbstractModel from '../../../base/AbstractModel';
import StoryImage from './StoryImage';

export default class Story extends AbstractModel {
  id: number | null = null;
  title: string | null = null;
  preview: string | null = null;
  isNew: boolean | null = null;
  images: StoryImage[] | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
