import React, { useState } from 'react';
import { Box, Button, Divider, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useIsTablet } from 'hooks/useIsTablet';
import { useRootStore } from 'base/hooks/useRootStore';
import QAvatar from 'components/UI/QAvatar';
import SquareIcon from 'components/UI/Icons/SquareIcon';
import { ChildSettingsIcon, UnbindIcon } from 'components/UI/Icons/SvgIcons';
import AddChildBtn from 'components/AddChildBtn';
import AddChildModal from 'components/add-child-modals/AddChildModal';
import UnbindChildModal from 'components/UnbindChildModal';
import Loader from 'components/UI/Loader';

interface ISettingsChildDataProps {}

const SettingsChildData: React.FC<ISettingsChildDataProps> = observer(() => {
  const classes = useStyles();
  const isTablet = useIsTablet();
  const history = useHistory();
  const { childrenStore } = useRootStore();
  const { children } = childrenStore;

  const [openAddChild, setOpenAddChild] = useState<boolean>(false);
  const [unbindChildContractId, setUnbindChildContractId] = useState<number | null>(null);

  // Handlers
  const handleOpenAddChildModal = () => {
    setOpenAddChild(true);
  };

  const handleCloseAddChildModal = () => {
    setOpenAddChild(false);
  };

  const handleOpenSettings = (contractId: number | null) => () => {
    if (contractId) {
      history.push(`/${contractId}/settings`);
    }
  };

  const handleOpenUnbindChildModal = (contractId: number | null) => () => {
    if (contractId) {
      setUnbindChildContractId(contractId);
    }
  };

  const handleCloseUnbindChildModal = () => {
    setUnbindChildContractId(null);
  };

  // Renders
  return (
    <Box mb="40px">
      <Box mb={isTablet ? 3 : '30px'}>
        <Typography variant={isTablet ? 'h4' : 'h3'}>Привязанные аккаунты</Typography>
      </Box>

      <Box mb={isTablet ? 3 : '30px'}>
        {childrenStore.loading ? (
          <Loader />
        ) : (
          children?.map((child, index) => (
            <div key={child.contractId}>
              <Box my={{ md: 1, xl: 2 }} ml={{ xl: '25px' }}>
                <Grid container className={classes.item}>
                  <Grid item md={4}>
                    <Box display="flex" alignItems="center" height="100%">
                      <Box mr="25px" height={isTablet ? 40 : 72}>
                        <QAvatar avatarUrl={child.avatarUrl} size={isTablet ? 40 : 72} />
                      </Box>
                      <Link to={`/${child.contractId}`}>
                        <Typography
                          variant={isTablet ? 'body2' : 'body1'}
                          className={classes.childName}
                          color="textPrimary"
                        >
                          {child.firstLastName}
                        </Typography>
                      </Link>
                    </Box>
                  </Grid>
                  <Grid item md={3} xl={2}>
                    <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
                      <Box mb={{ md: '3px', xl: 0.5 }}>
                        <Typography variant={isTablet ? 'caption' : 'body2'} className={classes.grayLabel}>
                          Обркарта
                        </Typography>
                      </Box>
                      <Typography variant={isTablet ? 'body2' : 'body1'}>{child.contractId}</Typography>
                    </Box>
                  </Grid>
                  <Grid item md={3} xl={2}>
                    <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
                      <Box mb={{ md: '3px', xl: 0.5 }}>
                        <Typography variant={isTablet ? 'caption' : 'body2'} className={classes.grayLabel}>
                          Транспортная карта
                        </Typography>
                      </Box>
                      <Typography variant={isTablet ? 'body2' : 'body1'}>
                        {child.cards.find(card => card.type === 1)?.number || '–––––––––'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={1} xl={2}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                      mx={isTablet ? undefined : 3}
                    >
                      {isTablet ? (
                        <IconButton onClick={handleOpenSettings(child.contractId)}>
                          <SquareIcon component={ChildSettingsIcon} size={24} />
                        </IconButton>
                      ) : (
                        <Button
                          startIcon={<SquareIcon component={ChildSettingsIcon} size={24} />}
                          onClick={handleOpenSettings(child.contractId)}
                        >
                          Настроить
                        </Button>
                      )}
                    </Box>
                  </Grid>
                  <Grid item md={1} xl={2}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                      mx={isTablet ? undefined : 3}
                    >
                      {isTablet ? (
                        <IconButton onClick={handleOpenUnbindChildModal(child.contractId)}>
                          <SquareIcon component={UnbindIcon} size={24} fillNone />
                        </IconButton>
                      ) : (
                        <Button
                          startIcon={<SquareIcon component={UnbindIcon} size={24} fillNone />}
                          onClick={handleOpenUnbindChildModal(child.contractId)}
                        >
                          Отвязать
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {index !== children?.length - 1 && <Divider />}
            </div>
          ))
        )}
      </Box>

      <AddChildBtn onClick={handleOpenAddChildModal} />

      <AddChildModal open={openAddChild} onClose={handleCloseAddChildModal} />

      <UnbindChildModal
        open={!!unbindChildContractId}
        contractId={unbindChildContractId}
        onClose={handleCloseUnbindChildModal}
      />
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    height: 72,
  },
  childName: {
    fontWeight: 500,
  },
  grayLabel: {
    color: theme.palette.neonGray['500'],
  },
}));

export default SettingsChildData;
