import AbstractFactory from '../../base/AbstractFactory';

export default class TCardFactory extends AbstractFactory {
  createCard<T>(Model: any, data: any): T {
    const model = new Model(data);

    if (model.number?.toString().length < 10) {
      model.number = '0' + model.number;
    }
    return model;
  }
}
