import MenuSetupApiRepository from './MenuSetupApiRepository';
import MenuSetupFactory from './MenuSetupFactory';
import MenuCategory from './models/MenuCategory';
import MenuItemModel from './models/MenuItemModel';

export default class MenuSetupService {
  private api: MenuSetupApiRepository;
  private factory: MenuSetupFactory;

  constructor() {
    this.api = new MenuSetupApiRepository();
    this.factory = new MenuSetupFactory();
  }

  getMenuSections = async (contractId: number): Promise<string[]> => {
    const { data } = await this.api.getMenuSections(contractId);

    return data.data?.items;
  };

  getMenu = async (contractId: number): Promise<MenuCategory[]> => {
    const menuItemNames = await this.getMenuSections(contractId);
    const response = await Promise.all(menuItemNames.map(item => this.api.getMenu(contractId, item)));
    const menuItems = response.map(dataItem => dataItem.data.data.items);

    return this.factory.createMenuCategories(menuItemNames, menuItems);
  };

  getMenuCategory = async (contractId: number, itemName: string): Promise<MenuCategory> => {
    const { data } = await this.api.getMenu(contractId, itemName);

    return this.factory.createMenuCategory(itemName, data.data.items);
  };

  toggleMenuItem = (contractId: number, menuItem: MenuItemModel) => {
    if (menuItem.enabled) {
      return this.api.disableMenuItem(contractId, menuItem.text || '');
    }

    return this.api.enableMenuItem(contractId, menuItem.blockId || 0);
  };
}
