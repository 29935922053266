import React, { useState } from 'react';
import { Box, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import { Child } from '../../../modules/children/models/Child';
import QAvatar from '../../../components/UI/QAvatar';
import { useIsTablet } from '../../../hooks/useIsTablet';
import SquareIcon from '../../../components/UI/Icons/SquareIcon';
import { ChangePhotoIcon, UnbindIcon } from '../../../components/UI/Icons/SvgIcons';
import UploadPhotoModal from '../../../components/UploadPhotoModal';
import UnbindChildModal from '../../../components/UnbindChildModal';

const ICON_SIZE_VIEWBOX = '0 0 24 24';

interface IChildItemProps {
  child: Child;
}

enum ModalName {
  addDiary = 'addDiary',
  uploadPhoto = 'uploadPhoto',
  unbind = 'unbind',
}

type ModalOpen = Record<ModalName, boolean>;

const ChildItem: React.FC<IChildItemProps> = props => {
  const { child } = props;
  const classes = useStyles();
  const isTablet = useIsTablet();

  const [showModal, setShowModal] = useState<ModalOpen>({
    [ModalName.addDiary]: false,
    [ModalName.uploadPhoto]: false,
    [ModalName.unbind]: false,
  });

  const handleOpenModal = (modalName: ModalName) => () => {
    setModalState(modalName, true);
  };

  const handleCloseModal = (modalName: ModalName) => () => {
    setModalState(modalName, false);
  };

  const setModalState = (modalName: ModalName, state: boolean) => {
    setShowModal(prev => ({
      ...prev,
      [modalName]: state,
    }));
  };

  return (
    <Box mt={{ md: 1, xl: 2 }} mb={2} display="flex" alignItems="center" height={isTablet ? 136 : 168}>
      <Grid container>
        <Grid md={7} xl={8}>
          <Box display="flex" alignItems="center" height="100%">
            <Box ml={{ xl: 3 }} className={classes.avatar}>
              <QAvatar size={isTablet ? 96 : 144} avatarUrl={child.avatarUrl} />
            </Box>

            <Box display="flex" flexDirection="column" justifyContent="center" mb={{ md: '4px' }}>
              <Box mb={{ md: '10px', xl: 2 }}>
                <Typography variant="h2" className={classes.childName}>
                  {child.firstLastName}
                </Typography>
              </Box>

              <Box mb={{ md: '1px', xl: 0.5 }}>
                <Typography variant={isTablet ? 'body2' : 'body1'} className={classes.grayLabel}>
                  Лицевой счет Обркарты
                </Typography>
              </Box>

              <Typography className={classes.contractId} variant={isTablet ? 'body2' : 'body1'}>
                {child.contractId}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid md={5} xl={4}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" height="100%">
            {/*<Button*/}
            {/*  startIcon={*/}
            {/*    <SquareIcon component={AttachDiaryIcon} size={isTablet ? 16 : 24} viewBox={ICON_SIZE_VIEWBOX} />*/}
            {/*  }*/}
            {/*  className={classes.customBtn}*/}
            {/*  onClick={handleOpenModal(ModalName.addDiary)}*/}
            {/*>*/}
            {/*  Привязать электронный дневник*/}
            {/*</Button>*/}
            <Button
              startIcon={
                <SquareIcon component={ChangePhotoIcon} size={isTablet ? 16 : 24} viewBox={ICON_SIZE_VIEWBOX} />
              }
              className={classes.customBtn}
              onClick={handleOpenModal(ModalName.uploadPhoto)}
            >
              Изменить фотографию
            </Button>
            <Button
              startIcon={
                <SquareIcon component={UnbindIcon} size={isTablet ? 16 : 24} viewBox={ICON_SIZE_VIEWBOX} fillNone />
              }
              className={classes.customBtn}
              onClick={handleOpenModal(ModalName.unbind)}
            >
              Отвязать от аккаунта
            </Button>
          </Box>
        </Grid>
      </Grid>

      {child.contractId && (
        <>
          {/*<AddJournalModal open={showModal.addDiary} onClose={handleCloseModal(ModalName.addDiary)} />*/}
          <UploadPhotoModal
            contractId={child.contractId}
            open={showModal.uploadPhoto}
            onClose={handleCloseModal(ModalName.uploadPhoto)}
          />
          <UnbindChildModal
            contractId={child.contractId}
            open={showModal.unbind}
            onClose={handleCloseModal(ModalName.unbind)}
          />
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  childName: {
    [theme.breakpoints.down('lg')]: {
      fontSize: 21,
      lineHeight: '24px',
    },
  },
  avatar: {
    marginRight: 32,
    [theme.breakpoints.down('lg')]: {
      marginRight: 21,
    },
  },
  grayLabel: {
    color: theme.palette.neonGray['700'],
  },
  contractId: {
    color: theme.palette.neonGray['700'],
    fontWeight: 500,
  },
  customBtn: {
    fontSize: 18,
    lineHeight: '28px',
    fontWeight: 500,
    padding: '8px 24px',
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
      lineHeight: '20px',
      padding: '12px 16px',
    },

    '& .MuiButton-startIcon': {
      marginRight: 12,
      [theme.breakpoints.down('lg')]: {
        marginRight: 8,
      },
    },
  },
}));

export default ChildItem;
