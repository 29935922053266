import React from 'react';
import { Box, Container, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import { useIsTablet } from '../hooks/useIsTablet';
import PhoneDesktop from '../assets/images/footer/phone-desktop.png';
import PhoneTablet from '../assets/images/footer/phone-tablet.png';
import AppStoreBadge from '../assets/images/footer/app-store-badge.png';
import GooglePlayBadge from '../assets/images/footer/google-play-badge.png';
import AppGalleryBadge from '../assets/images/footer/app-galary-padge.png';

interface IFooterPhoneApkProps {}

const FooterPhoneApk: React.FC<IFooterPhoneApkProps> = () => {
  const classes = useStyles();
  const isTablet = useIsTablet();
  const location = useLocation();
  const noTopMargin = location.pathname.indexOf('/preorder/edit') !== -1;

  return (
    <Box className={classes.root} mt={noTopMargin ? 0 : 10}>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item md={7} xl={6}>
            <Box mt={{ md: 2, xl: '40px' }} mb={{ md: 2, xl: '27px' }}>
              <Typography variant={isTablet ? 'h3' : 'h1'} className={classes.title}>
                Скачайте приложение прямо сейчас
              </Typography>
            </Box>
            <Box>
              <Grid container direction="row" spacing={isTablet ? 2 : 4}>
                <Grid item>
                  <a
                    href="https://apps.apple.com/ru/app/%D0%BE%D0%B1%D1%80%D0%BA%D0%B0%D1%80%D1%82%D0%B0-2-0/id1527869810"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    <img src={AppStoreBadge} alt="AppStore" style={isTablet ? { height: 40, width: 122 } : undefined} />
                  </a>
                </Grid>
                <Grid item>
                  <a
                    href="https://play.google.com/store/apps/details?id=ru.obrkarta.apps.android.obrkarta"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    <img
                      src={GooglePlayBadge}
                      alt="GooglePlay"
                      style={isTablet ? { height: 40, width: 134 } : undefined}
                    />
                  </a>
                </Grid>
                <Grid item>
                  <a
                    href="https://appgallery.huawei.com/#/app/C104090547"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    <img
                      src={AppGalleryBadge}
                      alt="AppGallery"
                      style={isTablet ? { height: 40, width: 134 } : undefined}
                    />
                  </a>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item md={5} xl={6} className={classes.rightContainer}>
            {isTablet ? (
              <img src={PhoneTablet} alt="Мобильное приложение" className={classes.imageTablet} />
            ) : (
              <img src={PhoneDesktop} alt="Мобильное приложение" className={classes.imageDesktop} />
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: 283,
    [theme.breakpoints.down('lg')]: {
      height: 176,
    },
  },
  title: {
    fontSize: 48,
    lineHeight: '60px',
    [theme.breakpoints.down('lg')]: {
      fontSize: 28,
      lineHeight: '36px',
    },
  },
  badge: {
    cursor: 'pointer',
  },
  rightContainer: {
    position: 'relative',
    height: 283,
    [theme.breakpoints.down('lg')]: {
      height: 176,
    },
  },
  imageTablet: {
    position: 'absolute',
    bottom: 0,
    left: 39,
  },
  imageDesktop: {
    position: 'absolute',
    bottom: 0,
    left: 66,
  },
}));

export default FooterPhoneApk;
