import CallToInstallService from './CallToInstallService';

export default class CallToInstallStore {
  private callToInstallService: CallToInstallService;

  constructor() {
    this.callToInstallService = new CallToInstallService();
  }

  handleFormShow = () => {
    this.callToInstallService.handleFormShow();
  };
}
