import React, { useEffect } from 'react';
import { Box, Button, Chip, Divider, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useIsTablet } from '../../../hooks/useIsTablet';
import SquareIcon from '../../../components/UI/Icons/SquareIcon';
import { ObrCardIcon, TransportCardIcon, WalletYellowIcon } from '../../../components/UI/Icons/SvgIcons';
import ChildLibrary from './ChildLibrary';
import { hideTextHelper } from '../../../helpers/hideTextHelper';
import currencyFormat, { rubSignFormat } from '../../../helpers/currencyFormat';
import QAvatar from '../../../components/UI/QAvatar';
import useContractId from '../../../hooks/useContractId';
import { useRootStore } from '../../../base/hooks/useRootStore';
import { isNumber } from '../../../utils/isNumber';

const ChildSidebar: React.VFC = observer(() => {
  const { tCardStore, childrenStore } = useRootStore();
  const { child } = childrenStore;
  const classes = useStyles();
  const isTablet = useIsTablet();
  const contractId = useContractId();
  const tCard = child?.contractId ? tCardStore.getTCard(child.contractId) : null;

  const handleReplenishOCClick = () => {
    childrenStore.handleReplenishOCClick(contractId);
  };

  const handleReplenishTKClick = () => {
    childrenStore.handleReplenishTKClick(contractId);
  };

  useEffect(() => {
    if (child?.contractId && !tCard) {
      tCardStore.getChildTCard(child.contractId);
    }
  }, [tCardStore, child?.contractId]);

  return (
    <Grid
      container
      item
      xl={4}
      className={classes.sidebar}
      direction={isTablet ? 'row' : 'column'}
      wrap={isTablet ? 'nowrap' : 'wrap'}
    >
      <Grid container item alignItems="center">
        <QAvatar avatarUrl={child?.avatarUrl} size={isTablet ? 96 : 120} />

        <Box maxWidth={120} ml={3}>
          {child?.inSchool && <Chip label="Сейчас в школе" color="primary" />}
          <Typography variant={isTablet ? 'h4' : 'h3'}>{child?.firstName}</Typography>
        </Box>
      </Grid>

      <Grid container item direction="column">
        {!isTablet && (
          <Box my={2}>
            <Divider />
          </Box>
        )}

        <Grid container item alignItems="center" className={classes.cardInfo}>
          <SquareIcon component={ObrCardIcon} viewBox="0 0 40 40" size={40} />

          <Box ml={2}>
            <Typography>Обркарта</Typography>
            <Typography variant="body2" color="textSecondary">
              {hideTextHelper(String(child?.contractId), 4)}
            </Typography>
          </Box>

          <Box className={classes.priceContainer}>
            <Typography variant={isTablet ? 'h4' : 'h3'} className={classes.price}>
              {child && currencyFormat(child.balance)}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.replenishBtn}
              onClick={handleReplenishOCClick}
            >
              Пополнить
            </Button>

            {isTablet && (
              <Box ml="18px">
                <IconButton onClick={handleReplenishOCClick}>
                  <WalletYellowIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </Grid>

        {!isTablet && (
          <Box my={2}>
            <Divider />
          </Box>
        )}

        <Grid container item alignItems="center" className={classes.cardInfo}>
          <SquareIcon component={TransportCardIcon} size={40} />

          <Box ml={2}>
            <Typography>Транспортная карта</Typography>
            <Typography variant="body2" color="textSecondary">
              {tCard?.number ? hideTextHelper(String(tCard.number), 4) : '–––––––––'}
            </Typography>
          </Box>

          <Box className={classes.priceContainer}>
            <Typography variant={isTablet ? 'h4' : 'h3'} className={classes.price}>
              {child?.contractId && tCard && isNumber(tCard.amount) ? rubSignFormat(tCard.amount) : ''}
            </Typography>

            {tCard?.number && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.replenishBtn}
                onClick={handleReplenishTKClick}
              >
                Пополнить
              </Button>
            )}

            {isTablet && (
              <Box ml="18px">
                <IconButton onClick={handleReplenishTKClick}>
                  <WalletYellowIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Box mb={3} />

      {!isTablet && <ChildLibrary contractId={contractId} />}
    </Grid>
  );
});

const useStyles = makeStyles(theme => ({
  sidebar: {
    [theme.breakpoints.down('lg')]: {
      order: 2,
    },
  },
  cardInfo: {
    position: 'relative',
    height: 60,
    overflow: 'hidden',

    [theme.breakpoints.up('xl')]: {
      '&:hover': {
        '& $replenishBtn': {
          transform: 'translateX(0)',
        },
      },
    },
  },
  priceContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  price: {},
  replenishBtn: {
    position: 'absolute',
    padding: '18px 27px',
    maxHeight: 60,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transform: 'translateX(calc(100% + 1px))',
    transition: theme.transitions.create(['transform']),
  },
}));

export default ChildSidebar;
