import { makeAutoObservable } from 'mobx';

import LibraryService from './LibraryService';
import Publication from './models/Publication';

export default class LibraryStore {
  publications: Publication[] = [];
  loading: boolean = false;
  private libraryService: LibraryService;

  constructor() {
    makeAutoObservable(this);
    this.libraryService = new LibraryService();
  }

  getPublications = (contractId: number) => {
    this.setLoading(true);

    this.libraryService
      .getPublications(contractId)
      .then(publications => {
        this.setPublications(publications);
      })
      .catch(err => {
        this.setPublications([]);
        throw err;
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  get publicationsWithStatus() {
    return this.publications.filter(publication => !!publication.status);
  }

  private setPublications = (publications: Publication[]) => (this.publications = publications);

  private setLoading = (loading: boolean) => (this.loading = loading);
}
