import React, { FC, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

interface IAuthDoubleCheckProps {
  checkAuth: () => Promise<boolean>;
}

const AuthDoubleCheck: FC<IAuthDoubleCheckProps> = ({ children, checkAuth }) => {
  const [isAuthed, setIsAuthed] = useState(false);
  // Effects
  useEffect(() => {
    checkAuth().then(setIsAuthed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Renders
  if (isAuthed) {
    return <Redirect to="/" />;
  }
  return <>{children}</>;
};

export default AuthDoubleCheck;
