import React from 'react';

interface IPagesProps {
  activeStep: number;
  pages: JSX.Element[];
}

const Pages: React.VFC<IPagesProps> = ({ activeStep, pages }) => {
  return pages[activeStep];
};

export default Pages;
