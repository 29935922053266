import React, { useRef } from 'react';
import { Box, FormControlLabel, Grid, RadioGroup, TextField, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useFormik } from 'formik';
import QDatePicker from 'components/UI/QDatePicker';
import { useRootStore } from 'base/hooks/useRootStore';
import QRadio from 'components/UI/QRadio';
import { useIsTablet } from 'hooks/useIsTablet';
import { initialValues } from 'modules/auth/stores/AuthStore';
import { IValues } from 'modules/auth/types/AuthTypes';
import { isEmailValid } from 'helpers/validationHelper';
import LoaderButton from 'components/UI/LoaderButton';
import QFioInput from 'components/UI/QFioInput';
import { Gender } from 'modules/auth/models/Auth';
import { isBetween, isValidDate, MIN_BIRTHDAY_DATE } from 'helpers/dateHelpers';
import history from 'base/routes/history';

import { routes } from '../../../routes';

const validateName = (name: string, isRequired: boolean): string | null => {
  if (isRequired && !name) {
    return 'Обязательное поле';
  }

  if (!name) {
    return null;
  }

  if (name.length < 2 || name.search(/\d/g) !== -1 || name.search(/\S/g) === -1) {
    return 'Неверный формат ввода';
  }

  return null;
};

const RSPersonalData: React.FC = observer(() => {
  const { authStore } = useRootStore();
  const isTablet = useIsTablet();

  const authData = authStore.auth;
  const minBirthdayDate = useRef<Date>(MIN_BIRTHDAY_DATE);
  const maxBirthdayDate = useRef<Date>(new Date());

  const { values, errors, handleChange, setFieldValue, handleBlur, handleSubmit } = useFormik<IValues>({
    initialValues: initialValues(authData),
    validateOnChange: true,
    validateOnBlur: true,
    validate(values) {
      const errors: any = {};

      const errorName = validateName(values.name || '', true);
      if (errorName) {
        errors.name = errorName;
      }

      const errorLastname = validateName(values?.lastname || '', true);
      if (errorLastname) {
        errors.lastname = errorLastname;
      }

      const errorMiddlename = validateName(values?.middlename || '', false);
      if (errorMiddlename) {
        errors.middlename = errorMiddlename;
      }

      if (values.email && !isEmailValid(values.email)) {
        errors.email = 'Неверный формат e-mail';
      }

      if (
        !values.birthdayValid ||
        (values.birthday &&
          (!isValidDate(values.birthday) ||
            !isBetween(minBirthdayDate.current, values.birthday, maxBirthdayDate.current)))
      ) {
        errors.birthday = 'Введен некорректный формат даты';
      }

      return errors;
    },
    onSubmit(values) {
      authStore.updateProfile(values).then(() => {
        history.push(routes.MainScreen.path);
      });
    },
  });

  const handleDateInputBlur = (e: any, date: Date | null) => {
    if (isValidDate(date)) {
      setBirthday(date);
    }
    handleBlur(e);
  };

  const handleDateInputChange = (date: Date | [Date, Date] | null) => {
    if (!Array.isArray(date)) {
      setFieldValue('birthday', date);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    errors[e.target.name as keyof IValues] = '';
    handleChange(e);
  };

  const setBirthday = (date: Date | null) => {
    const isValid = date ? isValidDate(date) : true;

    setFieldValue('birthdayValid', isValid);

    if (isValid) {
      setFieldValue('birthday', date);
    }
  };

  const isFilled =
    values.name?.length && values.lastname?.length && values.name.length > 1 && values.lastname.length > 1;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" spacing={isTablet ? 3 : 4}>
        <Grid item>
          <Typography variant={isTablet ? 'h4' : 'h3'} align="center">
            Введите личные данные
          </Typography>
        </Grid>

        <Grid container item>
          <Grid item md={12}>
            <Box mb={3}>
              <QFioInput
                variant="outlined"
                size={isTablet ? 'small' : 'medium'}
                placeholder="Имя*"
                name="name"
                onChange={onChange}
                value={values.name}
                error={!!errors.name}
                helperText={errors.name}
                inputProps={{ maxLength: 30 }}
                fullWidth
                required
              />
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box mb={3}>
              <QFioInput
                variant="outlined"
                size={isTablet ? 'small' : 'medium'}
                placeholder="Фамилия*"
                name="lastname"
                onChange={onChange}
                value={values.lastname}
                error={!!errors.lastname}
                helperText={errors.lastname}
                inputProps={{ maxLength: 30 }}
                fullWidth
                required
              />
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box mb={3} width="100%">
              <TextField
                variant="outlined"
                size={isTablet ? 'small' : 'medium'}
                placeholder="Отчество"
                name="middlename"
                onChange={onChange}
                value={values.middlename}
                error={!!errors.middlename}
                helperText={errors.middlename}
                inputProps={{ maxLength: 30 }}
                fullWidth
              />
            </Box>
          </Grid>

          <Grid item md={12}>
            <QDatePicker
              id="birthday"
              placeholderText="Дата рождения"
              size={isTablet ? 'small' : 'medium'}
              selected={values.birthday}
              minDate={minBirthdayDate.current}
              maxDate={maxBirthdayDate.current}
              onChange={handleDateInputChange}
              onBlur={handleDateInputBlur}
              error={!!errors.birthday}
              helperText={errors.birthday}
            />
          </Grid>
        </Grid>

        <Grid item>
          <RadioGroup name="gender1" value={values.gender} onChange={onChange} style={{ flexDirection: 'row' }}>
            <FormControlLabel
              value={Gender.male}
              name="gender"
              control={<QRadio color="primary" />}
              label="Мужчина"
              labelPlacement="end"
            />
            <FormControlLabel
              value={Gender.female}
              name="gender"
              control={<QRadio color="primary" />}
              label="Женщина"
              labelPlacement="end"
            />
          </RadioGroup>
        </Grid>

        <Grid item>
          <TextField
            variant="outlined"
            size={isTablet ? 'small' : 'medium'}
            placeholder="Электронная почта"
            name="email"
            onChange={onChange}
            value={values.email}
            error={!!errors.email}
            helperText={errors.email}
            inputProps={{ maxLength: 50 }}
            fullWidth
          />
        </Grid>

        <Grid item>
          <Typography color="textSecondary">* Поля, обязательные к заполнению</Typography>
        </Grid>

        <Grid item>
          <LoaderButton
            type="submit"
            disabled={!isFilled}
            loading={authStore.loading}
            variant="contained"
            color="primary"
            size={isTablet ? 'small' : 'medium'}
            fullWidth
          >
            Зарегистрироваться
          </LoaderButton>
        </Grid>
      </Grid>
    </form>
  );
});

export default RSPersonalData;
