import AbstractModel from '../../../base/AbstractModel';
import TCardTicketType from './TCardTicketType';

export default class TCard extends AbstractModel {
  tariff: string | null = null;
  amount: number | null = null;
  comment: string | null = null;
  number: number | null = null;
  canPay: boolean | null = null;
  tickets: TCardTicketType[] | null = null;

  constructor(data: any) {
    super();
    this.load(data);
  }
}
