import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface ITabScrollButtonProps {
  direction: 'left' | 'right';
  edge: 'border' | 'shadow';
  disabled: boolean;
  onClick: () => void;
}

const QTabScrollButton: React.FC<ITabScrollButtonProps> = props => {
  const classes = useStyles();
  const { direction, disabled, onClick, edge } = props;
  const boxClassName = direction === 'left' ? classes.rootLeft : classes.rootRight;

  if (disabled) {
    return null;
  }

  return (
    <Box position="absolute" className={clsx(classes.root, boxClassName)}>
      <Box
        className={clsx(classes.button, edge === 'border' && classes.border)}
        onClick={onClick}
        boxShadow={edge === 'shadow' ? 1 : 0}
      >
        {props.children}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 'calc(50% - 24px)',
  },
  rootLeft: {
    left: 4,
    zIndex: 1,
    transform: 'rotate(180deg)',
  },
  rootRight: {
    right: 4,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
    width: 48,
    borderRadius: 24,
    backgroundColor: 'white',
    cursor: 'pointer',
  },
  border: {
    border: '1px solid #D5D9DE',
  },
}));

export default QTabScrollButton;
