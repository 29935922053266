import { Box, TextField, Tooltip } from '@material-ui/core';
import React, { FC } from 'react';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { useFormik } from 'formik';
import { useIsTablet } from 'hooks/useIsTablet';
import { useAddChildCommonStyles } from 'styles/CommonStyles';
import { useRootStore } from 'base/hooks/useRootStore';
import { StepSettingOptions } from 'hooks/useStepHandler';
import { useOnlyDigitsHandleChange } from 'hooks/useOnlyDigitsHandleChange';

import { REQUIRED_FIELD_TEXT } from './BaseAddChild';
import PasswordResetModal from './PasswordResetModal';
import AccountTooltip from './AccountTooltip';
import LoaderButton from '../UI/LoaderButton';

interface IPasswordResetFirstStepProps {
  handleSetStep: (options: StepSettingOptions) => void;
  loading?: boolean;
}

const title = 'Восстановить пароль';

const PasswordResetFirstStep: FC<IPasswordResetFirstStepProps> = ({ handleSetStep, loading }) => {
  const { childrenStore } = useRootStore();
  const isTablet = useIsTablet();
  const addChildCommonClasses = useAddChildCommonStyles();

  const { values, errors, handleSubmit, handleChange } = useFormik({
    initialValues: {
      contractId: '',
    },
    validateOnChange: false,
    validate(values) {
      const errors: any = {};

      if (!values.contractId) {
        errors.contractId = REQUIRED_FIELD_TEXT;
      }

      return errors;
    },
    onSubmit(values) {
      childrenStore.restorePassword(
        +values.contractId,
        () => {
          handleSetStep({ step: 4 });
        },
        () => {
          handleSetStep({ step: 3 });
        },
      );
    },
  });

  // Handlers
  const handleBackButton = () => {
    handleSetStep({ step: 0 });
  };

  const onlyDigitsHandleChange = useOnlyDigitsHandleChange(handleChange);

  // Renders
  return (
    <PasswordResetModal title={title} handleBackButton={handleBackButton}>
      <Box mb={{ md: '28px', xl: 3 }}>
        <TextField
          variant="outlined"
          type="text"
          placeholder="Номер лицевого счета"
          name="contractId"
          size={isTablet ? 'small' : 'medium'}
          onChange={onlyDigitsHandleChange}
          value={values.contractId}
          error={!!errors.contractId}
          helperText={errors.contractId}
          fullWidth
          InputProps={{
            endAdornment: (
              <Tooltip
                placement="right-start"
                classes={{
                  tooltip: addChildCommonClasses.tooltip,
                  tooltipPlacementRight: addChildCommonClasses.tooltipPlacement,
                }}
                title={<AccountTooltip />}
              >
                <HelpOutlineOutlinedIcon className={addChildCommonClasses.icon} />
              </Tooltip>
            ),
          }}
        />
      </Box>

      <LoaderButton
        variant="contained"
        color="primary"
        fullWidth
        size={isTablet ? 'small' : 'medium'}
        loading={loading || false}
        onClick={() => handleSubmit()}
      >
        {title}
      </LoaderButton>
    </PasswordResetModal>
  );
};

export default PasswordResetFirstStep;
