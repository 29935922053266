import { makeAutoObservable, runInAction } from 'mobx';

import Operation from './OperationModel';
import OperationsService from './OperationsService';
import { rootStore } from '../../base/RootStore';
import { OperationsParams } from './interfaces/OperationsInterfaces';

export default class OperationsStore {
  loading: boolean = false;
  operations: Operation[] = [];

  private operationsService: OperationsService;

  constructor() {
    makeAutoObservable(this);
    this.operationsService = new OperationsService();
  }

  getOperations = (params: OperationsParams): void => {
    const datesAreNotValid = !params.filterEndedAt || !params.filterStartedAt;
    if (this.loading || datesAreNotValid) {
      return;
    }
    this.setLoading(true);

    this.operationsService
      .getOperations(rootStore.childrenStore.children, params)
      .then(data => {
        runInAction(() => {
          this.operations = data;
        });
      })
      .finally(() => this.setLoading(false));
  };

  getReportFile = (params: OperationsParams) => {
    this.operationsService
      .getReportFile(params)
      .then(({ url }) => {
        const fileName = url.split('/')[-1];
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.click();
      })
      .catch(() => {})
      .finally(() => {});
  };

  filterOperations = (id: number): Operation[] => {
    return this.operations?.filter(({ contractId }) => contractId && +contractId === id);
  };

  setLoading = (value: boolean): void => {
    this.loading = value;
  };
}
