import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';

import PasswordResetModal from './PasswordResetModal';

interface IPasswordChangedModalProps {
  childName: string;
}

const PasswordChangedModal: FC<IPasswordChangedModalProps> = ({ childName }) => {
  const classes = useStyles();

  // Renders
  return (
    <PasswordResetModal title="Пароль заменён">
      <Box mb={4}>
        <Typography variant="body1" className={classes.text}>
          Пароль от лицевого счета Обркарты для ребенка {childName} изменен на новый.
        </Typography>
      </Box>
    </PasswordResetModal>
  );
};

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.secondary,
  },
}));

export default PasswordChangedModal;
