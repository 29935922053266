import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '../../base/hooks/useRootStore';

const ProgressArray: React.FC = observer(() => {
  const { storiesStore } = useRootStore();
  const { activeStoryIndex, activeStorySlideIndex, pause, defaultDurationMs } = storiesStore;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {storiesStore.stories[activeStoryIndex].images?.map((image, index) => {
        const isActive = activeStorySlideIndex === index;
        const isFilled = index < activeStorySlideIndex;
        const isEmpty = index > activeStorySlideIndex;

        return (
          <div key={image.img} className={classes.progressItemContainer}>
            <div
              className={clsx(classes.progress, {
                [classes.progressFilled]: isFilled,
                [classes.animation]: isActive,
                [classes.animationPause]: isActive && pause,
                [classes.progressEmpty]: isEmpty,
              })}
              style={{ animationDuration: `${defaultDurationMs}ms` }}
            />
          </div>
        );
      })}
    </div>
  );
});

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    margin: '16px 20px',
  },
  progressItemContainer: {
    flex: 1,
    height: 4,
    marginLeft: 1,
    marginRight: 1,
    borderRadius: 4,
    backgroundColor: 'rgba(255,255,255,.35)',
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  progress: {
    height: 4,
    borderRadius: 4,
    backgroundColor: 'white',
  },
  progressFilled: {
    width: '100%',
  },
  progressEmpty: {
    width: 0,
  },
  animation: {
    animation: '$slideRight linear',
  },
  animationPause: {
    animationPlayState: 'paused',
  },
  '@keyframes slideRight': {
    from: {
      width: 0,
    },
    to: {
      width: '100%',
    },
  },
}));

export default ProgressArray;
