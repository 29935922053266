import AttendanceApiRepository from './AttendanceApiRepository';
import AttendanceFactory from './AttendanceFactory';

export default class AttendanceService {
  private api: AttendanceApiRepository;
  private factory: AttendanceFactory;

  constructor() {
    this.api = new AttendanceApiRepository();
    this.factory = new AttendanceFactory();
  }

  getAttendance = async (contractId: number) => {
    const { data } = await this.api.getAttendance(contractId);

    return this.factory.createAttendanceList(data.data.items);
  };
}
