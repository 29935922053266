import React from 'react';
import { makeStyles, Menu, MenuProps } from '@material-ui/core';

const QMenu: React.FC<MenuProps> = props => {
  const classes = useStyles();
  const { children, ...rest } = props;

  return (
    <Menu classes={{ paper: classes.menu, list: classes.list }} elevation={10} {...rest}>
      {children}
    </Menu>
  );
};

const useStyles = makeStyles(theme => ({
  menu: {
    borderRadius: 4,
    minWidth: 272,
    [theme.breakpoints.down('lg')]: {
      minWidth: 200,
      width: 200,
    },
  },
  list: {
    padding: '6px 0',
  },
}));

export default QMenu;
