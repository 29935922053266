import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, makeStyles, Tab, Tabs } from '@material-ui/core';

import { ArrowIcon } from '../../../components/UI/Icons/SvgIcons';
import { useRootStore } from '../../../base/hooks/useRootStore';
import QTabScrollButton from '../../../components/UI/header/QTabScrollButton';
import SquareIcon from '../../../components/UI/Icons/SquareIcon';
import Stories from '../../../components/Stories/Stories';

const StoriesList: React.VFC = observer(() => {
  const { storiesStore } = useRootStore();
  const classes = useStyles();

  useEffect(() => {
    storiesStore.getStories();
  }, []);

  return (
    <>
      <Stories />
      <Box py={4} className={classes.tabsContainer}>
        <Tabs
          value={false}
          variant="scrollable"
          scrollButtons="on"
          className={classes.tabs}
          ScrollButtonComponent={props => (
            <QTabScrollButton {...props} edge="border">
              <SquareIcon component={ArrowIcon} size={19} />
            </QTabScrollButton>
          )}
        >
          {storiesStore.stories.map(({ preview, title }, index) => {
            return (
              <Tab
                key={title}
                disableRipple
                onClick={() => storiesStore.setActiveStory(index)}
                className={classes.tab}
                icon={
                  <Box width={154} height={168}>
                    {preview && <img className={classes.storyPreview} src={preview} alt="" />}
                  </Box>
                }
              />
            );
          })}
        </Tabs>
      </Box>
    </>
  );
});

const useStyles = makeStyles(theme => ({
  storyPreview: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  tab: {
    opacity: 1,
  },
  tabsContainer: {
    background: theme.palette.neonGray['50'],
    display: 'flex',
    justifyContent: 'center',
  },
  tabs: {
    maxWidth: 1240,
    position: 'relative',
  },
}));

export default StoriesList;
