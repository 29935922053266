const hideTextHelper = (text: string, lastCharsNum: number, dotsNum: number = 4) => {
  const replaceHiddenValue = '•'.repeat(dotsNum);
  return `${replaceHiddenValue}${text.substr(-lastCharsNum)}`;
};

const GET_PHONE_DATA_REGEXP = /(\d)(\d{2})$/;

const hidePhone = (phone: string): string => {
  const match = phone.match(GET_PHONE_DATA_REGEXP);

  if (match) {
    // Берём последние три цифры
    return `+7 *** *** *${match[1]} ${match[2]}`;
  }

  return '';
};

export { hideTextHelper, hidePhone };
