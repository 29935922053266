import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

import BusSvg from '../../../assets/icons/activate-replenish-card/1-bus.svg';
import TerminalSvg from '../../../assets/icons/activate-replenish-card/2-terminal.svg';
import MagicSvg from '../../../assets/icons/activate-replenish-card/3-some-magic.svg';
import ActivatedSvg from '../../../assets/icons/activate-replenish-card/4-activated.svg';
import PutCardSvg from '../../../assets/icons/activate-replenish-card/5-put-card.svg';
import { useIsTablet } from '../../../hooks/useIsTablet';

interface IInstructionProps {
  icon: string;
}

const Instruction: React.FC<IInstructionProps> = ({ icon, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.guideItem}>
      <Box mb={3}>
        <img src={icon} alt="" />
      </Box>
      <Typography align="center" className={classes.guideText}>
        {children}
      </Typography>
    </div>
  );
};

const ReplenishmentInstructions = () => {
  const isTablet = useIsTablet();

  return (
    <>
      <Box mb="40px">
        <Typography variant={isTablet ? 'h3' : 'h1'} align="center">
          Как активировать пополнение?
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" flexWrap="wrap">
        <Instruction icon={BusSvg}>
          Попросите кондуктора общественного транспорта активировать онлайн-пополнение
        </Instruction>
        <Instruction icon={TerminalSvg}>Кондуктор попросит вас приложить карту к терминалу</Instruction>
        <Instruction icon={MagicSvg}>Произойдет магия</Instruction>
        <Instruction icon={ActivatedSvg}>Ваше онлайн-пополнение активировано!</Instruction>
        <Instruction icon={PutCardSvg}>Приложите карту к терминалу еще раз для оплаты проезда</Instruction>
      </Box>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  guideItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20%',
    [theme.breakpoints.down('lg')]: {
      width: '33.333%',
    },
  },
  guideText: {
    width: 217,
    [theme.breakpoints.down('lg')]: {
      width: 251,
    },
  },
}));

export default ReplenishmentInstructions;
