import React, { useMemo } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import Attendance from '../../../modules/attendance/models/Attendance';
import { useIsTablet } from '../../../hooks/useIsTablet';

interface Props {
  attendances: Attendance[];
}

const ChildAttendanceTime: React.VFC<Props> = observer(props => {
  const { attendances } = props;
  const classes = useStyles();
  const isTablet = useIsTablet();

  const sortedAttendances = useMemo(() => (attendances.length ? attendances.slice().reverse() : [{} as Attendance]), [
    attendances,
  ]);

  return (
    <>
      {sortedAttendances.map((attendance, index) => (
        <Box py={2} display="flex" justifyContent="space-around" alignItems="center" key={index}>
          {[attendance.enter, attendance.exit].map((timestamp, index) => {
            const time = timestamp ? (
              <Typography variant={isTablet ? 'body2' : 'body1'} className={classes.timestamp}>
                {timestamp}
              </Typography>
            ) : (
              <Box width={63} textAlign="center">
                <Typography variant={isTablet ? 'body2' : 'body1'} className={classes.timestamp} color="textSecondary">
                  ––:––
                </Typography>
              </Box>
            );

            return (
              <React.Fragment key={index}>
                {index === 1 && <Dash />}
                {time}
              </React.Fragment>
            );
          })}
        </Box>
      ))}
    </>
  );
});

const Dash: React.VFC = () => {
  const classes = useStyles();
  return <Box width={16} height={2} className={classes.dash} />;
};

const useStyles = makeStyles(theme => ({
  dash: {
    background: theme.palette.neonGray[150],
  },
  timestamp: {
    fontWeight: 500,
    width: 64,
    textAlign: 'center',
  },
}));

export default ChildAttendanceTime;
