import AbstractModel from '../../../base/AbstractModel';

export default class NewsModel extends AbstractModel {
  id: string | null = null;
  text: string | null = null;
  subtitle: string | null = null;
  url: string | null = null;
  content: string | null = null;
  image: string | null = null;
  date: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
