import AbstractModel from './AbstractModel';

export default class AbstractDto extends AbstractModel {
  static populate<T>(data: any): T {
    const dto = new this();

    dto.getAttributes().forEach(attribute => {
      if (data && data.hasOwnProperty(attribute)) {
        (dto as any)[attribute] = data[attribute];
      }
    });

    return (dto as unknown) as T;
  }
}
