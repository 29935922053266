export interface Values {
  newPassword: string;
  repeatPassword: string;
}

const usePasswordChangeValidationRules = (values: Values) => {
  const isEnoughLength = values.newPassword.length >= 8;
  const isLatin = /[a-zA-Z]/.test(values.newPassword);
  const hasAtLeastOneBigLetter = /[A-Z]{1,}/.test(values.newPassword);
  const hasAtLeastOneNumber = /[0-9]{1,}/.test(values.newPassword);
  const fieldsAreEqual = values.newPassword === values.repeatPassword;

  return {
    isEnoughLength,
    isLatin,
    hasAtLeastOneBigLetter,
    hasAtLeastOneNumber,
    fieldsAreEqual,
  };
};

export default usePasswordChangeValidationRules;
