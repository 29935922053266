import React, { useCallback } from 'react';
import { Box, TextField } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useFormik } from 'formik';

import { RussianPhoneMaskInput } from '../../../../components/UI/RussianPhoneMaskInput';
import { useRootStore } from '../../../../base/hooks/useRootStore';
import AuthButton from '../AuthButton';
import { useIsTablet } from '../../../../hooks/useIsTablet';

const RSPhone: React.VFC = observer(() => {
  const { authStore: store } = useRootStore();
  const isTablet = useIsTablet();

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      phone: '',
    },
    validateOnChange: false,
    validate(values) {
      const errors: any = {};

      if (!values.phone || values.phone.length !== 10) {
        errors.phone = 'Некорректный формат номера телефона';
      }

      return errors;
    },
    onSubmit(values) {
      store.setPhone(values.phone);
      store.register();
    },
  });

  const handleKeyPress = useCallback(
    (e: any) => {
      if (e.key === 'Enter') {
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  return (
    <>
      {/*<Box mb={{ md: 2, xl: 4 }}>*/}
      {/*  <Typography variant={isTablet ? 'h4' : 'h3'}>Введите номер телефона для входа</Typography>*/}
      {/*</Box>*/}

      <Box mb={{ md: '36px', xl: 4 }}>
        <TextField
          variant="outlined"
          placeholder="Телефон"
          name="phone"
          size={isTablet ? 'small' : 'medium'}
          InputProps={{ inputComponent: RussianPhoneMaskInput }}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          value={values.phone}
          error={!!errors.phone}
          helperText={errors.phone}
          fullWidth
        />
      </Box>

      <Box mb={{ md: 2, xl: 4 }}>
        <AuthButton onClick={() => handleSubmit()} disabled={!values.phone.length}>
          Войти
        </AuthButton>
      </Box>

      {/*<Box mb={{ md: 2, xl: 4 }}>*/}
      {/*  <Divider />*/}
      {/*</Box>*/}

      {/*<Box display="flex" justifyContent="center">*/}
      {/*  <Typography component="span" color="textSecondary">*/}
      {/*    Есть аккаунт на сайте?*/}
      {/*  </Typography>*/}
      {/*  <Box ml={2} component="span">*/}
      {/*    <Typography component="span">Войти</Typography>*/}
      {/*  </Box>*/}
      {/*</Box>*/}
    </>
  );
});

export default RSPhone;
