import React, { ChangeEvent } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import QSelect from './QSelect';

interface IQSelectChildrenProps {
  value: number | string; // empty string to show empty QSelect
  onChange: (contractId: number) => void;
}

const QSelectChildren: React.FC<IQSelectChildrenProps> = observer(props => {
  const { value, onChange, children } = props;
  const classes = useStyles();

  const handleChange = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    onChange(e.target.value as number);
  };

  return (
    <QSelect
      labelId="select-label"
      value={value}
      onChange={handleChange}
      classes={{
        select: classes.select,
        outlined: classes.outlined,
      }}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        classes: {
          list: classes.selectMenu,
          paper: classes.menuPaper,
        },
      }}
    >
      {children}
    </QSelect>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  select: {
    padding: '12px 16px',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    '&$outlined': {
      paddingRight: '35px',
    },
  },
  outlined: {
    paddingRight: '35px',
  },
  selectMenu: {
    padding: 0,
  },
  menuPaper: {
    boxShadow: theme.shadows[10],
    borderRadius: 4,
  },
}));

export default QSelectChildren;
