import React from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

import EmptyLibraryAsset from '../../../assets/images/empty-library.svg';
import { useIsTablet } from '../../../hooks/useIsTablet';

interface IEmptyLibraryProps {}

const EmptyLibrary: React.FC<IEmptyLibraryProps> = () => {
  const isTablet = useIsTablet();
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item md={5} xl={5}>
        <Box display="flex" height="100%" alignItems="center">
          <Typography variant={isTablet ? 'h4' : 'h3'}>
            Здесь появится список книг, которые возьмет Ваш ребенок в школьной библиотеке.
          </Typography>
        </Box>
      </Grid>
      <Grid item md={7} xl={7}>
        <img src={EmptyLibraryAsset} alt="" className={classes.image} />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  image: {
    marginTop: 10,
    marginLeft: 93,
    height: 415,
    width: 477,
    [theme.breakpoints.down('lg')]: {
      marginTop: 15,
      marginLeft: 67,
      height: 348,
      width: 385,
    },
  },
}));

export default EmptyLibrary;
