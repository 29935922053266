import React, { useEffect } from 'react';
import { Box, Button, Chip, Grid, IconButton, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import SquareIcon from '../../../components/UI/Icons/SquareIcon';
import { ObrCardIcon, WalletYellowIcon } from '../../../components/UI/Icons/SvgIcons';
import { useIsTablet } from '../../../hooks/useIsTablet';
import TkIcon from '../../../assets/images/kazan-tk-icon.png';
import { Child } from '../../../modules/children/models/Child';
import currencyFormat, { rubSignFormat } from '../../../helpers/currencyFormat';
import QAvatar from '../../../components/UI/QAvatar';
import { useRootStore } from '../../../base/hooks/useRootStore';
import { isNumber } from '../../../utils/isNumber';

interface IAnimCardWrapperProps {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const AnimCardWrapper: React.FC<IAnimCardWrapperProps> = ({ children, onClick, className, disabled }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(!disabled && classes.animCard)} minHeight="100px" onClick={onClick}>
      <Paper elevation={0} className={clsx(classes.animCardPaper, className)}>
        {children}
      </Paper>
    </Box>
  );
};

interface ISmallPayBtnProps {
  showIcon: boolean;
  onClick: () => void;
}

const SmallPayBtn: React.FC<ISmallPayBtnProps> = props => {
  const { showIcon, onClick } = props;
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height={40} width={40}>
      {showIcon && (
        <IconButton onClick={onClick}>
          <SquareIcon component={WalletYellowIcon} size={16} fillNone />
        </IconButton>
      )}
    </Box>
  );
};

interface IChildItemProps {
  child: Child;
}

const ChildItem: React.FC<IChildItemProps> = observer(({ child }) => {
  const { tCardStore, childrenStore } = useRootStore();
  const classes = useStyles();
  const isTablet = useIsTablet();
  const history = useHistory();
  const tCard = child?.contractId ? tCardStore.getTCard(child.contractId) : null;

  const handleClickOnChild = () => {
    history.push(`/${child.contractId}`);
  };

  const handleChildCardClick = () => {
    if (isTablet) {
      handleClickOnChild();
    }
  };

  const handleReplenishOCClick = () => {
    child?.contractId && childrenStore.handleReplenishOCClick(child.contractId);
  };

  const handleReplenishTKClick = () => {
    child?.contractId && childrenStore.handleReplenishTKClick(child.contractId);
  };

  useEffect(() => {
    child?.contractId && child.cards[0] && tCardStore.getChildTCard(child.contractId);
  }, []);

  return (
    <Grid container className={classes.root}>
      <Grid item md={6}>
        <AnimCardWrapper className={classes.leftContainer} onClick={handleChildCardClick}>
          <Box mr={{ md: '21px', xl: '32px' }}>
            <QAvatar avatarUrl={child.avatarUrl} size={isTablet ? 96 : 144} />
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
            {child.inSchool && (
              <Box mb={2}>
                <Chip label="Сейчас в школе" color="primary" />
              </Box>
            )}

            <Typography variant={isTablet ? 'h4' : 'h3'}>{child.firstLastName}</Typography>

            <div className={classes.animBtn}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.btnStyle}
                onClick={handleClickOnChild}
              >
                Подробная информация
              </Button>
            </div>
          </Box>
        </AnimCardWrapper>
      </Grid>

      <Grid item md={6}>
        <Grid container className={classes.rightContainer}>
          <Grid item md={12} xl={6} className={clsx(classes.cardContainer, classes.obrCard)}>
            <Box display="flex" alignItems="center" height="100%">
              <AnimCardWrapper>
                <SquareIcon
                  className={classes.cardIcon}
                  component={ObrCardIcon}
                  size={isTablet ? 32 : 40}
                  viewBox="0 0 40 40"
                />
                <Grid container>
                  <Grid item md={6} xl={12}>
                    <Typography>Обркарта</Typography>
                    <Typography className={classes.number} variant="body2">
                      {child.contractId}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xl={12}>
                    <Box display="flex" justifyContent={{ md: 'flex-end', xl: 'flex-start' }} alignItems="center">
                      <Typography variant="h3" className={classes.amount}>
                        {currencyFormat(child.balance)}
                      </Typography>
                      {isTablet && <SmallPayBtn showIcon onClick={handleReplenishOCClick} />}
                    </Box>
                  </Grid>
                  {!isTablet && (
                    <div className={clsx(classes.animBtn, classes.payBtn)}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.btnStyle}
                        onClick={handleReplenishOCClick}
                      >
                        Пополнить
                      </Button>
                    </div>
                  )}
                </Grid>
              </AnimCardWrapper>
            </Box>
          </Grid>

          <Grid item md={12} xl={6} className={clsx(classes.cardContainer, classes.tkCard)}>
            <Box display="flex" alignItems="center" height="100%">
              <AnimCardWrapper disabled={!tCard?.number && true}>
                <img className={clsx(classes.cardIcon, classes.cardImg)} src={TkIcon} alt="Транспортная кара" />
                <Grid container>
                  <Grid item md={6} xl={12}>
                    <Typography>Транспортная карта</Typography>
                    <Typography className={classes.number} variant="body2">
                      {tCard?.number || '–––––––––'}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xl={12}>
                    <Box display="flex" justifyContent={{ md: 'flex-end', xl: 'flex-start' }} alignItems="center">
                      <Typography variant="h3" className={classes.amount}>
                        {child?.contractId && tCard && isNumber(tCard.amount) ? rubSignFormat(tCard.amount) : ''}
                      </Typography>
                      {isTablet && <SmallPayBtn showIcon={!!tCard} onClick={handleReplenishTKClick} />}
                    </Box>
                  </Grid>
                  {!isTablet && tCard && (
                    <div className={clsx(classes.animBtn, classes.payBtn)}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.btnStyle}
                        onClick={handleReplenishTKClick}
                      >
                        Пополнить
                      </Button>
                    </div>
                  )}
                </Grid>
              </AnimCardWrapper>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(2, 0),
  },
  number: {
    color: theme.palette.neonGray['500'],
  },
  btnStyle: {
    height: 40,
  },
  animCard: {
    flex: 1,
    [theme.breakpoints.up('xl')]: {
      '&:hover': {
        '& $animCardPaper': {
          boxShadow: theme.shadows[10],
        },
        '& $animBtn': {
          height: 40,
          opacity: 1,
          visibility: 'visible',
        },
      },
    },
  },
  animCardPaper: {
    display: 'flex',
    padding: theme.spacing(1, 3),
    borderRadius: 12,
    transition: theme.transitions.create('box-shadow'),
    overflow: 'hidden',
  },
  animBtn: {
    height: 0,
    opacity: 0,
    visibility: 'hidden',
    marginTop: theme.spacing(2),
    transition: theme.transitions.create(['height', 'opacity', 'visibility']),
  },
  payBtn: {
    marginTop: theme.spacing(1),
  },
  amount: {
    marginRight: 16,
  },
  cardContainer: {
    [theme.breakpoints.up('xl')]: {
      height: '100%',
    },
  },
  leftContainer: {
    marginRight: 36,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0,
      cursor: 'pointer',
    },
  },
  rightContainer: {
    height: '100%',
  },
  cardImg: {
    [theme.breakpoints.up('xl')]: {
      height: 40,
    },
    [theme.breakpoints.down('lg')]: {
      height: 32,
    },
  },
  cardIcon: {
    [theme.breakpoints.up('xl')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('lg')]: {
      marginRight: theme.spacing(1),
    },
  },
  obrCard: {
    [theme.breakpoints.up('xl')]: {
      paddingRight: 16,
    },
  },
  tkCard: {
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 16,
    },
  },
}));

export default ChildItem;
