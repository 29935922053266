import { makeAutoObservable } from 'mobx';

import NewsModel from './models/NewsModel';
import NewsService from './NewsService';

export default class NewsStore {
  news: NewsModel[] = [];
  loading: boolean = false;

  private newsService: NewsService;

  constructor() {
    makeAutoObservable(this);
    this.newsService = new NewsService();
  }

  getNews = () => {
    this.setLoading(true);

    this.newsService
      .getNews()
      .then(news => {
        this.setNews(news);
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  private setNews = (news: NewsModel[]) => (this.news = news);

  private setLoading = (loading: boolean) => (this.loading = loading);
}
