import React, { useState } from 'react';
import { Box, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import HeaderAvatar from './HeaderAvatar';
import QMenu from '../QMenu';
import SquareIcon from '../Icons/SquareIcon';
import { ProfileIcon, WalletIcon, SettingsIcon, LogoutIcon } from '../Icons/SvgIcons';
import QMenuItem from '../QMenuItem';
import QListItemIcon from '../QListItemIcon';
import QMenuDivider from '../QMenuDivider';
import { useIsTablet } from '../../../hooks/useIsTablet';
import { useRootStore } from '../../../base/hooks/useRootStore';
import { createViewBoxHelper } from '../Icons/createViewBoxHelper';

const VIEWBOX = createViewBoxHelper(18);

interface IProfileMenuProps {}

const ProfileMenu: React.FC<IProfileMenuProps> = observer(() => {
  const { authStore } = useRootStore();
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useIsTablet();
  const history = useHistory();
  const location = useLocation();
  const { profileStore } = useRootStore();
  const { profile } = profileStore;

  const isActive = location.pathname === '/settings';
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenu = (path: string) => () => {
    history.push(path);
    handleClose();
  };

  const handleClickLogout = () => {
    authStore.logout();
    handleClose();
  };

  const renderProfile = (inMenu: boolean) => {
    return (
      <Box
        display="flex"
        alignItems="center"
        pl={inMenu ? '8px' : { md: '17px', xl: '50px' }}
        borderLeft={inMenu ? undefined : `1px solid ${theme.palette.neonGray['150']}`}
        className={classes.profile}
        onClick={handleClick}
      >
        <HeaderAvatar active={!inMenu && isActive}>
          <SquareIcon component={ProfileIcon} size={isTablet ? 16 : 24} viewBox={VIEWBOX} />
        </HeaderAvatar>
        <Box ml={{ md: '8px', xl: '16px' }}>
          <Typography
            variant={isTablet ? 'caption' : 'body2'}
            className={clsx(classes.name, isActive && classes.nameActive)}
            noWrap={true}
          >
            {profile?.firstLastName}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {renderProfile(false)}
      <QMenu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: isTablet ? 'top' : 'bottom', horizontal: 'left' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={10}
      >
        {isTablet && renderProfile(true)}
        <QMenuItem onClick={handleClickMenu('/operations')}>
          <QListItemIcon>
            <SquareIcon component={WalletIcon} size={16} />
          </QListItemIcon>
          <Typography variant={isTablet ? 'body2' : 'body1'}>Операции</Typography>
        </QMenuItem>
        <QMenuItem onClick={handleClickMenu('/settings')}>
          <QListItemIcon>
            <SquareIcon component={SettingsIcon} size={16} />
          </QListItemIcon>
          <Typography variant={isTablet ? 'body2' : 'body1'}>Настройки</Typography>
        </QMenuItem>
        <QMenuDivider />
        <QMenuItem onClick={handleClickLogout}>
          <QListItemIcon>
            <SquareIcon component={LogoutIcon} size={16} />
          </QListItemIcon>
          <Typography variant={isTablet ? 'body2' : 'body1'}>Выйти</Typography>
        </QMenuItem>
      </QMenu>
    </>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  profile: {
    flexShrink: 0,
    height: '100%',
    cursor: 'pointer',
  },
  name: {
    maxWidth: 150,
    color: theme.palette.neonGray['500'],
  },
  nameActive: {
    color: 'black',
  },
}));

export default ProfileMenu;
