import ChildrenApiRepository from '../repositories/ChildrenApiRepository';
import { ChildrenFactory } from '../factories/ChildrenFactory';
import { IChildCardValues } from '../types/ChildrenTypes';
import { Child } from '../models/Child';
import { RestoreTokenData } from '../models/RestoreTokenData';
import { CheckPassword } from '../models/CheckPassword';

export default class ChildrenService {
  api: ChildrenApiRepository;
  factory: ChildrenFactory;

  constructor() {
    this.api = new ChildrenApiRepository();
    this.factory = new ChildrenFactory();
  }

  getChildren = async (): Promise<{
    popup: { needChangePassword: boolean; contractId: number };
    children: Child[];
  }> => {
    const { data } = await this.api.getChildren();

    return { popup: data.data.popup, children: this.factory.createChildList(Child, data.data.contracts) };
  };

  addChild = async (card: IChildCardValues): Promise<Child> => {
    const { data } = await this.api.addChild(card);

    return this.factory.createChild(Child, data.data);
  };

  changeEmail = (contractId: number, email: string) => this.api.changeEmail(contractId, email);

  changePhone = (contractId: number, phone: string) => this.api.changePhone(contractId, phone);

  changeExpenditureLimit = (contractId: number, limit: number) => this.api.changeExpenditureLimit(contractId, limit);

  changePassword = (contractId: number, currentPassword: string, newPassword: string, repeatPassword: string) =>
    this.api.changePassword(contractId, currentPassword, newPassword, repeatPassword);

  changePasswordIfForgot = async (contractId: number, newPassword: string, repeatPassword: string, token: string) => {
    const { data } = await this.api.changePasswordIfForgot(contractId, newPassword, repeatPassword, token);

    return data.data;
  };

  checkPassword = async (contractId: number, password: string) => {
    const { data } = await this.api.checkPassword(contractId, password);

    return this.factory.create<CheckPassword>(CheckPassword, data.data);
  };

  restorePassword = async (contractId: number) => {
    const { data } = await this.api.restorePassword(contractId);

    return this.factory.create<RestoreTokenData>(RestoreTokenData, data.data);
  };

  changeAvatar = (contractId: number, avatar: File) => {
    const formData = new FormData();

    formData.append('avatar', avatar);

    return this.api.uploadAvatar(contractId, formData);
  };

  removeAvatar = (contractId: number) => this.api.removeAvatar(contractId);

  unbindChild = (contractId: number) => this.api.unbindChild(contractId);
}
