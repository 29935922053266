export interface IPaymentLink {
  url: string;
  orderId: string;
}

export enum CheckStatus {
  SUCCESS = 'success',
  EXPIRED = 'expired',
  WAIT = 'wait',
}

export interface ICheckPayment {
  text: string;
  value: CheckStatus;
}
