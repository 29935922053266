import AbstractDto from '../../../base/AbstractDto';

export default class ProfileDto extends AbstractDto {
  name: string = '';
  middlename: string = '';
  lastname: string | null = null;
  gender: string | null = null;
  birthday: string | null = null;
  email: string | null = null;
}
