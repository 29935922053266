import React from 'react';
import { Box, Dialog, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import ProgressArray from './ProgressArray';
import { StoriesArrow, CloseStories } from '../UI/Icons/SvgIcons';
import { useRootStore } from '../../base/hooks/useRootStore';

const Stories: React.FC = observer(() => {
  const classes = useStyles();
  const { storiesStore } = useRootStore();
  const {
    stories,
    activeStoryIndex,
    storiesOpen,
    setStoriesClose,
    activeStorySlideIndex,
    storiesSlidesAmount,
    handlePause,
    handlePlay,
  } = storiesStore;

  const activeStorySlide = stories[activeStoryIndex]?.images?.[activeStorySlideIndex];

  const handleNextStorySlide = () => {
    storiesStore.changeActiveSlide(1);
  };

  const handlePrevStorySlide = () => {
    storiesStore.changeActiveSlide(-1);
  };

  const handlePrevStory = () => {
    storiesStore.changeActiveSlideWithSideEffect(-1);
  };

  const handleNextStory = () => {
    storiesStore.changeActiveSlideWithSideEffect(1);
  };

  return (
    <Dialog
      open={storiesOpen}
      onClose={setStoriesClose}
      PaperProps={{ elevation: 0 }}
      classes={{ paper: classes.dialogPaper }}
    >
      <Box display="flex" alignItems="center" position="relative" borderRadius={16} width={339} height={602}>
        <div onClick={handlePrevStory} className={classes.btnPrev}>
          <StoriesArrow />
        </div>

        <ProgressArray key={storiesSlidesAmount} />

        <Box
          position="absolute"
          right={20}
          top={20}
          zIndex={20}
          className={classes.closeIcon}
          onClick={setStoriesClose}
        >
          <CloseStories />
        </Box>

        <Box position="absolute" left={0} top={0} bottom={0} width="30%" onClick={handlePrevStorySlide} />

        <img
          key={activeStoryIndex}
          src={activeStorySlide?.img!}
          className={classes.image}
          onTouchStart={handlePause}
          onMouseDown={handlePause}
          onTouchEnd={handlePlay}
          onMouseUp={handlePlay}
          alt=""
        />

        <Box position="absolute" right={0} top={0} bottom={0} width="30%" onClick={handleNextStorySlide} />

        {activeStorySlide?.buttonImg && (
          <a href={activeStorySlide.link!}>
            <Box
              className={classes.buttonWrapper}
              top={`${activeStorySlide?.buttonImg?.marginTopPercent}%`}
              left={`${activeStorySlide?.buttonImg?.marginLeftPercent}%`}
              right={`${activeStorySlide?.buttonImg?.marginRightPercent}%`}
              bottom={`${activeStorySlide?.buttonImg?.marginBottomPercent}%`}
            >
              <img className={classes.storiesIcon} src={activeStorySlide?.buttonImg.url!} alt="" />
              <Typography className={classes.buttonText}>{activeStorySlide.button}</Typography>
            </Box>
          </a>
        )}

        <div onClick={handleNextStory} className={classes.btnNext}>
          <StoriesArrow />
        </div>
      </Box>
    </Dialog>
  );
});

const useStyles = makeStyles(() => ({
  dialogPaper: {
    width: 500,
    background: 'transparent',
    overflow: 'hidden',
    alignItems: 'center',
  },
  btnPrev: {
    position: 'absolute',
    left: -61,
    cursor: 'pointer',
  },
  btnNext: {
    position: 'absolute',
    right: -61,
    cursor: 'pointer',
    transform: 'rotate(180deg)',
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: 16,
  },
  storiesIcon: {
    maxWidth: 175,
  },
  closeIcon: {
    cursor: 'pointer',
  },
  buttonWrapper: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    position: 'absolute',
  },
}));

export default Stories;
