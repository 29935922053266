import React from 'react';
import { Badge, makeStyles, Theme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import SquareIcon from './Icons/SquareIcon';
import { CameraIcon, AvatarSvg } from './Icons/SvgIcons';

interface IQAvatarProps {
  avatarUrl?: string | null;
  showBadge?: boolean;
  size: number;
  onClickBadge?: () => void;
}

interface IQAvatarStyleProps {
  size: number;
  badgeSize: number;
}

const QAvatar: React.FC<IQAvatarProps> = observer(props => {
  const { avatarUrl, showBadge, size, onClickBadge } = props;
  const classes = useStyles({ size, badgeSize: 24 });

  const renderAvatar = () =>
    avatarUrl ? (
      <img src={avatarUrl} className={classes.avatar} alt="Аватар" />
    ) : (
      <SquareIcon component={AvatarSvg} size={size} viewBox="0 0 253 256.4" className={classes.avatar} />
    );

  if (showBadge) {
    return (
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          <div className={classes.badge} onClick={onClickBadge}>
            <SquareIcon component={CameraIcon} size={16} />
          </div>
        }
      >
        {renderAvatar()}
      </Badge>
    );
  }

  return renderAvatar();
});

const useStyles = makeStyles((theme: Theme) => ({
  avatar: (props: IQAvatarStyleProps) => ({
    fill: 'inherit',
    height: props.size,
    width: props.size,
    borderRadius: props.size / 2,
    objectFit: 'cover',
  }),
  badge: (props: IQAvatarStyleProps) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: props.badgeSize,
    width: props.badgeSize,
    borderRadius: props.badgeSize / 2,
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
  }),
}));

export default QAvatar;
