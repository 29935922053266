import React, { ElementType } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface IQIconProps extends SvgIconProps {
  component: ElementType;
  size: {
    width: number | string;
    height: number | string;
  };
  viewBox?: string;
  fillNone?: boolean;
}

const QIcon: React.FC<IQIconProps> = props => {
  const { size, viewBox, component, fillNone, className, ...rest } = props;
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox={viewBox ? viewBox : `0 0 ${size.width} ${size.height}`}
      component={component}
      style={size}
      className={clsx(fillNone && classes.fillNone, className && className)}
      {...rest}
    />
  );
};

const useStyles = makeStyles({
  fillNone: {
    fill: 'none',
  },
});

export default QIcon;
