import AbstractModel from '../../../base/AbstractModel';

export default class Attendance extends AbstractModel {
  enter: string | null = null;
  exit: string | null = null;

  constructor(data: any) {
    super();
    this.load(data);
  }
}
