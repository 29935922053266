const getContractIdFromLocation = (location: string): number => {
  const contractId = location.split('/')[1];

  if (contractId && !isNaN(Number(contractId))) {
    return Number(contractId);
  }

  return 0;
};

export default getContractIdFromLocation;
