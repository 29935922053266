import React from 'react';
import { ButtonProps } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import LoaderButton from '../../../components/UI/LoaderButton';
import { useRootStore } from '../../../base/hooks/useRootStore';
import { useIsTablet } from '../../../hooks/useIsTablet';

const AuthButton: React.FC<ButtonProps> = observer(props => {
  const { authStore } = useRootStore();
  const isTablet = useIsTablet();

  return (
    <LoaderButton
      loading={authStore.loading}
      variant="contained"
      color="primary"
      size={isTablet ? 'small' : 'medium'}
      fullWidth
      {...props}
    />
  );
});

export default AuthButton;
