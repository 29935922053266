import { createMuiTheme } from '@material-ui/core';

import { rubrikNewBold } from './fonts';

export const theme = createMuiTheme({
  // INFO: настройка shadow в конце файла
  breakpoints: {
    values: {
      md: 0,
      lg: 786 + 48,
      xl: 1216 + 48,
    },
  },
  palette: {
    primary: { main: '#FFD500' },
    // secondary: { main: '' },
    error: { main: '#FF4733' },
    text: {
      primary: '#000',
      secondary: '#8D9AA6',
    },
    neonGray: {
      50: '#FAFAFB',
      100: '#EDEFF1',
      150: '#E1E4E7',
      200: '#D5D9DE',
      // 300: '#',
      // 400: '#',
      500: '#8D9AA6',
      600: '#747E89',
      700: '#5C646D',
    },
    link: {
      800: '#1E60BE',
    },
    green: {
      150: '#C9F3CD',
      500: '#22BE54',
    },
    red: {
      100: '#FFE5DD',
      500: '#FF4733',
    },
    yellow: {
      100: '#FFFCD9',
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    h1: {
      // [D] Headline / 3XL
      fontFamily: 'Rubrik New',
      fontWeight: 700,
      fontSize: 40,
      lineHeight: '56px',
    },
    h2: {
      // [D] Headline / 2XL
      fontFamily: 'Rubrik New',
      fontWeight: 700,
      fontSize: 32,
      lineHeight: '40px',
    },
    h3: {
      // [D] Headline / XL
      // [M] Headline / 3XL
      fontFamily: 'Rubrik New',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '32px',
    },
    h4: {
      // [M] Headline / XL
      fontFamily: 'Rubrik New',
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '24px',
    },
    h5: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
    },
    body1: {
      // [D] Paragraph / M
      fontSize: 16,
      lineHeight: '24px',
    },
    body2: {
      // [M] Paragraph / M
      // [M] Paragraph / M.1
      // [D] Paragraph / S
      fontSize: 14,
      lineHeight: '20px',
    },
    subtitle1: {
      // [D] Paragraph / L
      // [D] Paragraph / L.1
      fontSize: 18,
      lineHeight: '28px',
      fontWeight: 500,
    },
    subtitle2: {
      // [D] Paragraph / M.1
      // [M] Paragraph / L.1
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 500,
    },
    button: {
      fontSize: 18,
      lineHeight: '28px',
      fontWeight: 400,
      textTransform: 'none',
    },
    caption: {
      // [M] Paragraph / S
      // [M] Paragraph / S.1
      // [D] Paragraph / XS
      fontSize: 13,
      lineHeight: '16px',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [rubrikNewBold],
        body: {
          color: '#000',
          backgroundColor: '#fff !important',
          minWidth: 768,
        },
        main: {
          flex: '1 0 auto',
        },
        '#root': {
          position: 'relative',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
        'a, .router-link': {
          textDecorationLine: 'none',
        },
      },
    },
    MuiTableCell: {
      head: {
        backgroundColor: '#FAFAFB', // neonGrey[50]
        border: 'none',
      },
    },
    MuiButton: {
      // size - normal
      root: {
        cursor: 'pointer',
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 500,
        padding: '16px 20px',
      },
      text: {
        padding: '16px 20px',
        backgroundColor: 'transparent',
        transition: 'all .4s ease',
        '&:hover': {
          color: '',
          backgroundColor: 'transparent',
        },
      },
      textPrimary: {
        color: '',
        '&:hover': {
          color: '',
          backgroundColor: 'transparent',
        },
      },
      textSecondary: {
        color: '',
        '&:hover': {
          color: '',
          backgroundColor: 'transparent',
        },
      },
      contained: {
        boxShadow: 'none !important',
      },
      containedPrimary: {
        backgroundColor: '#FFD500',
        '&:hover': {
          backgroundColor: '#e8c200',
        },
      },
      outlined: {
        padding: '15px 20px',
        borderColor: '#000',
      },
      // size - small
      sizeSmall: {
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 400,
        padding: 14,
      },
      textSizeSmall: {
        fontSize: 16,
        lineHeight: '24px',
      },
      outlinedSizeSmall: {
        padding: 13,
      },
      // size - large
      sizeLarge: {
        fontSize: 18,
        lineHeight: '28px',
        padding: '18px 24px',
      },
      outlinedSizeLarge: {
        padding: '17px 24px',
      },
      // icon
      iconSizeSmall: {
        '&$startIcon': {
          marginRight: 12,
        },
      },
    },
    MuiSwitch: {
      root: {
        width: 48,
        height: 24,
        padding: 0,
      },
      switchBase: {
        top: 2,
        left: 2,
        padding: 0,
        '&$checked': {
          transform: 'translateX(24px)',
          '& + $track': {
            opacity: 1,
          },
        },
      },
      track: {
        width: 48,
        height: 24,
        borderRadius: 12,
        backgroundColor: '#D5D9DE',
      },
      thumb: {
        color: 'white',
        boxShadow: '0px 3px 1px 0px #0000000F',
      },
      colorSecondary: {
        '&$checked': {
          color: 'white',
          '& + $track': {
            backgroundColor: '#FFD500',
          },
          '&$disabled + $track': {
            backgroundColor: '#FFE873',
          },
        },
        '&$disabled:not($checked) + $track': {
          opacity: 1,
          backgroundColor: '#D5D9DE',
        },
      },
    },

    MuiAvatar: {
      colorDefault: {
        backgroundColor: '#EDEFF1', // neonGray['100'],
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 12,
      },
    },
    MuiFormLabel: {
      root: {
        display: 'block',
        fontSize: 18,
        lineHeight: '28px',
        fontWeight: 400,
        color: '#5C646D',
        marginBottom: 12,
      },
    },
    MuiChip: {
      root: {
        borderRadius: 4,
        height: 24,
      },
      label: {
        // [D] Paragraph / XS
        fontSize: 13,
        lineHeight: '16px',
      },
      colorPrimary: {
        color: 'white',
        backgroundColor: '#22BE54',
      },
    },

    // TextField
    MuiTextField: {
      root: {
        '& label + .MuiInputBase-root .MuiInputBase-input': {
          padding: '28px 24px 8px',
        },
        '& label + .MuiInputBase-root .MuiInputBase-inputMarginDense': {
          padding: '23px 20px 9px',
        },
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: '#FAFAFB',
      },
      input: {
        '&::-webkit-input-placeholder': {
          color: '#747E89',
          opacity: 1,
        },
        '&:-ms-input-placeholder': {
          color: '#747E89',
          opacity: 1,
        },
        '&::-moz-placeholder': {
          color: '#747E89',
          opacity: 1,
        },
        '&:-moz-placeholder': {
          color: '#747E89',
          opacity: 1,
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        color: '#747E89',
        fontSize: 18,
        lineHeight: '28px',
        transform: 'translate(24px, 18px) scale(1)',
        '&$focused': {
          color: '#747E89',
        },
        '&$shrink': {
          transform: 'translate(24px, 7px) scale(0.79)',
        },
        '&$marginDense': {
          fontSize: 17,
          lineHeight: '24px',
          transform: 'translate(20px, 15px) scale(1)',
        },
        '&$marginDense$shrink': {
          transform: 'translate(20px, 5.2px) scale(0.72)',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused': {
          '& $notchedOutline': {
            borderColor: '#000',
            borderWidth: 1,
          },
        },
      },
      input: {
        fontSize: 18,
        lineHeight: '28px',
        height: 28,
        borderColor: '#D5D9DE',
        padding: '18px 24px',
        // паддинги для TextField+label объявлены в MuiTextField
      },
      inputMarginDense: {
        fontSize: 16,
        lineHeight: '24px',
        height: 24,
        padding: 20,
        paddingTop: 16, // нужно для оверрайда дефолтных стилей
        paddingBottom: 16,
        // паддинги для TextField+label объявлены в MuiTextField
      },
      notchedOutline: {
        '& > legend': {
          maxWidth: 0,
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 24,
        marginRight: 24,

        '&$marginDense': {
          marginLeft: 20,
          marginRight: 20,
        },
      },
    },

    MuiDivider: {
      root: {
        backgroundColor: '#E1E4E7',
      },
    },
    MuiFormControlLabel: {
      label: {
        marginLeft: 6,
        '@media only screen and (max-width: 1264px)': {
          marginLeft: 2,
        },
      },
    },
  },
});

theme.shadows[1] = '0 1px 10px rgba(0,0,0,.25)';
theme.shadows[10] = '0 8px 32px rgba(0,0,0,.08)';
