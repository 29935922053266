import React, { useState } from 'react';
import { Box, Button, Container, Divider, FormLabel, Grid, TextField, Typography } from '@material-ui/core';

import TextButton from '../../components/UI/TextButton';
import QDatePicker from '../../components/UI/QDatePicker';
import QRangePicker from '../../components/UI/QRangePicker';
import AuthScreen from '../auth/AuthScreen';
import QTextFieldSmallest from '../../components/UI/QTextFieldSmallest';

const PreviewComponentsScreen: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const handleDateChange = (date: Date) => setSelectedDate(date);
  const handleStartDateChange = (date: Date) => setStartDate(date);
  const handleEndDateChange = (date: Date) => setEndDate(date);

  return (
    <Container maxWidth="xl">
      <AuthScreen />

      <Typography variant="h1">H1</Typography>
      <Typography variant="h2">H2</Typography>
      <Typography variant="h3">H3</Typography>
      <Typography variant="body1">body1</Typography>
      <Typography variant="body2">body2</Typography>
      <Typography variant="caption">caption</Typography>

      <Box my={5}>
        <Divider />
      </Box>

      <Grid container spacing={5}>
        <Grid item md="auto">
          <Button variant="contained" size="small" color="primary" fullWidth>
            Contained small button
          </Button>
        </Grid>
        <Grid item md="auto">
          <Button variant="contained" color="primary">
            Contained button
          </Button>
        </Grid>
        <Grid item md="auto">
          <Button variant="contained" size="large" color="primary">
            Contained large button
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item md="auto">
          <Button variant="outlined" size="small">
            Outlined small button
          </Button>
        </Grid>
        <Grid item md="auto">
          <Button variant="outlined">Outlined button</Button>
        </Grid>
        <Grid item md="auto">
          <Button variant="outlined" size="large">
            Outlined large button
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item md="auto">
          <Button size="small">Just small button</Button>
        </Grid>
        <Grid item md="auto">
          <Button>Just button</Button>
        </Grid>
        <Grid item md="auto">
          <Button size="large">Just large button</Button>
        </Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item md="auto">
          <TextButton size="small">Text small button</TextButton>
        </Grid>
        <Grid item md="auto">
          <TextButton>Text button</TextButton>
        </Grid>
        <Grid item md="auto">
          <TextButton size="large">Text large button</TextButton>
        </Grid>
      </Grid>

      <Box my={5}>
        <Divider />
      </Box>

      <Typography>QTextFieldSmallest</Typography>
      <Grid container spacing={5}>
        {/* w\o label x2, default value, with error */}

        <Grid item md>
          <QTextFieldSmallest variant="outlined" placeholder="Почта" fullWidth />
        </Grid>

        <Grid item md>
          <QTextFieldSmallest variant="outlined" placeholder="Почта" fullWidth />
        </Grid>

        <Grid item md>
          <QTextFieldSmallest variant="outlined" placeholder="Почта" fullWidth defaultValue="mymail@gmail.com" />
        </Grid>

        <Grid item md>
          <QTextFieldSmallest
            variant="outlined"
            placeholder="Почта"
            helperText="Ошибка"
            error
            fullWidth
            defaultValue="mymail@gmail.com"
          />
        </Grid>
      </Grid>

      <Typography>TextField Medium (size='small')</Typography>
      <Grid container spacing={5}>
        {/* w\o label, w\ label, default value, with error */}

        <Grid item md>
          <TextField variant="outlined" size="small" placeholder="Фамилия" fullWidth />
        </Grid>

        <Grid item md>
          <TextField variant="outlined" size="small" label="Фамилия" fullWidth />
        </Grid>

        <Grid item md>
          <TextField variant="outlined" size="small" label="Фамилия" fullWidth defaultValue="Константинопольская" />
        </Grid>

        <Grid item md>
          <TextField
            variant="outlined"
            size="small"
            label="Фамилия"
            helperText="Ошибка"
            error
            fullWidth
            defaultValue="Константинопольская"
          />
        </Grid>
      </Grid>

      <Typography>TextField Large (size='medium')</Typography>
      <Grid container spacing={5}>
        {/* w\o label, w\ label, default value, with error */}

        <Grid item md>
          <TextField variant="outlined" size="medium" placeholder="Фамилия" fullWidth />
        </Grid>

        <Grid item md>
          <TextField variant="outlined" size="medium" label="Фамилия" fullWidth />
        </Grid>

        <Grid item md>
          <TextField variant="outlined" size="medium" label="Фамилия" fullWidth defaultValue="Константинопольская" />
        </Grid>

        <Grid item md>
          <TextField
            variant="outlined"
            size="medium"
            label="Фамилия"
            helperText="Ошибка"
            error
            fullWidth
            defaultValue="Константинопольская"
          />
        </Grid>
      </Grid>

      <Box my={5}>
        <Divider />
      </Box>

      <Grid container spacing={5}>
        <Grid item md={12}>
          <Grid container spacing={5}>
            <Grid item md>
              <FormLabel>Дата</FormLabel>
              <QDatePicker placeholderText="Выберите дату" selected={selectedDate} onChange={handleDateChange} />
            </Grid>
            <Grid item md>
              <FormLabel>&nbsp;</FormLabel>
              <QDatePicker
                placeholderText="Выберите дату"
                label="Дата"
                selected={selectedDate}
                onChange={handleDateChange}
              />
            </Grid>
            <Grid item md>
              <FormLabel>&nbsp;</FormLabel>
              <QDatePicker
                placeholderText="Выберите дату"
                label="Дата"
                error
                helperText="Ошибка"
                selected={selectedDate}
                onChange={handleDateChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <QRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={handleStartDateChange}
            onEndDateChange={handleEndDateChange}
          />
        </Grid>
        <Grid item md={6}>
          <QRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={handleStartDateChange}
            onEndDateChange={handleEndDateChange}
            startDateProps={{ error: true, helperText: 'Ошибка' }}
            endDateProps={{ error: true, helperText: 'Ошибка' }}
          />
        </Grid>
      </Grid>

      <Box mb={40} />
    </Container>
  );
};

export default PreviewComponentsScreen;
