import React, { forwardRef } from 'react';
import { IconButton, makeStyles, TextField, Theme } from '@material-ui/core';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import ruLocale from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import clsx from 'clsx';
import { parse } from 'date-fns';

import { CalendarInputIcon } from './Icons/SvgIcons';
import { DateMaskInput } from './NumberMaskedInputs';
import SquareIcon from './Icons/SquareIcon';

export interface IQDatePickerProps extends Omit<ReactDatePickerProps, 'onBlur'> {
  label?: string;
  error?: boolean;
  helperText?: string;
  size?: 'small' | 'medium';
  onBlur?: (e: React.FocusEvent<HTMLInputElement>, date: Date | null) => void;
}

const DISPLAY_DATE_FORMAT = 'dd.MM.yyyy';

const QDatePicker: React.FC<IQDatePickerProps> = props => {
  const { label, calendarClassName, wrapperClassName, error, helperText, customInput, size, onBlur, ...rest } = props;
  const classes = useStyles();

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      const date = parse(e.target.value, DISPLAY_DATE_FORMAT, new Date());

      onBlur(e, date);
    }
  };

  const CustomInput = forwardRef((props: any, ref: any) => {
    const { onClick, onFocus, placeholder, ...rest } = props;
    return (
      <TextField
        variant="outlined"
        placeholder={placeholder}
        label={label}
        inputProps={{ className: label ? classes.labelInput : '' }}
        InputProps={{
          endAdornment: (
            <IconButton onClick={onClick}>
              <SquareIcon component={CalendarInputIcon} size={24} />
            </IconButton>
          ),
          inputComponent: DateMaskInput,
        }}
        ref={ref}
        error={error}
        helperText={helperText}
        fullWidth
        size={size}
        {...rest}
      />
    );
  });

  return (
    <DatePicker
      locale={ruLocale}
      dateFormat={DISPLAY_DATE_FORMAT}
      dateFormatCalendar="MMMM"
      calendarClassName={clsx(classes.calendar, calendarClassName)}
      {...rest}
      renderDayContents={(day: number) => <div>{day}</div>}
      customInput={customInput || <CustomInput />}
      wrapperClassName={clsx(classes.wrapper, wrapperClassName)}
      popperClassName={classes.popper}
      onBlur={handleBlur}
      forceShowMonthNavigation={true}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
  },
  labelInput: {
    padding: '31.32px 24px 11.32px',
  },
  popper: {
    zIndex: 5,
    '& .react-datepicker__triangle': {
      borderBottomColor: `${theme.palette.primary.main} !important`,
      '&:before': {
        borderBottomColor: `${theme.palette.primary.main} !important`,
      },
    },
    '& .react-datepicker__navigation--previous': {
      top: 30,
      left: 264,
      height: 9,
      width: 9,
      transform: 'rotate(-45deg)',
      borderWidth: '0.2em 0.2em 0 0',
      borderRadius: 2,
      borderRightWidth: 0,
      borderLeftWidth: 2,
      borderTopWidth: 2,
      borderColor: 'black',
    },
    '& .react-datepicker__navigation--next': {
      top: 30,
      right: 27,
      height: 9,
      width: 9,
      transform: 'rotate(135deg)',
      borderWidth: '0.2em 0.2em 0 0',
      borderRadius: 2,
      borderRightWidth: 0,
      borderLeftWidth: 2,
      borderTopWidth: 2,
      borderColor: 'black',
    },
  },
  calendar: {
    border: 'none',
    boxShadow: '0 8px 32px rgba(0,0,0,.08)',
    '& .react-datepicker': {
      '&__month-container': {
        float: 'none',
      },
      '&__header': {
        position: 'relative',
        padding: '22px 0 6px',
        borderBottom: 0,
        backgroundColor: theme.palette.common.white,
        '&:before': {
          content: `''`,
          position: 'absolute',
          bottom: -8,
          left: 0,
          width: '100%',
          height: 10,
          backgroundColor: theme.palette.common.white,
        },
      },
      '&__year-read-view': {
        position: 'absolute',
        fontSize: 16,
        color: theme.palette.primary.main,
        top: 23,
        left: 98,
        '&--down-arrow': {
          display: 'none',
        },
      },
      '&__year-dropdown': {
        width: '100%',
        backgroundColor: theme.palette.common.white,
        border: 'none',
        left: 0,
        top: 48,
        fontSize: 16,
      },
      '&__year-option': {
        padding: 8,
        '&--selected_year': {
          backgroundColor: theme.palette.primary.main,
          '& > span': {
            display: 'none',
          },
        },
      },
      '&__current-month': {
        textTransform: 'capitalize',
        textAlign: 'initial',
        paddingLeft: 24,
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 400,
        marginBottom: 18,
      },
      '&__day-name': {
        fontSize: 12,
        lineHeight: '16px',
        margin: '3px 8.1px',
      },
      '&__month': {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        margin: 0,
        padding: 12,
        borderRadius: 8,
        // In selecting range: reset selected styles
        '&:not(&--selecting-range)': {
          '& .react-datepicker__day': {
            '&:hover:not(&--selected)': {
              '& div': {
                backgroundColor: '#fbf5b4',
              },
            },
          },
        },
      },
      '&__week': {
        display: 'flex',
        margin: '4px 0',
        borderRadius: 8,
        overflow: 'hidden',
      },
      '&__day': {
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 44,
        height: 32,
        fontSize: 16,
        lineHeight: '24px',
        padding: '0 6px',
        margin: 0,
        borderRadius: 0,
        backgroundColor: 'transparent',
        outline: 'none',
        zIndex: 1,
        // day inner base
        '& div': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 32,
          height: 32,
          borderRadius: 8,
        },
        '&--disabled': {
          color: '#b3b3b3',
        },
        '&--outside-month': {
          color: '#b3b3b3',
        },
        '&--selected': {
          color: '#000',
          '& div': {
            backgroundColor: theme.palette.primary.main,
          },
        },
        ' &--keyboard-selected': {
          color: '#000',
          '& div': {
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
      '&__day--in-selecting-range': {
        color: 'black',
        backgroundColor: theme.palette.yellow['100'],
      },
      '&__day--selecting-range-start': {
        backgroundColor: theme.palette.yellow['100'],
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      },
      '&__day--selecting-range-start div': {
        backgroundColor: theme.palette.primary.main,
      },
      '&__day--selecting-range-end': {
        backgroundColor: theme.palette.yellow['100'],
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
    },
  },
}));

export default QDatePicker;
