import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core';

interface HeaderLinkProps {
  to: string;
  children: string;
}

const HeaderLink: React.FC<HeaderLinkProps> = props => {
  const { to, children } = props;
  const classes = useStyles();

  return (
    <NavLink to={to} className={classes.root} activeClassName={classes.active}>
      {children}
    </NavLink>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '20px',
    color: theme.palette.neonGray['500'],

    [theme.breakpoints.down('lg')]: {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  active: {
    color: 'black',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}));

export default HeaderLink;
