import React, { FC } from 'react';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import { useIsTablet } from 'hooks/useIsTablet';
import usePasswordChangeValidationRules from 'hooks/usePasswordChangeValidationRules';
import { useRootStore } from 'base/hooks/useRootStore';
import { StepSettingOptions } from 'hooks/useStepHandler';

import QPassword from '../UI/input/QPassword';
import LoaderButton from '../UI/LoaderButton';
import PasswordResetModal from './PasswordResetModal';
import ValidationRules from './ValidationRules';
import { REQUIRED_FIELD_TEXT } from './BaseAddChild';

interface INewPasswordModalProps {
  handleSetStep: (options: StepSettingOptions) => void;
  loading?: boolean;
  contractId?: number | null;
  password?: string;
}

const NewPasswordModal: FC<INewPasswordModalProps> = ({ loading, contractId, password, handleSetStep }) => {
  const { childrenStore } = useRootStore();

  const isTablet = useIsTablet();
  const { values, errors, handleSubmit, handleChange } = useFormik({
    initialValues: {
      contractId: contractId || 0,
      password,
      newPassword: '',
      repeatPassword: '',
    },
    validateOnChange: true,
    validate(values) {
      const errors: any = {};

      if (!values.newPassword) {
        errors.newPassword = REQUIRED_FIELD_TEXT;
      }

      if (!values.repeatPassword) {
        errors.repeatPassword = REQUIRED_FIELD_TEXT;
      }

      if (values.newPassword !== values.repeatPassword) {
        errors.repeatPassword = 'Пароли не совпадают';
      }

      return errors;
    },
    onSubmit(values) {
      childrenStore.addChild(
        {
          contractId: `${values.contractId}`,
          password: `${values.password}`,
          newPassword: values.newPassword,
        },
        () => {
          handleSetStep({ step: 6 });
        },
      );
    },
  });

  const {
    isEnoughLength,
    isLatin,
    hasAtLeastOneBigLetter,
    hasAtLeastOneNumber,
    fieldsAreEqual,
  } = usePasswordChangeValidationRules(values);

  // Renders
  return (
    <PasswordResetModal title="Новый пароль">
      <Box mb={3}>
        <QPassword
          name="newPassword"
          placeholder="Новый пароль"
          password={values.newPassword}
          onChange={handleChange}
          error={errors.newPassword}
        />
      </Box>
      <Box mb={3}>
        <QPassword
          name="repeatPassword"
          placeholder="Повторите новый пароль"
          password={values.repeatPassword}
          onChange={handleChange}
          error={errors.repeatPassword}
        />
      </Box>

      <ValidationRules {...{ isEnoughLength, isLatin, hasAtLeastOneBigLetter, hasAtLeastOneNumber }} />

      <LoaderButton
        variant="contained"
        color="primary"
        fullWidth
        size={isTablet ? 'small' : 'medium'}
        loading={loading || false}
        onClick={() => handleSubmit()}
        disabled={
          !values.newPassword ||
          !values.repeatPassword ||
          !fieldsAreEqual ||
          !isEnoughLength ||
          !isLatin ||
          !hasAtLeastOneBigLetter ||
          !hasAtLeastOneNumber
        }
      >
        Сменить пароль
      </LoaderButton>
    </PasswordResetModal>
  );
};

export default NewPasswordModal;
