import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

/**
 * Query params
 * Example:
 * // domain.ru/route?param-name=data
 * const params = useLocationQuery();
 * params.get('param-name'); // = 'data'
 */
export const useLocationQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};
