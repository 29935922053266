import React from 'react';
import clsx from 'clsx';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { usePagination } from '@material-ui/lab';
import { getDate, intlFormat } from 'date-fns';
import { observer } from 'mobx-react-lite';

import { ArrowIcon } from '../../../components/UI/Icons/SvgIcons';
import { useRootStore } from '../../../base/hooks/useRootStore';
import { getMonthSubjCase } from '../../../helpers/dateHelpers';

const ChildCalendar: React.VFC = observer(() => {
  const { childrenStore: store } = useRootStore();
  const classes = useStyles();
  const { items } = usePagination({
    count: 7,
  });

  return (
    <Box mb={2}>
      <Box mb={2} mt={{ md: 2, xl: 0 }}>
        <Typography variant="h2" className={classes.month}>
          {getMonthSubjCase(store.currentDay)}
        </Typography>
      </Box>
      <ul className={classes.ul}>
        {items.map(({ page, type }) => {
          if (type === 'previous') {
            return <ArrowIcon onClick={store.handleSetPrevDate} className={classes.arrowPrev} key="prev" />;
          }
          if (type === 'next') {
            return <ArrowIcon onClick={store.handleSetNextDate} className={classes.arrowNext} key="next" />;
          }
          if (type === 'page') {
            return (
              <li
                onClick={() => store.handleSetDate(page)}
                className={clsx(
                  classes.paginationItem,
                  page === 4 && classes.itemActive,
                  page === 1 && classes.itemFirst,
                )}
                key={page}
              >
                <Typography color="textSecondary">
                  {intlFormat(store.currentWeek[page - 1], { weekday: 'short' }).toUpperCase()}
                </Typography>
                <Typography>{getDate(store.currentWeek[page - 1])}</Typography>
              </li>
            );
          }
        })}
      </ul>
    </Box>
  );
});

const useStyles = makeStyles(theme => ({
  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paginationItem: {
    textAlign: 'center',
    width: 96,
    position: 'relative',
    cursor: 'pointer',

    '&:after': {
      content: `""`,
      position: 'absolute',
      width: 1,
      height: 44,
      backgroundColor: theme.palette.neonGray[100],
      right: 0,
      top: 0,
    },
  },
  itemFirst: {
    '&:before': {
      content: `""`,
      position: 'absolute',
      width: 1,
      height: 44,
      backgroundColor: theme.palette.neonGray[100],
      left: 0,
      top: 0,
    },
  },
  itemActive: {
    backgroundColor: theme.palette.neonGray[50],
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  arrowPrev: {
    marginRight: theme.spacing(3),
    cursor: 'pointer',
    transform: 'rotate(180deg)',
  },
  arrowNext: {
    cursor: 'pointer',
    marginLeft: theme.spacing(3),
  },
  month: {
    textTransform: 'capitalize',
  },
}));

export default ChildCalendar;
