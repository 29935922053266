import React from 'react';
import { Box, makeStyles, Tab, Theme } from '@material-ui/core';

import ChildrenTabLabel from './ChildrenTabLabel';
import HeaderAvatar from './HeaderAvatar';
import QAvatar from '../QAvatar';
import { useIsTablet } from '../../../hooks/useIsTablet';

interface IHeaderTabProps {
  onClick: (e: React.MouseEvent<any>) => void;
  active: boolean;
  avatarUrl: string | null;
  textTop: string;
  textBottom: string;
}

const ChildrenTab: React.FC<IHeaderTabProps> = props => {
  const { onClick, avatarUrl, active, textTop, textBottom } = props;
  const classes = useStyles();
  const isTablet = useIsTablet();

  return (
    <Tab
      disableRipple
      className={classes.tab}
      classes={{
        root: classes.root,
        wrapper: classes.tabWrapper,
        textColorInherit: classes.textColorInherit,
      }}
      onClick={onClick}
      label={<ChildrenTabLabel active={active} textTop={textTop} textBottom={textBottom} />}
      icon={
        <Box mr="10px">
          <HeaderAvatar active={active}>
            <QAvatar avatarUrl={avatarUrl} size={isTablet ? 28 : 40} />
          </HeaderAvatar>
        </Box>
      }
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('xl')]: {
      padding: '0 16px 0 48px',
    },
    [theme.breakpoints.down('lg')]: {
      padding: '0 18px',
    },
  },
  tabWrapper: {
    display: 'flex',
    flexDirection: 'row',

    '.MuiTab-labelIcon & > *:first-child': {
      marginBottom: 0,
    },
  },
  tab: {
    height: 64,
    minHeight: 64,
    [theme.breakpoints.down('lg')]: {
      height: 47,
      minHeight: 47,
    },
  },
  textColorInherit: {
    opacity: 1,
  },
}));

export default ChildrenTab;
