import React from 'react';
import { ListItemIcon, ListItemIconProps, makeStyles } from '@material-ui/core';

const QListItemIcon: React.FC<ListItemIconProps> = props => {
  const classes = useStyles();
  const { children, ...rest } = props;

  return (
    <ListItemIcon className={classes.root} {...rest}>
      {props.children}
    </ListItemIcon>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 32,
  },
}));

export default QListItemIcon;
