import AbstractFactory from '../../base/AbstractFactory';
import { Child } from '../children/models/Child';
import PurchaseItem from './models/PurchaseItem';

export default class PurchasesFactory extends AbstractFactory {
  createPurchases = (Model: any, data: any[], children: Child[]) =>
    data.map(json => {
      const model = new Model(json);

      model.contractId = Number(json.contractId);
      model.date = new Date(json.timestamp * 1000);
      model.child = children.find(child => child.contractId === model.contractId);
      model.items = this.createList<PurchaseItem>(PurchaseItem, json.items)

      return model;
    });

  createPurchasesWithoutChild = (Model: any, data: any[]) =>
    data.map(json => {
      const model = new Model(json);

      model.contractId = Number(json.contractId);
      model.date = new Date(json.timestamp * 1000);

      return model;
    });
}
