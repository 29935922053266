import { makeAutoObservable } from 'mobx';

import AttendanceService from './AttendanceService';
import AttendanceDay from './models/AttendanceDay';

export default class AttendanceStore {
  attendance: AttendanceDay[] = [];
  loading: boolean = false;

  private attendanceService: AttendanceService;

  constructor() {
    makeAutoObservable(this);
    this.attendanceService = new AttendanceService();
  }

  getAttendance = (contractId: number) => {
    this.setLoading(true);

    this.attendanceService
      .getAttendance(contractId)
      .then(attendance => {
        this.setAttendance(attendance);
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  private setAttendance = (attendance: AttendanceDay[]) => (this.attendance = attendance);

  private setLoading = (loading: boolean) => (this.loading = loading);
}
