import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import MenuCategory from '../../../modules/menu-setup/models/MenuCategory';
import SquareIcon from '../../../components/UI/Icons/SquareIcon';
import { ArrowUpIcon, InfoIcon } from '../../../components/UI/Icons/SvgIcons';
import { useIsTablet } from '../../../hooks/useIsTablet';
import currencyFormat from '../../../helpers/currencyFormat';
import { postfixHelper } from '../../../helpers/stringHelper';
import { useRootStore } from '../../../base/hooks/useRootStore';
import useContractId from '../../../hooks/useContractId';
import SwitchWithText from '../../../components/UI/SwitchWithText';
import MenuFilter from '../../../modules/menu-setup/types/MenuFilter';
import MenuItemModel from '../../../modules/menu-setup/models/MenuItemModel';
import { FOOD_ICONS, toFoodIconKey } from './FoodIcons';

interface IMenuItemProps {
  menuCategory: MenuCategory;
  expanded: boolean;
  onChange: (e: any, expanded: boolean) => void;
  filter: MenuFilter;
}

const MenuItem: React.FC<IMenuItemProps> = observer(props => {
  const { menuCategory, expanded, onChange, filter } = props;
  const classes = useStyles();
  const isTablet = useIsTablet();
  const contractId = useContractId();
  const { menuSetupStore } = useRootStore();
  const { loadingToggle } = menuSetupStore;

  const filteredItems =
    (filter !== MenuFilter.all
      ? menuCategory?.menu?.filter(item => (filter === MenuFilter.allowed ? item.enabled : !item.enabled))
      : menuCategory.menu) || [];

  const toggleMenuItem = (menu: MenuItemModel) => {
    menuSetupStore.toggleMenuItem(contractId, menuCategory.title || '', menu);
  };

  return (
    <Accordion
      className={classes.accordion}
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={onChange}
    >
      <AccordionSummary
        classes={{ root: classes.accordionSummary, content: classes.accordionSummaryContent }}
        expandIcon={<SquareIcon component={ArrowUpIcon} size={24} />}
      >
        <Box display="flex" alignItems="center" mr="12px">
          <Box mr={3}>
            <Avatar>
              <SquareIcon
                component={menuCategory.title ? FOOD_ICONS[toFoodIconKey(menuCategory.title)] || InfoIcon : InfoIcon}
                size={24}
              />
            </Avatar>
          </Box>
          <Typography variant={isTablet ? 'h4' : 'h3'}>{menuCategory.title}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Наименование блюда</TableCell>
              <TableCell className={classes.weight}>Вес</TableCell>
              <TableCell className={classes.price}>Цена</TableCell>
              <TableCell className={classes.status}>Статус</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredItems.map((menu: MenuItemModel) => (
              <TableRow key={menu.text}>
                <TableCell>{menu.text}</TableCell>
                <TableCell>{postfixHelper(menu.weight, 'г.')}</TableCell>
                <TableCell>{currencyFormat(menu.price)}</TableCell>
                <TableCell>
                  <SwitchWithText
                    disabled={loadingToggle}
                    checked={menu.enabled || false}
                    onChange={(e, enabled) => toggleMenuItem(menu)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  accordion: {
    boxShadow: 'none',
    '&.Mui-expanded': {
      margin: 0,
      '&:before': {
        opacity: 1,
      },
    },
  },
  accordionSummary: {
    padding: 0,
    justifyContent: 'flex-start',
  },
  accordionSummaryContent: {
    flexGrow: 0,
    margin: theme.spacing(3, 0),
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(2, 0),
    },
    '&.Mui-expanded': {
      margin: theme.spacing(3, 0),
      [theme.breakpoints.down('lg')]: {
        margin: theme.spacing(2, 0),
      },
    },
  },
  accordionDetails: {
    padding: 0,
  },
  weight: {
    width: 143,
    [theme.breakpoints.down('lg')]: {
      width: 131,
    },
  },
  price: {
    width: 211,
    [theme.breakpoints.down('lg')]: {
      width: 150,
    },
  },
  status: {
    width: 173,
  },
}));

export default MenuItem;
