import React, { useState } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import NumberFormat from 'react-number-format';
import CodeConfirm from 'components/CodeConfirm';
import TextButton from 'components/UI/TextButton';
import { useRootStore } from 'base/hooks/useRootStore';
import { useTimer } from 'hooks/useTimer';
import { useIsTablet } from 'hooks/useIsTablet';

import AuthButton from '../AuthButton';

const SMS_CODE_LENGTH = 5;
const COUNTDOWN = 60;

const RSCodeConfirm: React.VFC = observer(() => {
  const { authStore } = useRootStore();
  const classes = useStyles();
  const isTablet = useIsTablet();

  const [code, setCode] = useState<string | undefined>();
  const { seconds, resetTimer } = useTimer(COUNTDOWN);

  const handleResendCode = (): void => {
    authStore.register();
    resetTimer();
  };

  const handlePhoneChange = (): void => authStore.setStep(0);

  const handleSendCode = (): void => {
    authStore.sendCode(code || '');
  };

  return (
    <Grid container direction="column" spacing={isTablet ? 2 : 4}>
      <Grid item>
        <Typography variant={isTablet ? 'h4' : 'h3'} align="center">
          Введите код
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant={isTablet ? 'body2' : 'body1'} component="span" color="textSecondary">
          Мы отправили SMS с кодом подтверждения на номер{' '}
        </Typography>

        <Typography variant={isTablet ? 'body2' : 'body1'} color="textSecondary" component="span" noWrap>
          <NumberFormat value={authStore.phone} displayType={'text'} format="+7 ### ###-##-##" />
        </Typography>

        <Box ml={1} display="inline">
          <TextButton className={classes.link} onClick={handlePhoneChange}>
            Изменить
          </TextButton>
        </Box>
      </Grid>

      <Grid item>
        <CodeConfirm length={SMS_CODE_LENGTH} onFill={setCode} error={false} />
      </Grid>

      <Box alignSelf="center" mb={2}>
        <Box mb={1}>
          <Typography variant={isTablet ? 'body2' : 'body1'} component="span" color="textSecondary">
            Получить код еще раз можно будет через {seconds}с
          </Typography>
        </Box>
        <Box textAlign="center">
          <TextButton disabled={!!seconds} className={classes.link} onClick={handleResendCode}>
            Получить код
          </TextButton>
        </Box>
      </Box>

      <Grid item>
        <AuthButton onClick={handleSendCode} disabled={!code?.length}>
          Продолжить
        </AuthButton>
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles(theme => ({
  link: {
    fontSize: 16,
    color: theme.palette.link[800],
    cursor: 'pointer',
    padding: 0,
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
}));

export default RSCodeConfirm;
