import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Tabs, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';

import ChildrenTab from './ChildrenTab';
import QMenu from '../QMenu';
import QMenuItem from '../QMenuItem';
import QListItemIcon from '../QListItemIcon';
import QMenuDivider from '../QMenuDivider';
import SquareIcon from '../Icons/SquareIcon';
import {
  BookClosedIcon,
  // BookOpenedIcon,
  CalendarMenuIcon,
  FoodIcon,
  // ForkKnifeIcon,
  SettingsIcon,
  TabBarArrowRightIcon,
  UserIcon,
  WalletIcon,
} from '../Icons/SvgIcons';
import QTabScrollButton from './QTabScrollButton';
import { useRootStore } from '../../../base/hooks/useRootStore';
import currencyFormat from '../../../helpers/currencyFormat';
import { Child } from '../../../modules/children/models/Child';
import getContractIdFromLocation from '../../../helpers/getContractIdFromLocation';
import { useIsTablet } from '../../../hooks/useIsTablet';
import QAvatar from '../QAvatar';
import HeaderAvatar from './HeaderAvatar';
import ChildrenTabLabel from './ChildrenTabLabel';

interface IMenuProps {
  title: string;
  url: string;
  icon: React.FC;
  divider?: boolean;
}
//убираем пока не добавлен функционал
const menu: IMenuProps[] = [
  // { title: 'Дневник', url: '/diary', icon: BookOpenedIcon },
  { title: 'Покупки', url: '/purchases', icon: WalletIcon },
  { title: 'Посещаемость', url: '/attendance', icon: CalendarMenuIcon },
  { title: 'Настройка меню', url: '/menu-setup', icon: FoodIcon },
  // { title: 'Предзаказ питания', url: '/preorder', icon: ForkKnifeIcon },
  { title: 'Библиотека', url: '/library', icon: BookClosedIcon, divider: true },
  { title: 'Настройки', url: '/settings', icon: SettingsIcon },
];

interface IHeaderTabBarProps {}

const ChildrenTabBar: React.FC<IHeaderTabBarProps> = observer(() => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const contractId = getContractIdFromLocation(location.pathname);
  const isTablet = useIsTablet();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeMenuChild, setActiveMenuChild] = useState<Child | undefined>();
  const [activeChild, setActiveChild] = useState<Child | undefined>();
  const [activeTab, setActiveTab] = useState<number>(0);

  const { childrenStore } = useRootStore();
  const { children } = childrenStore;

  useEffect(() => {
    if (contractId) {
      const childIndex = children.findIndex(child => child.contractId === contractId);

      setActiveChild(children[childIndex]);
      setActiveTab(childIndex);
    } else {
      setActiveChild(undefined);
      setActiveTab(0);
    }
  }, [contractId, children]);

  const handleTabClick = (contractId: number, tabIndex: number) => {
    return (event: React.MouseEvent<any>) => {
      setActiveMenuChild(children.find(child => child.contractId === contractId));
      setAnchorEl(event.currentTarget);
      setActiveTab(tabIndex);
    };
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenuItem = (redirectTo: string) => {
    if (activeMenuChild) {
      history.push(`/${activeMenuChild?.contractId}${redirectTo}`);
    }

    handleClose();
  };

  return (
    <>
      <Tabs
        classes={{ root: classes.root, indicator: classes.indicator }}
        value={activeTab}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        ScrollButtonComponent={props => (
          <QTabScrollButton {...props} edge="shadow">
            <SquareIcon component={TabBarArrowRightIcon} size={19} />
          </QTabScrollButton>
        )}
      >
        {children.map((child, index) => (
          <ChildrenTab
            key={child.contractId || -1}
            onClick={handleTabClick(child.contractId || -1, index)}
            active={activeChild ? activeChild.contractId === child.contractId : false}
            avatarUrl={child?.avatarUrl}
            textTop={child.firstName || ''}
            textBottom={currencyFormat(child.balance)}
          />
        ))}
      </Tabs>

      <QMenu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: isTablet ? 'top' : 'bottom', horizontal: 'left' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={10}
      >
        {isTablet && (
          <Box display="flex" ml="18px" mt="1px">
            <Box mr="10px">
              <HeaderAvatar active={false}>
                <QAvatar avatarUrl={activeMenuChild?.avatarUrl} size={isTablet ? 28 : 40} />
              </HeaderAvatar>
            </Box>
            <ChildrenTabLabel
              active={true}
              textTop={activeMenuChild?.firstName || ''}
              textBottom={currencyFormat(activeMenuChild?.balance || 0)}
            />
          </Box>
        )}
        <QMenuItem onClick={() => handleClickMenuItem('')}>
          <QListItemIcon>
            <SquareIcon component={UserIcon} size={16} />
          </QListItemIcon>
          <Typography variant={isTablet ? 'body2' : 'body1'}>{activeMenuChild?.firstLastName || ''}</Typography>
        </QMenuItem>
        <QMenuDivider />
        {menu.map((menuItem, index) => (
          <div key={index}>
            <QMenuItem onClick={() => handleClickMenuItem(menuItem.url)}>
              <QListItemIcon>
                <SquareIcon component={menuItem.icon} size={16} />
              </QListItemIcon>
              <Typography variant={isTablet ? 'body2' : 'body1'}>{menuItem.title}</Typography>
            </QMenuItem>
            {menuItem.divider && <QMenuDivider />}
          </div>
        ))}
      </QMenu>
    </>
  );
});

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  indicator: {
    display: 'none',
  },
}));

export default ChildrenTabBar;
