import React from 'react';
import { Select } from '@material-ui/core';
import { SelectProps } from '@material-ui/core/Select/Select';

import SquareIcon from './Icons/SquareIcon';
import { DropdownArrowIcon } from './Icons/SvgIcons';

interface IQSelectProps extends SelectProps {}

const QSelect: React.FC<IQSelectProps> = props => {
  const { children, onOpen, onClose, ...rest } = props;

  return (
    <Select
      IconComponent={props => <SquareIcon component={DropdownArrowIcon} style={{ right: 20 }} size={24} {...props} />}
      {...rest}
    >
      {children}
    </Select>
  );
};

export default QSelect;
