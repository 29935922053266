export const RUB_SIGN = '₽';

const getBalance = (amount: number | null) => {
  if (!amount) {
    return '0';
  }

  return (amount / 100).toFixed(2).replace('.', ',');
};

// \u00A0 - неразрывный пробел
export const rubSignFormat = (amount: string | number): string => {
  return `${amount}\u00A0${RUB_SIGN}`;
};

const currencyFormat = (amount: number | null, inRubles: boolean = false) => {
  amount = amount || 0;
  if (inRubles) {
    return rubSignFormat(getBalance(amount * 100));
  }
  return rubSignFormat(getBalance(amount));
};

export default currencyFormat;
