import TCardFactory from './TCardFactory';
import TCardApiRepository from './TCardApiRepository';
import TCard from './models/TCard';

export default class TCardService {
  private factory: TCardFactory;
  private api: TCardApiRepository;

  constructor() {
    this.factory = new TCardFactory();
    this.api = new TCardApiRepository();
  }

  getTCardInfo = async (contractId: number): Promise<TCard> => {
    const { data } = await this.api.getTCardInfo(contractId);
    return this.factory.createCard(TCard, data.data);
  };
}
