import React from 'react';
import { Button, Card, makeStyles, Theme } from '@material-ui/core';

import SquareIcon from './UI/Icons/SquareIcon';
import { PlusYellowIcon } from './UI/Icons/SvgIcons';

interface IAddChildBtnProps {
  onClick?: () => void;
}

const AddChildBtn: React.FC<IAddChildBtnProps> = props => {
  const classes = useStyles();

  return (
    <Card elevation={0} className={classes.root}>
      <Button color="primary" startIcon={<SquareIcon component={PlusYellowIcon} size={24} />} onClick={props.onClick}>
        Добавить ребёнка
      </Button>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 86,
    borderRadius: 12,
    border: `1px solid ${theme.palette.neonGray['150']}`,
    [theme.breakpoints.down('lg')]: {
      height: 56,
    },
  },
}));

export default AddChildBtn;
