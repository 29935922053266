import { Box } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { FC } from 'react';
import { useRootStore } from 'base/hooks/useRootStore';
import { useIsTablet } from 'hooks/useIsTablet';
import usePasswordChangeValidationRules from 'hooks/usePasswordChangeValidationRules';
import { StepSettingOptions } from 'hooks/useStepHandler';

import QPassword from '../UI/input/QPassword';
import LoaderButton from '../UI/LoaderButton';
import { REQUIRED_FIELD_TEXT } from './BaseAddChild';
import PasswordResetModal from './PasswordResetModal';
import ValidationRules from './ValidationRules';

interface IPasswordResetThirdStepProps {
  token: string | null;
  contractId: number | null;
  handleSetStep: (options: StepSettingOptions) => void;
  onClose?: () => void;
  loading?: boolean;
}

const PasswordResetThirdStep: FC<IPasswordResetThirdStepProps> = props => {
  const { loading, token, handleSetStep, onClose, contractId } = props;
  const { childrenStore } = useRootStore();

  const isTablet = useIsTablet();
  const { values, errors, handleSubmit, handleChange } = useFormik({
    initialValues: {
      contractId: contractId || 0,
      newPassword: '',
      repeatPassword: '',
      token: token || '',
    },
    validateOnChange: true,
    validate(values) {
      const errors: any = {};

      if (!values.newPassword) {
        errors.newPassword = REQUIRED_FIELD_TEXT;
      }

      if (!values.repeatPassword) {
        errors.repeatPassword = REQUIRED_FIELD_TEXT;
      }

      if (values.newPassword !== values.repeatPassword) {
        errors.repeatPassword = 'Пароли не совпадают';
      }

      return errors;
    },
    onSubmit(values) {
      childrenStore.changePasswordIfForgot(
        values.contractId,
        values.newPassword,
        values.repeatPassword,
        values.token,
        () => {
          if (onClose) {
            childrenStore.getChildren();
            onClose();
            return;
          }
          handleSetStep({ action: 'next' });

          childrenStore.getChildren();
        },
      );
    },
  });

  const {
    isEnoughLength,
    isLatin,
    hasAtLeastOneBigLetter,
    hasAtLeastOneNumber,
    fieldsAreEqual,
  } = usePasswordChangeValidationRules(values);

  // Renders
  return (
    <PasswordResetModal title="Новый пароль">
      <Box mb={3}>
        <QPassword
          name="newPassword"
          placeholder="Новый пароль"
          password={values.newPassword}
          onChange={handleChange}
          error={errors.newPassword}
        />
      </Box>
      <Box mb={3}>
        <QPassword
          name="repeatPassword"
          placeholder="Повторите новый пароль"
          password={values.repeatPassword}
          onChange={handleChange}
          error={errors.repeatPassword}
        />
      </Box>

      <ValidationRules {...{ isEnoughLength, isLatin, hasAtLeastOneBigLetter, hasAtLeastOneNumber }} />

      <LoaderButton
        variant="contained"
        color="primary"
        fullWidth
        size={isTablet ? 'small' : 'medium'}
        loading={loading || false}
        onClick={() => handleSubmit()}
        disabled={
          !values.newPassword ||
          !values.repeatPassword ||
          !fieldsAreEqual ||
          !isEnoughLength ||
          !isLatin ||
          !hasAtLeastOneBigLetter ||
          !hasAtLeastOneNumber
        }
      >
        Сменить пароль
      </LoaderButton>
    </PasswordResetModal>
  );
};

export default PasswordResetThirdStep;
