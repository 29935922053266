import AbstractApiRepository from '../../base/repositories/AbstractApiRepository';
import Story from './models/Story';

interface IStories {
  stories: Story[];
}

export default class StoriesApiRepository extends AbstractApiRepository {
  getStories = () =>
    this.get<IStories>({
      url: 'v2/stories',
    });
}
