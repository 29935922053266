/**
 * symbols - >1 символа, любой символ, кроме @
 * symbols@symbols.symbols
 */

export const EMAIL_REGEXP = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

export const isEmailValid = (emailRaw: string) => emailRaw.search(EMAIL_REGEXP) !== -1;

export const PHONE_REGEXP = /^\+7\d{10}$/;

export const isPhoneValid = (phoneRaw: string): boolean => {
  const phone = phoneRaw.replace(/[- ()]/g, '');
  return phone.search(PHONE_REGEXP) !== -1;
};
